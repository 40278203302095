import { Dispatch, SetStateAction } from 'react';
import { useSnackbar } from 'notistack';
import { DeviceOwner } from './DeviceOwnersPopover';
import { KwConfirmationModal } from '../../kw-ui-components/KwConfirmationModal';
import { fetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selected: DeviceOwner[];
  setSelected: Dispatch<SetStateAction<DeviceOwner[]>>;
  mutate: () => void;
}

export const DeviceOwnerInviteUnenrolledModal = ({ isOpen, onClose, selected, setSelected, mutate }: IProps) => {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { enqueueSnackbar } = useSnackbar();
  const { firstName, lastName, email } = selected.length ? selected[0] : { firstName: '', lastName: '', email: '' };

  const inviteUnenrolledDeviceOwner = async () => {
    const deviceOwnerInfo = `${firstName} ${lastName} (${email})`;

    try {
      const response = await fetchService(`/organizations/invite?email=${encodeURIComponent(email)}`, accessToken);
      if (response.error) {
        enqueueSnackbar(`Error inviting ${deviceOwnerInfo}`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(`Successfully invited ${deviceOwnerInfo}`, {
          variant: 'success',
        });
      }
    } catch (err) {
      console.error('Error inviting device owner', err);
    } finally {
      setSelected([]);
      mutate();
    }
  };

  return (
    <KwConfirmationModal
      title="Invite Device Owner"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSelected([]);
      }}
      onConfirm={() => {
        inviteUnenrolledDeviceOwner();
      }}
      description={`invite ${firstName} ${lastName} ${email} to join your Q-Scout organization`}
    />
  );
};
