import React, { useEffect, useState } from 'react';
import { Typography, styled, Box, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { urlMap } from '../../utils/fetchService';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';

export default function Register() {
  const [email, setEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isRegistrationSuccessful, setRegistrationSuccessful] = useState(false);
  const [countdown, setCountdown] = useState(3);

  const location = useLocation();
  const auth0 = useAuth0();
  const searchParams = new URLSearchParams(location.search);
  const inviteToken = searchParams.get('invite');

  const sendRegistrationRequest = () => {
    const registrationPath = `/organizations/portal-user?token=${inviteToken}&email=${encodeURIComponent(email)}`;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`${urlMap(registrationPath)}${registrationPath}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          enqueueSnackbar(`Error registering: ${data.error}`, {
            variant: 'error',
          });
        } else {
          setRegistrationSuccessful(true);
        }
      })
      .catch(error => {
        enqueueSnackbar(`Error registering`, {
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    if (isRegistrationSuccessful) {
      const interval = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    return undefined;
  }, [isRegistrationSuccessful]);

  useEffect(() => {
    if (countdown < 1) {
      auth0.loginWithRedirect();
    }
  }, [countdown, auth0]);

  const handleSubmitRegister = (event: React.FormEvent) => {
    event.preventDefault();
    sendRegistrationRequest();
  };

  return (
    <StyledContainer>
      <StyledForm onSubmit={handleSubmitRegister}>
        <StyledImage>
          <img src="/logo/Quokka-Logo-Black-Green.svg" alt="quokka-logo" width={180} height={56} />
        </StyledImage>
        {!isRegistrationSuccessful ? (
          <>
            <StyledTitle>{'Welcome to Q-Scout!'}</StyledTitle>
            <StyledTextInformation>
              {'You have been invited to join an organization. Enter your email address to complete the sign up process.'}
            </StyledTextInformation>
            <StyledTextField
              label="name@email.com"
              variant="outlined"
              type="email"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <StyledTypography variant="body2" align="center">
              By signing up, you agree to our terms of service and privacy policy.
            </StyledTypography>
            <StyledKwButton type="submit" variant="filled">
              {'Sign Up'}
            </StyledKwButton>
          </>
        ) : (
          <div>
            <StyledSuccess>
              Registration Successful! <br />
            </StyledSuccess>
            <StyledRedirect>You will be redirected to the login page in {countdown}...</StyledRedirect>
          </div>
        )}
      </StyledForm>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(30, 30, 38);
`;

const StyledForm = styled('form')`
  background-color: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 550px;
`;

const StyledTitle = styled(Typography)`
  padding-top: 25px;
  font-size: 36px;
  font-weight: bold;
`;

const StyledTextInformation = styled(Typography)`
  width: 400px;
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 400;
  margin-top: 50px;
`;

const StyledSuccess = styled(Typography)`
  width: 600px;
  font-family: Nunito Sans;
  font-size: 35px;
  font-weight: 400;
  margin-top: 25%;
  text-align: center;
`;

const StyledRedirect = styled(Typography)`
  width: 600px;
  font-family: Nunito Sans;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
`;

const StyledTextField = styled(TextField)`
  width: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledTypography = styled(Typography)`
  margin-top: auto;
  margin-bottom: 20px;
`;

const StyledKwButton = styled(KwButton)`
  padding: 10px;
  width: 100%;
  height: 75px;
  max-width: 600px;
  font-size: 14px;
  font-weight: 400;
`;

const StyledImage = styled('div')`
  margin: 18px 0 0 0;
`;
