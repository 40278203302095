export class RequestError extends Error {
  info: Response;

  status: number;
}

export const constructRequestError = async res => {
  const error = new RequestError('An error occurred while fetching the data.');
  // Attach extra info to the error object.
  error.info = await res.json();
  error.status = res.status;
  return error;
};
