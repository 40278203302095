// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-scroll::before {
  position: absolute;
  content: attr(data-scroll-instructions);
  top: 45%;
  left: 31%;
  z-index: 9999;
  font-size: 22px;
  color: #ffffff;
}

.map-scroll::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: '';
  background: #00000061;
  z-index: 999;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/css/mapScrollWheelZoomNotifier.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uCAAuC;EACvC,QAAQ;EACR,SAAS;EACT,aAAa;EACb,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,SAAS;EACT,MAAM;EACN,WAAW;EACX,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".map-scroll::before {\n  position: absolute;\n  content: attr(data-scroll-instructions);\n  top: 45%;\n  left: 31%;\n  z-index: 9999;\n  font-size: 22px;\n  color: #ffffff;\n}\n\n.map-scroll::after {\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: 0;\n  content: '';\n  background: #00000061;\n  z-index: 999;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
