import { Checkbox } from '@mui/material';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import type { ChangeEvent } from 'react';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
// eslint-disable-next-line import/extensions
import { DeviceOwnerStatus } from './enum/DeviceOwnerKeyMaps.enum';

interface Header {
  title: string;
  onClick?: () => void;
  sortValue?: null | 'ASC' | 'DESC';
}
interface TableHeader {
  enrolled: Header[];
  pending: Header[];
  unenrolled: Header[];
}
interface IProps {
  setPage: (pageNum: number) => void;
  ownerStatus: (typeof DeviceOwnerStatus)[keyof typeof DeviceOwnerStatus];
  numSelected: number;
  rowCount: number;
  tableHeaders: TableHeader;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export default function DeviceOwnersTableHead({ setPage, ownerStatus, numSelected, rowCount, tableHeaders, onSelectAllClick }: IProps) {
  const selectedTableHeaders =
    ownerStatus === DeviceOwnerStatus.ENROLLED
      ? tableHeaders.enrolled
      : ownerStatus === DeviceOwnerStatus.PENDING
      ? tableHeaders.pending
      : tableHeaders.unenrolled;

  return (
    <KwTableHead>
      <KwTableRow>
        {ownerStatus !== DeviceOwnerStatus.ENROLLED && (
          <>
            <KwTableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
                inputProps={{
                  'aria-label': `select all ${String(ownerStatus).toLowerCase()} device owners`,
                }}
              />
            </KwTableCell>
          </>
        )}
        {selectedTableHeaders.map(({ title, onClick, sortValue }) => (
          <KwColumnSortHeader
            key={title}
            title={title}
            onClick={() => {
              onClick();
              setPage(0);
            }}
            sortValue={sortValue}
          />
        ))}
      </KwTableRow>
    </KwTableHead>
  );
}
