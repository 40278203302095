import { useState } from 'react';

export const useKwPagination = (initialPageSize, initialPageNum = 0) => {
  const [page, setPage] = useState(initialPageNum);
  const [pageSize, setPageSize] = useState(initialPageSize);

  // for Pagination component
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  // for TablePagination component
  const handleChangeRowsPerPage = event => {
    setPage(0);
    setPageSize(parseInt(event.target.value, 10));
  };

  return {
    page,
    pageSize,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
