import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { KwTableBody } from './KwTableBody';

export const KwTableRow = styled(TableRow)`
  ${props => (props.onClick ? `cursor: pointer;` : '')}
  ${KwTableBody} & {
    background-color: ${props => (props.selected ? 'rgba(79, 134, 142, 0.1)' : 'transparent')};
    &:hover {
      background-color: rgba(79, 134, 142, 0.1);
    }
  }
`;
