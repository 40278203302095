import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';

// based on https://stackoverflow.com/a/64789744
interface TableRowLinkProps {
  href: string;
  uniqueId: string;
  children: JSX.Element[];
  ariaLabel: string;
}

export const TableRowLink = ({ href, uniqueId, children, ariaLabel }: TableRowLinkProps) => {
  const navigate = useNavigate();

  return (
    <KwTableRow tabIndex={0} aria-label={ariaLabel} aria-describedby={uniqueId} onClick={() => navigate(href)}>
      <StyledHiddenLink id={uniqueId}>click to open {href}</StyledHiddenLink>
      {children}
    </KwTableRow>
  );
};

// p is not a valid child of tbody or tr, so it needs to be appended to the body
const HiddenLink = ({ children, ...props }) => {
  return ReactDOM.createPortal(<p {...props}>{children}</p>, document.body);
};

const StyledHiddenLink = styled(HiddenLink)`
  display: none;
`;
