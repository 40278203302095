import Drawer from '@mui/material/Drawer';
import { styled, css } from '@mui/material/styles';

const dontForwardOpenProp = {
  shouldForwardProp: prop => prop !== 'open',
};

export const drawerWidth = 224;

export const DashboardDrawer = styled(Drawer, { ...dontForwardOpenProp })`
  & .MuiDrawer-paper {
    background-color: #244c5a;
    position: fixed;
    width: ${224}px;
    white-space: nowrap;
    transition: ${props =>
      props.theme.transitions.create('width', {
        easing: props.theme.transitions.easing.sharp,
        duration: props.theme.transitions.duration.enteringScreen,
      })};
    box-sizing: border-box;
    ${props =>
      !props.open
        ? css`
            overflow-x: hidden;
            transition: ${props.theme.transitions.create('width', {
              easing: props.theme.transitions.easing.sharp,
              duration: props.theme.transitions.duration.leavingScreen,
            })};
            width: ${props.theme.spacing(7)};
          `
        : ''}
  }
`;
