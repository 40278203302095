/* eslint-disable react/forbid-component-props */
import { TableContainer, Table, TableRow, TableCell, TableBody, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import CountryFlag from 'react-country-flag';
import { PageLoader } from '../../PageLoader';

export default function TopThreeCard({ type, data = [], error }: { type: string; data: any; error: boolean }) {
  const CountriesComponent = () => {
    return (
      <>
        {data.map(({ countryCode, count, countryName }) => (
          <TableRow key={countryCode}>
            <StyledTableCell>
              <StyledChip label={String(count)} />
              {countryName}
              <StyledCountryFlag countryCode={countryCode} svg style={{ width: '24px', height: '16px' }} />
            </StyledTableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const PermissionsComponent = () => {
    const permissions = Array.from(data) as { name: string; count: number }[];
    return (
      <>
        {permissions.map(({ name, count }) => (
          <TableRow key={name}>
            <StyledTableCell>
              <StyledPermission>
                <StyledChip label={String(count)} />
                {name}
              </StyledPermission>
            </StyledTableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const IssuesComponent = () => {
    const issues = Array.from(data) as { title: string; weight: number; count: number }[];
    return (
      <>
        {issues.map(({ title, count }) => (
          <TableRow key={title}>
            <StyledTableCell>
              <StyledChip label={String(count)} />
              {title}
            </StyledTableCell>
          </TableRow>
        ))}
      </>
    );
  };

  if (error) {
    console.error(error);
    return <div>failed to load</div>;
  }

  return (
    <>
      {!data && <PageLoader />}
      <TableContainer>
        <Table>
          <TableBody>
            {type === 'countries' ? (
              <CountriesComponent />
            ) : type === 'issues' ? (
              <IssuesComponent />
            ) : type === 'permissions' ? (
              <PermissionsComponent />
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const StyledChip = styled(Chip)`
  border-radius: 12px;
  width: 52px;
  height: 24px;
  background-color: ${props => props.theme.palette.text.secondary};
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-right: 24px;
` as typeof Chip;

const StyledTableCell = styled(TableCell)`
  border: none;
  padding-left: 0;
`;

const StyledCountryFlag = styled(CountryFlag)`
  margin-left: 8px;
`;

const StyledPermission = styled('div')`
  display: flex;
  align-items: center;
  font-size: 12px;
`;
