import { useContext } from 'react';
import { Chip, styled, Typography } from '@mui/material';
import { PolicyInfoContext } from './PolicyInfoContext';

export const PolicyProfileHeader = () => {
  const { policyName, policyEnabled } = useContext(PolicyInfoContext);

  const chipProps = policyEnabled
    ? { label: 'Enabled', color: 'success', variant: 'filled' }
    : { label: 'Disabled', color: 'warning', variant: 'outlined' };

  return (
    <StyledHeaderText>
      {policyName}{' '}
      <Chip label={`${chipProps.label} Policy`} color={chipProps.color as any} variant={chipProps.variant as any} size="small" />
    </StyledHeaderText>
  );
};

const StyledHeaderText = styled(Typography)`
  line-height: 45px;
  color: '${props => props.theme.palette.text.primary}';
  // shorten leading trim on leftside to offset default whitespace
  margin-left: -4px;
  flex-grow: 1;
` as typeof Typography;

// @ts-ignore
StyledHeaderText.defaultProps = {
  component: 'h1',
  variant: 'h1Regular',
};
