import { Typography } from '@mui/material';

export const getUnenrollDeviceExtraDetails = () => (
  <div>
    <Typography variant="bodyBold">What to expect when you un-enroll a device:</Typography>
    <Typography variant="bodyRegular">
      <ul style={{ paddingLeft: '16px', marginTop: '8px', marginBottom: '8px' }}>
        <li>The Device will be removed from the Devices page</li>
        <li>The Device will be un-enrolled from your organization, meaning a new invite will be required for it to re-join</li>
        <li>Any Managed Apps the Device Owner has installed on the Device will be removed</li>
        <li>
          If that is the only Device its Device Owner has enrolled in your organization, the Device Owner will be removed from the Device
          Owners page
        </li>
      </ul>
    </Typography>
  </div>
);
