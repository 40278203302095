import type { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';

interface RequestParams {
  path: string;
  method: 'POST' | 'PUT' | 'DELETE';
  body?: any;
}

export const httpRequest = async ({ path, body = {}, method }: RequestParams) => {
  const item = window.localStorage.getItem('accessToken') || '';

  const accessToken = item ? JSON.parse(item) : '';

  try {
    const response = await fetch(path, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.status === 202) {
      return { data: null, response, error: false };
    }

    const respBody = await response.text(); // safe for empty bodies
    const data = (respBody.length && JSON.parse(respBody)) || {};

    return { data, response, error: false };
  } catch (err) {
    console.log(`Error in HTTP ${method}:`, err);
    return { data: null, response: null, error: true };
  }
};

interface sendHttpRequestParams {
  path: RequestParams['path'];
  method: RequestParams['method'];
  body?: RequestParams['body'];
  successMessage: SnackbarMessage;
  errorMessage: SnackbarMessage;
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
}

export async function sendHttpRequest({ path, method, body, successMessage, errorMessage, enqueueSnackbar }: sendHttpRequestParams) {
  const {
    data: { error },
  } = await httpRequest({ path, method, body });
  if (error) {
    enqueueSnackbar(errorMessage, { variant: 'error' });
  } else {
    enqueueSnackbar(successMessage, { variant: 'success' });
  }
}
