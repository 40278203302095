import { useState } from 'react';
import { useCycleState } from '../../utils/useCycleState';

export const useRuleSortParams = () => {
  const [ruleNameOrder, toggleRuleNameOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [createdDateOrder, toggleCreatedDateOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [updatedDateOrder, toggleUpdatedDateOrder] = useCycleState([null, 'ASC', 'DESC']);
  const toggles = [toggleRuleNameOrder, toggleCreatedDateOrder, toggleUpdatedDateOrder];

  const [orderBy, setOrderBy] = useState(null);

  const toggleExclude = triggeredToggle => () => {
    triggeredToggle();
    toggles.filter(toggle => toggle !== triggeredToggle).forEach(toggle => toggle(0));
  };

  const resetToggles = () => {
    toggles.forEach(toggle => toggle(0));
  };

  return {
    ruleNameOrder,
    toggleRuleNameOrder: toggleExclude(toggleRuleNameOrder),
    createdDateOrder,
    toggleCreatedDateOrder: toggleExclude(toggleCreatedDateOrder),
    updatedDateOrder,
    toggleUpdatedDateOrder: toggleExclude(toggleUpdatedDateOrder),
    orderBy,
    setOrderBy,
    resetToggles,
  };
};
