import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

export interface NavigationContextProps {
  appName: string;
  // setAppName: (name: string) => void;
  setAppName: Dispatch<SetStateAction<string>>;
  deviceDetails: {
    deviceId: string;
    deviceName: string;
  };
  // setDeviceDetails: (details: { deviceId: string; deviceName: string }) => void;
  setDeviceDetails: Dispatch<SetStateAction<{ deviceId: string; deviceName: string }>>;
}

export const NavigationInfoHook = () => {
  const [appName, setAppName] = useState('');
  const [deviceDetails, setDeviceDetails] = useState({ deviceId: '', deviceName: '' });

  return {
    appName,
    setAppName,
    deviceDetails,
    setDeviceDetails,
  };
};

export const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const { appName, setAppName, deviceDetails, setDeviceDetails } = NavigationInfoHook();

  return (
    <NavigationContext.Provider
      value={{
        appName,
        setAppName,
        deviceDetails,
        setDeviceDetails,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
