import { useContext } from 'react';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { ManagedAppsInfoContext } from './AppManagementInfoContext';

export const useRecentlyViewedApps = () => {
  const { platform } = useContext(ManagedAppsInfoContext);
  const [recentlyViewedAppsIos = [], updateRecentlyViewedAppsIos] = useLocalStorage('recentlyViewedAppsIos', []);
  const [recentlyViewedAppsAndroid = [], updateRecentlyViewedAppsAndroid] = useLocalStorage('recentlyViewedAppsAndroid', []);

  const recentlyViewed =
    platform === 'ios'
      ? { data: recentlyViewedAppsIos, update: updateRecentlyViewedAppsIos }
      : { data: recentlyViewedAppsAndroid, update: updateRecentlyViewedAppsAndroid };

  const { data: recentlyViewedApps, update: updateRecentlyViewedApps } = recentlyViewed;

  return { recentlyViewedApps, updateRecentlyViewedApps };
};
