import { Divider, Modal, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { DeviceOwner, ManagedApp } from './DeviceOwnersPopover';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { httpRequest } from '../../utils/network.service';
import { getNameOrEmail } from '../../utils/getDeviceOwnerNameOrEmail';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  currentManagedAppsOwner: DeviceOwner;
  currentManagedApp: ManagedApp;
}

export const DeviceOwnerUninstallAppModal = ({ isOpen, onClose, currentManagedAppsOwner, currentManagedApp }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const uninstallApp = async () => {
    setIsLoading(true);
    try {
      const path = `${process.env.PDM_SERVICE_URL}/pdm/device-owner/${encodeURIComponent(currentManagedAppsOwner.email)}/remove/${
        currentManagedApp.managedAppId
      }`;
      const { response } = await httpRequest({ path, method: 'POST' });
      if (response.status === 202) {
        enqueueSnackbar(`Successfully uninstalled ${currentManagedApp.name}`, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(`Error uninstalling ${currentManagedApp.name}`, {
          variant: 'error',
        });
      }
    } catch (err) {
      console.log('Error uninstalling app:', err);
      enqueueSnackbar(`Error uninstalling ${currentManagedApp.name}`, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  if (!currentManagedAppsOwner || !currentManagedApp) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      aria-labelledby="device-owner-uninstall-app-modal-title"
      aria-describedby="device-owner-uninstall-app-modal-description"
      onClose={onClose}
    >
      <StyledBox>
        <Typography variant="h3Medium" id="device-owner-uninstall-app-modal-title">
          Uninstall {currentManagedApp.name} for {getNameOrEmail(currentManagedAppsOwner)}?
          <StyledCloseIcon onClick={onClose} />
        </Typography>
        <StyledDivider />

        <div id="device-owner-uninstall-app-modal-description">
          <Typography variant="bodyRegular">
            What happens when the app is uninstalled:
            <ul style={{ listStylePosition: 'inside', paddingLeft: 0 }}>
              <li>
                The app will be removed from any enrolled device belonging to {getNameOrEmail(currentManagedAppsOwner)} on the target
                platform ({currentManagedApp.platform})
              </li>
              <li>
                The app will once again be available to install for {getNameOrEmail(currentManagedAppsOwner)} in their Q-Scout mobile app on
                the target platform
              </li>
            </ul>
          </Typography>
        </div>
        <StyledButtonsContainer>
          <KwButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </KwButton>
          <KwButton variant="filled" onClick={uninstallApp} disabled={isLoading}>
            Uninstall
          </KwButton>
        </StyledButtonsContainer>
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)`
  padding: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  background-color: #fafafa;
`;

const StyledButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;
