import { useState } from 'react';

export const useCycleState = states => {
  const [stateIndex, setStateIndex] = useState(0);

  const toggleSort = (index = null) => {
    if (typeof index === 'number') {
      setStateIndex(index);
      return;
    }

    if (stateIndex === states.length - 1) {
      setStateIndex(0);
      return;
    }

    setStateIndex(state => state + 1);
  };
  return [states[stateIndex], toggleSort];
};
