// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.intune-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 16px;
}

.intune-action-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.intune-sync-action-wrapper {
  display: flex;
  width: 100%;
  align-items: left;
}

.intune-sync-now-button-text {
  text-transform: capitalize;
  display: inline-block;
  margin-left: 4px;
  font-size: 16px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/Settings/intune-integration-card.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;EAC1B,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["\n.intune-logo {\n  height: 50px;\n  width: 50px;\n  border-radius: 50%;\n  vertical-align: middle;\n  margin-right: 16px;\n}\n\n.intune-action-wrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.intune-sync-action-wrapper {\n  display: flex;\n  width: 100%;\n  align-items: left;\n}\n\n.intune-sync-now-button-text {\n  text-transform: capitalize;\n  display: inline-block;\n  margin-left: 4px;\n  font-size: 16px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
