import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Gauge } from './Gauge';

/**
 * TODO: Meter in design is a slight ellipse. probably should swap out circle component with another in the future
 * @param props.value number between 0 and 10
 * @returns
 */
export const KwScoreGauge = ({ value }) => {
  // accomodate null value
  // eslint-disable-next-line no-param-reassign
  value = value || 0;
  return (
    <StyledCircleMeterContainer aria-labelledby={`${value}-label`}>
      <HiddenDiv id={`${value}-label`}>Score is {value}</HiddenDiv>
      <StyledGauge score={value} />
      <StyledScore variant="h4Bold">{value}</StyledScore>
    </StyledCircleMeterContainer>
  );
};

const size = 70;
const HiddenDiv = styled('div')`
  display: none;
`;
const StyledCircleMeterContainer = styled('div')`
  display: block;
  position: relative;
  width: ${size}px;
  height: 21px;
  transform: translateY(11px);
`;

const StyledGauge = styled(Gauge)`
  position: absolute;
`;

const StyledScore = styled(Typography)`
  position: absolute;
  top: 67%;
  width: ${size}px;
  text-align: center;
  height: 35px;
` as typeof Typography;
