import { Divider, Modal, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, SetStateAction } from 'react';
import { UninstallButton } from './UninstallButton';
import { DeviceOwner, ManagedApp } from './DeviceOwnersPopover';
import { useFetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { createQueryString } from '../../utils/createQueryString';
import { getNameOrEmail } from '../../utils/getDeviceOwnerNameOrEmail';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  currentManagedAppsOwner: DeviceOwner;
  setShowDeviceOwnerUninstallModal: Dispatch<SetStateAction<boolean>>;
  setCurrentManagedApp: Dispatch<SetStateAction<ManagedApp>>;
}

function useDeviceOwnerApps(queryParams, accessToken, shouldFetch) {
  const queryParamString = createQueryString(queryParams);
  const { data, error } = useFetchService(`/pdm/device-owner/apps?${queryParamString}`, accessToken, shouldFetch);
  const isLoading = !data && !error;

  return { apps: data, error, isLoading };
}

export const DeviceOwnerAppManagementModal = ({
  isOpen,
  onClose,
  currentManagedAppsOwner,
  setShowDeviceOwnerUninstallModal,
  setCurrentManagedApp,
}: IProps) => {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { apps = [] } = useDeviceOwnerApps(
    { ownerEmail: currentManagedAppsOwner?.email },
    accessToken,
    Boolean(currentManagedAppsOwner?.email),
  );

  if (!currentManagedAppsOwner) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      aria-labelledby="device-owner-app-management-modal-title"
      aria-describedby="device-owner-app-management-modal-description"
      onClose={onClose}
    >
      <StyledBox>
        <Typography variant="h3Medium" id="device-owner-app-management-modal-title">
          Managed apps for {getNameOrEmail(currentManagedAppsOwner)}
          <StyledCloseIcon onClick={onClose} />
        </Typography>
        <StyledDivider />

        <div id="device-owner-app-management-modal-description">
          <Typography variant="bodyRegular">
            Here is the list of Managed Apps currently installed on {getNameOrEmail(currentManagedAppsOwner)}&apos;s enrolled devices.
            devices. Clicking Uninstall will remove that app from all devices where the Device Owner has installed it. The app will will
            remain available to re-install via the Q-Scout Mobile app as long as it remains published.
          </Typography>
        </div>
        <br />
        <FlexContainer>
          <FlexTypography variant="h5Medium">Name</FlexTypography>
          <FlexTypography variant="h5Medium">OS</FlexTypography>
        </FlexContainer>
        <StyledDivider />
        <TableContainer>
          {apps.map(app => (
            <TableRow key={app.id}>
              <AppName>
                <img src={app.iconUrl} alt={`${app.name} icon`} width={24} height={24} style={{ marginRight: '8px' }} />
                {app.name}
              </AppName>
              <OS>{app.platform}</OS>
              {app.shouldRemove ? (
                <label style={{ fontSize: 12 }}>Uninstall pending</label>
              ) : (
                <UninstallButton
                  onClick={() => {
                    setShowDeviceOwnerUninstallModal(true);
                    setCurrentManagedApp(app);
                  }}
                />
              )}
            </TableRow>
          ))}
        </TableContainer>
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)`
  padding: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  background-color: #fafafa;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const FlexContainer = styled('div')({
  display: 'flex',
});

const FlexTypography = styled(Typography)({
  flex: 1,
});

const TableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0px;
`;

const TableRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 10px 10px 10px 0px;
`;

const AppName = styled('span')`
  flex: 1;
  align-items: center;
  flex-basis: 100px;
`;

const OS = styled('span')`
  flex: 1;
`;
