import { useState } from 'react';
import { useCycleState } from '../../utils/useCycleState';

export const useAppManagementSortParams = () => {
  const [appNameOrder, toggleAppNameOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [platformOrder, togglePlatformOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [vendorOrder, toggleVendorOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [versionOrder, toggleVersionOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [appIdOrder, toggleAppIdOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [numberOfDevicesOrder, toggleNumberOfDevicesOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [publishedOrder, togglePublishedOrder] = useCycleState([null, 'ASC', 'DESC']);

  const [orderBy, setOrderBy] = useState(null);

  const toggles = [
    toggleAppNameOrder,
    togglePlatformOrder,
    toggleVendorOrder,
    toggleVersionOrder,
    toggleAppIdOrder,
    toggleNumberOfDevicesOrder,
    togglePublishedOrder,
  ];

  const toggleExclude = triggeredToggle => () => {
    triggeredToggle();
    toggles.filter(toggle => toggle !== triggeredToggle).forEach(toggle => toggle(0));
  };

  const resetToggles = () => {
    toggles.forEach(toggle => toggle(0));
  };

  return {
    appNameOrder,
    toggleAppNameOrder: toggleExclude(toggleAppNameOrder),
    platformOrder,
    togglePlatformOrder: toggleExclude(togglePlatformOrder),
    vendorOrder,
    toggleVendorOrder: toggleExclude(toggleVendorOrder),
    versionOrder,
    toggleVersionOrder: toggleExclude(toggleVersionOrder),
    appIdOrder,
    toggleAppIdOrder: toggleExclude(toggleAppIdOrder),
    numberOfDevicesOrder,
    toggleNumberOfDevicesOrder: toggleExclude(toggleNumberOfDevicesOrder),
    publishedOrder,
    togglePublishedOrder: toggleExclude(togglePublishedOrder),
    orderBy,
    setOrderBy,
    resetToggles,
  };
};
