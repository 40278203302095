import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';

interface IProps {
  onClick: () => void;
}

export const AddDeviceOwnerButton = ({ onClick }: IProps) => {
  return (
    <StyledAddDeviceOwnerButton startIcon={<AddIcon />} variant="filled" onClick={onClick}>
      Add
    </StyledAddDeviceOwnerButton>
  );
};

const StyledAddDeviceOwnerButton = styled(KwButton)`
  height: 32px;
  box-shadow: none;
  margin-left: auto;
  float: right;
  &:focus {
    background-color: ${props => props.theme.palette.accent.primary};
  }
`;
