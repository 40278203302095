export enum AuthPermissions {
  LIST_DEVICE_APPS = 'list:device:apps',
  LIST_APP_VERSIONS = 'list:application:versions',
  READ_APP = 'read:application',
  UPDATE_ORG = 'update:org',
  CREATE_USER = 'create:user',
  LIST_RISK_INDICATORS = 'list:risk-indicators',
  CREATE_RISK_INDICATOR = 'create:org.risk-indicator',
  UPDATE_RISK_INDICATOR = 'update:org.risk-indicator',
  DELETE_RISK_INDICATOR = 'delete:org.risk-indicator',
  SYSTEM_ADMIN = 'system:admin',
  MENU_SETTINGS = 'menu:settings',
  MENU_DASHBOARD = 'menu:dashboard',
  MENU_DEVICES = 'menu:devices',
  MENU_DEVICE_OWNERS = 'menu:device-owners',
  MENU_APPLICATIONS = 'menu:applications',
  MENU_RULES = 'menu:rules',
  MENU_POLICIES = 'menu:policies',
  MENU_MANAGED_APPS = 'menu:managed-apps',
  MENU_RISK_INDICATORS = 'menu:risk-indicators',
  SETTINGS_SUPPORT_EMAIL = 'settings:org:support-email',
  SETTINGS_RISKY_LOCATIONS = 'settings:org:risky-locations',
  SETTINGS_INTEGRATIONS_OKTA = 'settings:integrations:okta',
  SETTINGS_INTEGRATIONS_MDM = 'settings:integrations:mdm',
}
