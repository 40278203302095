import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { UnenrollDeviceConfirmationModal } from './UnenrollDeviceConfirmationModal';

export const DeviceProfileHeader = ({ deviceName, deviceId }: { deviceName: string; deviceId: string }) => {
  const navigate = useNavigate();

  const [showUnenrollDeviceConfirmationModal, setShowUnenrollDeviceConfirmationModal] = useState(false);

  return (
    <>
      <UnenrollDeviceConfirmationModal
        showConfirmationModal={showUnenrollDeviceConfirmationModal}
        setShowConfirmationModal={setShowUnenrollDeviceConfirmationModal}
        selectedDevice={{ name: deviceName, id: deviceId }}
        afterSuccessFunc={() => navigate('/devices')}
      />
      <StyledHeaderText>{deviceName}</StyledHeaderText>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexGrow: 1 }}>
        <KwButton
          startIcon={<DeleteOutlineIcon />}
          onClick={() => setShowUnenrollDeviceConfirmationModal(!showUnenrollDeviceConfirmationModal)}
        >
          <Typography>Remove Device</Typography>
        </KwButton>
      </div>
    </>
  );
};

const StyledHeaderText = styled(Typography)`
  line-height: 45px;
  color: ${props => props.theme.palette.text.primary};
  // shorten leading trim on leftside to offset default whitespace
  margin-left: -4px;
  padding: 10px;
` as typeof Typography;

// @ts-ignore
StyledHeaderText.defaultProps = {
  component: 'h1',
  variant: 'h1Regular',
};
