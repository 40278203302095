import { Card, CardContent, InputBase, Link, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { useLocalStorage } from '../../utils/useLocalStorage';
// eslint-disable-next-line import/extensions
import { SettingsMode } from './enum/SettingsMode.enum';
import { sendHttpRequest } from '../../utils/network.service';
import { LaunchDarklyFlags } from '../../launch-darkly';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { AuthPermissions } from '../../auth/AuthPermissions';

interface OktaIntegrationCardProps {
  signInUrlPlaceholder: string;
  managementHintPlaceholder: { [key: string]: string };
  isAdmin: boolean;
  mode: { [key: string]: SettingsMode };
  setMode: React.Dispatch<React.SetStateAction<{ [key: string]: SettingsMode }>>;
  mutate: () => void;
}

export const OktaIntegrationCard = ({
  signInUrlPlaceholder,
  managementHintPlaceholder,
  isAdmin,
  mode,
  setMode,
  mutate,
}: OktaIntegrationCardProps) => {
  const isEditMode = mode.okta === SettingsMode.EDIT;
  const isReadMode = mode.okta === SettingsMode.READ;
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { organizationId } = jwtDecode(accessToken)['https://krwr.net/app_metadata'];
  const [isCopied, setIsCopied] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [signInUrl, setSignInUrl] = useState('');
  const [managementHint, setManagementHint] = useState({ ios: '', android: '' });
  const { enqueueSnackbar } = useSnackbar();

  const enrollmentLink = `${window.location.origin}/#/okta-troubleshooting?orgId=${organizationId}`;

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(enrollmentLink);
    setIsCopied(true);
  };

  useEffect(() => {
    // Resets 'isCopied' state after 3 seconds
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [isCopied]);

  useEffect(() => {
    if (signInUrl.length || managementHint.ios.length || managementHint.android.length) {
      setIsSaveButtonDisabled(false);
    }
    if (signInUrl.length === 0 && managementHint.ios.length === 0 && managementHint.android.length === 0) {
      setIsSaveButtonDisabled(true);
    }
  }, [managementHint, signInUrl]);

  // hardcoded for now until additional idp support is added
  const idpSource = 'Okta';

  const updateIdpCredentials = async () => {
    const method = signInUrlPlaceholder.length || managementHintPlaceholder.length ? 'PUT' : 'POST';
    const { ios, android } = managementHint;

    const body = { idpSource };

    if (ios.length) {
      Object.assign(body, { iosManagementHint: ios });
    }

    if (android.length) {
      Object.assign(body, { androidManagementHint: android });
    }

    if (signInUrl.length) {
      Object.assign(body, { signInUrl });
    }

    await sendHttpRequest({
      path: `${process.env.ORGANIZATION_SERVICE_URL}/organizations/identity-providers`,
      method,
      body,
      successMessage: 'Successfully updated Okta Verify configuration',
      errorMessage: 'Error updating Okta Verify configuration',
      enqueueSnackbar,
    });

    mutate();
    resetInputValues();
  };

  const resetInputValues = () => {
    setSignInUrl('');
    setManagementHint({ ios: '', android: '' });
    setMode({ ...mode, okta: SettingsMode.READ });
  };

  const flags: LaunchDarklyFlags['flags'] = useFlags();

  if (!flags?.oktaIntegration || !verifyAuthPermission(AuthPermissions.SETTINGS_INTEGRATIONS_OKTA)) return null;

  return (
    <StyledCard>
      <StyledCardContent>
        <StyledTypography variant="h4Bold">Okta Endpoint Management Attestation</StyledTypography>
        <StyledTypography variant="bodyRegular">
          Configure Endpoint Management Attestation for Mobile Devices using the Okta Verify App. See Okta documentation for Endpoint
          management{' '}
          <a
            href="https://help.okta.com/oie/en-us/content/topics/identity-engine/devices/config-mobile.htm"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </StyledTypography>
        <StyledTypography variant="h5Bold">Management Hints</StyledTypography>
        {isAdmin && isEditMode ? (
          <>
            <StyledTypography variant="bodyRegular">
              Copy the Secret Key you obtained when configuring management attestation for a Device Management Platform and paste it in the
              corresponding platform field below. Saving your key here will auto-publish Okta Verify as a Q-Scout Managed App. Your key will
              be used as the Management Hint to configure the Q-Scout deployment of the Okta Verify app.
            </StyledTypography>
            <StyledTypography variant="bodyBold">iOS</StyledTypography>
            <StyledInput
              placeholder={managementHintPlaceholder.ios}
              onChange={e => {
                setManagementHint({ ...managementHint, ios: e.target.value });
              }}
              inputProps={{ 'aria-label': 'managementHintIos' }}
              className="idp-form-input"
            />
            <StyledTypography variant="bodyBold">Android</StyledTypography>
            <StyledInput
              placeholder={managementHintPlaceholder.android}
              onChange={e => {
                setManagementHint({ ...managementHint, android: e.target.value });
              }}
              inputProps={{ 'aria-label': 'managementHintAndroid' }}
              className="idp-form-input"
            />
          </>
        ) : (
          <>
            <StyledTypography variant="bodyBold">iOS</StyledTypography>
            <StyledTypography>{managementHintPlaceholder.ios}</StyledTypography>
            <StyledTypography variant="bodyBold">Android</StyledTypography>
            <StyledTypography>{managementHintPlaceholder.android}</StyledTypography>
          </>
        )}

        <StyledTypography variant="h5Bold">Okta Sign-in URL</StyledTypography>
        {isAdmin && isEditMode ? (
          <>
            <StyledTypography variant="bodyRegular">
              Optionally input your organization&apos;s Okta sign-in URL. This URL will be used in the configuration of the managed version
              of Okta Verify deployed from Q-Scout so that Device Owners don&apos;t have to enter it when they add an Okta Verify account.
              Your Okta sign-in URL can be found in the drop down under your Username in the upper right of your Okta Admin Console. The URL
              is generally formatted like <strong>example.okta.com</strong>, but can be in other formats.
            </StyledTypography>
            <StyledInput
              placeholder={signInUrlPlaceholder}
              onChange={e => {
                setSignInUrl(e.target.value);
              }}
              inputProps={{ 'aria-label': 'signInUrl' }}
              className="idp-form-input"
            />
          </>
        ) : (
          <StyledTypography>{signInUrlPlaceholder}</StyledTypography>
        )}

        <StyledTypography variant="h5Bold">Enrollment Link</StyledTypography>
        <StyledTypography variant="bodyRegular">
          Copy this URL to use as your Enrollment Link under each platform configured in the Security -&gt; Device Integrations -&gt;
          Endpoint management section of your Okta Console. See Okta documentation for Endpoint management.
        </StyledTypography>
        <Tooltip title={isCopied ? 'Copied!' : 'Copy Enrollment Link'} placement="right">
          <StyledCopyLinkSection onClick={handleCopyLink}>
            <ContentCopyIcon />
            <span>{enrollmentLink}</span>
          </StyledCopyLinkSection>
        </Tooltip>
        <StyledButtonContainer>
          {isAdmin && isEditMode ? (
            <>
              <StyledLink onClick={resetInputValues}>Cancel</StyledLink>
              <StyledKwButton variant="filled" onClick={updateIdpCredentials} disabled={isSaveButtonDisabled}>
                Save
              </StyledKwButton>
            </>
          ) : (
            <>
              {isAdmin && isReadMode ? (
                <StyledKwButton variant="filled" onClick={() => setMode({ ...mode, okta: SettingsMode.EDIT })}>
                  Edit
                </StyledKwButton>
              ) : (
                <StyledKwButton disabled variant="filled">
                  Save
                </StyledKwButton>
              )}
            </>
          )}
        </StyledButtonContainer>
      </StyledCardContent>
    </StyledCard>
  );
};

export const StyledCard = styled(Card)`
  width: 40%;
  min-width: 300px;
  margin: 20px;
  height: 100%;
`;

export const StyledCardContent = styled(CardContent)`
  background-color: #fafafa;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  margin: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:nth-of-type(1) {
    color: #487f87;
  }
`;

export const StyledInput = styled(InputBase)<{ error?: boolean }>`
  width: 100%;
  background-color: #ffffff;
  border: ${props => (props.error ? '2px solid #f44336' : '1px solid rgb(198, 198, 198)')};
  border-radius: 5px;
  padding: 4px;
  &::before {
    border-bottom: none;
  }
`;

export const StyledTypography = styled(Typography)`
  margin: 20px 0;
  display: block;
`;

export const StyledButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  column-gap: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledCopyLinkSection = styled('div')`
  display: flex;
  align-items; center;
  column-gap: 6px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const StyledKwButton = styled(KwButton)`
  width: 90px;
  height: 48px;
  padding: 8px 30px;
`;
