import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Card, CardHeader, CardContent, styled, Box, Select, MenuItem } from '@mui/material';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, LayersControl, useMap, Tooltip, LayerGroup } from 'react-leaflet';
import { dateRange } from '../shared/constants';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useFetchService } from '../../utils/fetchService';
import { createQueryString } from '../../utils/createQueryString';
import { PageLoader } from '../../PageLoader';
import { MapScrollWheelZoomNotifier, scrollInstructions } from '../shared/MapScrollWheelZoomNotifier';
import './css/customizedMap.css';
import '../shared/css/mapScrollWheelZoomNotifier.css';

const maxMarkerSize = 60;

const Legend = () => {
  const map = useMap();

  const legend = useMemo(() => new L.Control({ position: 'bottomright' }), []);

  useEffect(() => {
    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      const types = [
        { label: 'Number of devices', color: 'rgba(255, 204, 9, 0.38)' },
        { label: 'Number of apps', color: ' rgba(16, 89, 191, 0.38)' },
      ];

      div.innerHTML = types.map(type => `<i style="background:${type.color}"></i> ${type.label}`).join('<br>');

      return div;
    };
    legend.addTo(map);
  }, [legend, map]);
  return null;
};

function useCountriesContactedList(queryParams, accessToken) {
  const queryParamString = createQueryString(queryParams);
  const { data, error } = useFetchService(`/device/countries-contacted-list?${queryParamString}`, accessToken);

  const isLoading = !data && !error;

  return { countries: data, error, isLoading };
}

export function CountriesContactCard({
  initialDateRange,
  setCustom,
}: {
  initialDateRange: number;
  setCustom: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { countries = [], isLoading } = useCountriesContactedList({ range: selectedDateRange }, accessToken);

  const filteredCountries = countries.filter(country => country.latitude && country.longitude);
  const devicesMaxCount = Math.max(...filteredCountries.map(country => country.devicesCount));
  const appsMaxCount = Math.max(...filteredCountries.map(country => country.appsCount));

  useEffect(() => {
    setSelectedDateRange(initialDateRange);
  }, [initialDateRange]);

  const handleDateRangeChange = event => {
    setSelectedDateRange(event.target.value);
    if (event.target.value !== initialDateRange) {
      setCustom(true);
    }
  };

  const MarkerWithToolTip = ({
    marker,
    className,
  }: {
    marker: {
      latitude: number;
      longitude: number;
      count: number;
      maxCount: number;
      countryName: string;
    };
    className: 'appsCustomMarker' | 'devicesCustomMarker';
  }) => {
    const { countryName, count, maxCount, latitude, longitude } = marker;

    const iconSize = Math.max(10, (count * maxMarkerSize) / maxCount);
    const icon = L.divIcon({
      className,
      html: `<div><span>${count}</span></div>`,
      iconSize: [iconSize, iconSize],
    });

    return (
      <Marker icon={icon} position={[latitude, longitude]} key={JSON.stringify([latitude, longitude])}>
        <Tooltip direction="top">
          <div>
            <i>{countryName}</i>
          </div>
        </Tooltip>
      </Marker>
    );
  };

  return (
    <StyledCard>
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4Medium">Countries Contacted</Typography>
            <Select variant="standard" value={selectedDateRange} onChange={handleDateRangeChange} disableUnderline>
              {dateRange.map(range => (
                <MenuItem key={range.value} value={range.value}>
                  <em>{range.label}</em>
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
      />
      <CardContent>
        {isLoading && <PageLoader />}
        <StyledMap
          id="map"
          center={[39.8397, 24.0297]}
          zoom={2}
          zoomControl={true}
          maxZoom={6}
          scrollWheelZoom={false}
          whenReady={() => {
            const mapElement = document.getElementById('map');
            mapElement.setAttribute('data-scroll-instructions', scrollInstructions);
          }}
        >
          <MapScrollWheelZoomNotifier />
          <TileLayer
            url={`https://api.maptiler.com/maps/bright-v2/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
            attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            tileSize={512}
            zoomOffset={-1}
          />
          <Legend />
          <LayersControl position="topright">
            <LayersControl.Overlay checked name={`Number of devices`}>
              <LayerGroup>
                {filteredCountries.map(({ countryName, devicesCount, latitude, longitude }, index) => (
                  <MarkerWithToolTip
                    marker={{ countryName, count: devicesCount, maxCount: devicesMaxCount, latitude, longitude }}
                    className={'devicesCustomMarker'}
                    key={index}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>

            <LayersControl.Overlay name={`Number of apps`}>
              <LayerGroup>
                {filteredCountries.map(({ countryName, appsCount, latitude, longitude }, index) => (
                  <MarkerWithToolTip
                    marker={{ countryName, count: appsCount, maxCount: appsMaxCount, latitude, longitude }}
                    className={'appsCustomMarker'}
                    key={index}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
        </StyledMap>
      </CardContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card)({
  borderRadius: '8px',
  boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  backgroundColor: '#FAFAFA',
  padding: '8px',
});

const StyledMap = styled(MapContainer)({ height: '517px', width: '100%' });
