import { FormControl, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { useState } from 'react';
import { useSelect } from 'use-mui';
import { KwSelect, SelectArrowDown, SelectArrowUp, StyledOutlineInput } from '../KwSelect';
import { IMultiSelectProps } from './@types/IMultiSelectProps';

export const useKwMultiSelect = (defaultValue: any[] = []) => {
  const { handleChange, value, setValue } = useSelect({
    defaultValue,
  });
  const reset = () => {
    setValue(defaultValue);
  };
  return {
    handleChange,
    value,
    reset,
  };
};

type IKwMultiSelect = IMultiSelectProps & Partial<ReturnType<typeof useKwMultiSelect>>;

export const KwMultiSelect = ({ label: selectLabel, items, handleChange, value: selectedValues }: IKwMultiSelect) => {
  const [open, setOpen] = useState(false);
  const id = _.camelCase(selectLabel);
  const hasItems = Boolean(items.filter(item => item.label && item.value).length > 0);

  return (
    <FormControl>
      <KwSelect
        displayEmpty
        multiple
        disabled={!hasItems}
        label={<div id={id}>{selectLabel} select dropdown</div>}
        renderValue={(_selected: any[]) =>
          (selectedValues as any[]).length ? (
            // eslint-disable-next-line react/forbid-component-props
            <SelectLabel sx={{ paddingRight: '40px' }}>{selectLabel}</SelectLabel>
          ) : (
            <SelectLabel>{selectLabel}</SelectLabel>
          )
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        input={<StyledOutlineInput />}
        onChange={handleChange}
        value={selectedValues}
        IconComponent={_props =>
          hasItems ? (
            <>
              {selectedValues.length > 0 ? <SelectCounter>{(selectedValues as any[]).length}</SelectCounter> : ''}
              {open ? <SelectArrowUp /> : <SelectArrowDown />}
            </>
          ) : null
        }
      >
        {items.map(({ value, label }) => {
          if (value && label) {
            return (
              <StyledMenuItem key={JSON.stringify(label)} value={value}>
                {selectedValues.includes(value) ? (
                  <StyledListItemIcon>
                    <CheckedBox alt="selected-item-icon" src={'/icons/check-square.svg'} />
                  </StyledListItemIcon>
                ) : (
                  <StyledListItemIcon>
                    <CheckBox alt="unselected-item-icon" src={'/icons/square.svg'} />
                  </StyledListItemIcon>
                )}
                <Typography variant="label14Regular">{label}</Typography>
              </StyledMenuItem>
            );
          }
          return null;
        })}
      </KwSelect>
    </FormControl>
  );
};

/** styles */
const CheckedBox = styled('img')`
  filter: ${props => props.theme.palette.accent.primaryFilter};
`;

const CheckBox = styled('img')`
  filter: ${props => props.theme.palette.greyFilter[600]};
`;

const SelectLabel = styled('div')`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.palette.text.primary};
`;

const SelectCounter = styled('div')`
  width: 22px;
  height: 20px;
  border-radius: 12px;
  flex-shrink: 0;
  text-align: center;
  background-color: ${props => props.theme.palette.accent[400]};
  ${props => props.theme.typography.smallRegular as any}
  color: white;
  position: absolute;
  pointer-events: none;
  right: 30px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 28px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    min-width: 135px;
    padding: 8px;
    box-shadow: 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%);
    background-color: #fafafa;
    min-height: 36px;
    height: 36px;
    box-shadow: none;
    &.Mui-selected {
      background-color: #fafafa;
    }
    :hover {
      background-color: #eeeeee;
    }
  }
`;
