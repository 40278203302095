import './BackgroundProgressBar.css';
import ErrorIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';
import SyncIcon from '@mui/icons-material/Sync';
import { useEffect, SetStateAction, Dispatch, useContext } from 'react';
import { ProgressEventProps, socket } from '../../utils/rt';
import { useBackgroundProgressBar } from './BackgroundProgressBarProvider';

function iconForStatus(status: string) {
  switch (status) {
    case 'success':
      return <DoneIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'warning':
      return <WarningAmberIcon />;
    case 'in-progress':
      return <SyncIcon className="rotating" />;
    default:
      return <InfoIcon />;
  }
}

export function BackgroundProgressBar() {
  const {
    setIsConnected,
    setStatusEvent,
    progressEvent,
    setProgressEvent,
  }: {
    setIsConnected: Dispatch<SetStateAction<boolean>>;
    setStatusEvent: Dispatch<SetStateAction<string>>;
    progressEvent: ProgressEventProps;
    setProgressEvent: Dispatch<SetStateAction<ProgressEventProps>>;
  } = useBackgroundProgressBar();

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onStatusEvent(value) {
      console.log(`status: ${JSON.stringify(value)}`);
      setStatusEvent(value);
    }

    function onProgressEvent(value) {
      console.log(`progress: ${JSON.stringify(value)}`);
      setProgressEvent(value);
      if (value.status !== 'in-progress') {
        setTimeout(() => setProgressEvent({ progressValue: 0, progressMax: 0, message: '', description: '', status: '' }), 3000);
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('status', onStatusEvent);
    socket.on('progress', onProgressEvent);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('status', onStatusEvent);
      socket.off('progress', onProgressEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    progressEvent?.status && (
      <div className="bg-progress">
        <div className="bg-progress-bar">
          <div
            className="bg-progress-bar-fill"
            style={{ width: `${(progressEvent.progressValue / progressEvent.progressMax) * 100}%` }}
          ></div>
        </div>
        <div className="bg-progress-body">
          <div className="bg-progress-icon">{iconForStatus(progressEvent.status)}</div>
          <div className="bg-progress-text">
            <div className="bg-progress-message">{progressEvent.message}</div>
            <div className="bg-progress-description">{progressEvent.description}</div>
          </div>
        </div>
      </div>
    )
  );
}
