import { styled } from '@mui/material/styles';

export const SortIcon = ({ order }) => {
  let src;
  if (order === 'ASC') {
    src = {
      src: '/icons/sort-ascending.svg',
      alt: 'sort-ascending-icon',
    };
  } else if (order === 'DESC') {
    src = {
      src: '/icons/sort-descending.svg',
      alt: 'sort-descending-icon',
    };
  } else {
    src = null;
  }

  return order ? <StyledSortIcon {...src} width={13} height={11} /> : null;
};

const StyledSortIcon = styled('img')`
  margin-left: 10px;
`;
