import clsx from 'clsx';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import * as React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export type LinkProps = {
  activeClassName?: string;
  noLinkStyle?: boolean;
  className?: string;
  to?: RouterLinkProps['to']; // made optional due to existing next architecture
  href: RouterLinkProps['to'];
} & Omit<MuiLinkProps, 'href' | 'color'>;

// eslint-disable-next-line prefer-arrow-callback
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function navLink(props, ref) {
  const { activeClassName = 'active', className: classNameProps, href, to, noLinkStyle, ...other } = props;

  const pathname = typeof href === 'string' ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: window.location.hash.replace('#', '') === pathname && activeClassName,
  });

  const isExternal = typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

  if (isExternal) {
    if (noLinkStyle) {
      return <a className={className} href={href} ref={ref} {...other} />;
    }

    return <MuiLink className={className} href={href} ref={ref} {...other} />;
  }

  if (noLinkStyle) {
    return <RouterLink className={className} ref={ref} to={to} {...other} />;
  }

  return <MuiLink component={RouterLink} className={className} ref={ref} to={href} {...other} />;
});

export { Link };
