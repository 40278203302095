import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import Typography from '@mui/material/Typography';
import { PermDeviceInformation } from '@mui/icons-material';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';

interface IProps {
  permissionsRequested: {
    name: string;
    protectionLevel: string;
  }[];
}

export default function PermissionsRequestedTable({ permissionsRequested }: IProps) {
  if (permissionsRequested == null || permissionsRequested.length === 0) {
    return (
      <StyledCenteredContainer>
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3256_86792)">
            <path
              d="M31.5 14C31.5 12.3406 32.8406 11 34.5 11C36.1594 11 37.5 12.3406 37.5 14C37.5 15.6594 36.1594 17 34.5 17C32.8406 17 31.5 15.6594 31.5 14ZM31.5 33.5C30.6094 33.5 29.7375 33.4344 28.8844 33.2937L26.2594 35.7594C25.9219 36.2656 25.35 36.5 24.75 36.5H21V40.25C21 41.4969 19.9969 42.5 18.75 42.5H15V46.25C15 47.4969 13.9969 48.5 12.75 48.5H2.25C1.00781 48.5 0 47.4969 0 46.25V35.75C0 35.15 0.237094 34.5781 0.658969 34.1562L15.2062 19.6156C15.075 18.7625 15 17.8906 15 17C15 7.8875 22.3875 0.5 31.5 0.5C40.6125 0.5 48 7.8875 48 17C48 26.1125 40.6125 33.5 31.5 33.5ZM31.5 29C38.1281 29 43.5 23.6281 43.5 17C43.5 10.3719 38.1281 5 31.5 5C24.8719 5 19.5 10.3719 19.5 17C19.5 17.6469 19.5469 18.2844 19.65 18.9031L20.0062 21.1719L4.41562 36.6781V44H10.4156V38H16.4156V32H23.8219L27.3281 28.4938L29.5969 28.85C30.2156 28.9531 30.8531 29 31.5 29Z"
              fill="#244C5A"
            />
          </g>
          <defs>
            <clipPath id="clip0_3256_86792">
              <rect width="48" height="48" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>

        <div style={{ marginTop: '16px' }}>No requested permissions found</div>
      </StyledCenteredContainer>
    );
  }

  return (
    <>
      <StyledCardTitle>Permissions Requested</StyledCardTitle>
      <KwTable aria-label="customized table">
        <KwTableBody>
          {permissionsRequested.map(permission => {
            return (
              <KwTableRow key={permission.name}>
                <KwTableCell>
                  {permission.protectionLevel === 'Important' && <StyledWarningIcon color={'error'} />}
                  {permission.name}
                </KwTableCell>
              </KwTableRow>
            );
          })}
        </KwTableBody>
      </KwTable>
    </>
  );
}

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.base.grey,
  '&.MuiSvgIcon-colorError': {
    color: theme.palette.error.main,
  },
  display: 'inline-block',
  margin: '5px 5px -6px 0',
}));

const StyledCenteredContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
`;

const StyledCardTitle = styled('div')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: '500',
  color: '#000',
});
