import { styled } from '@mui/system';
import { InputBase, Chip } from '@mui/material';
import { ClipboardEvent, ChangeEvent, KeyboardEvent, useState, Dispatch, SetStateAction, forwardRef, useImperativeHandle } from 'react';
import { validateEmail, globalValidEmailPattern } from '../../utils/emailValidation';
import { KwFormErrorMessage } from '../../kw-ui-components/KwFormErrorMessage';

interface IProps {
  setEmails: Dispatch<SetStateAction<string[]>>;
  setEmailInputValue: Dispatch<SetStateAction<string>>;
  duplicateMessage: string;
  emails?: string[];
  emailInputValue: string;
}

// eslint-disable-next-line react/display-name
export const EmailAddressEntry = forwardRef(({ setEmails, duplicateMessage, emails, emailInputValue, setEmailInputValue }: IProps, ref) => {
  const [emailInputErrors, setEmailInputErrors] = useState([]);

  const isIncluded = (email: string) => {
    return emails.includes(email);
  };

  const isValid = (email: string | { invalid: string[]; duplicate: string[] }) => {
    const errors = [];

    const generateErrorMessage = (flaggedEmails: string[], errorDescription: string) => {
      const emailClause = flaggedEmails.length > 1 ? `${flaggedEmails.join(', ')} have` : `${flaggedEmails} has`;
      return `${emailClause} ${errorDescription}`;
    };
    // generate errors for email input pasted from clipboard
    if (typeof email === 'object') {
      // eslint-disable-next-line guard-for-in
      for (const flagged in email) {
        const errorArr = email[flagged];
        if (flagged === 'invalid' && errorArr.length) {
          const { invalid } = email;
          const invalidated =
            invalid.length > 1 ? `${invalid.join(', ')} are not valid email addresses.` : `${invalid} is not a valid email address.`;
          errors.push(`The input text ${invalidated}`);
        } else if (errorArr.length) {
          errors.push(generateErrorMessage(email[flagged], duplicateMessage));
        }
      }
    }
    // generate errors for manual user input
    if (typeof email === 'string') {
      const manualInputChecks: { [key: string]: boolean } = {
        invalid: !validateEmail(email),
        duplicate: isIncluded(email),
      };

      for (const flagged in manualInputChecks) {
        if (flagged === 'invalid' && manualInputChecks[flagged]) {
          errors.push(`The input text ${email} is not a valid email address.`);
        } else if (manualInputChecks[flagged]) {
          errors.push(`${email} has ${duplicateMessage}`);
        }
      }
    }

    if (errors.length) {
      setEmailInputErrors(errors);
      return false;
    }
    setEmails(emails);
    setEmailInputErrors([]);
    return true;
  };

  useImperativeHandle(ref, () => ({
    isValid,
  }));

  const handleAddEmails = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailInputValue(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (['Tab', ',', ' '].includes(e.key) && emailInputValue !== '') {
      e.preventDefault();

      const email = emailInputValue.toLowerCase().trim();

      if (email && isValid(email)) {
        setEmails([...emails, email]);
        setEmailInputValue('');
      }
    }

    if (['Backspace'].includes(e.key)) {
      if (emails.length && emailInputValue === '') {
        setEmails([...emails.slice(0, -1)]);
        setEmailInputErrors([]);
      }
      setEmailInputErrors([]);
    }
  };

  const handleDelete = (toBeRemoved: string) => {
    setEmails(emails.filter(email => email !== toBeRemoved));
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text').toLowerCase();
    validateInput(paste);
  };

  const validateInput = (emailText: string) => {
    const formattedText = emailText.toLowerCase();
    const validEmails = formattedText.match(globalValidEmailPattern);
    if (!validEmails) return;

    // trim white space, and filter out empty strings (user pastes list with leading or trailing comma)
    const sanitized = [...formattedText.replaceAll(' ', '').replaceAll('\n', '').trim().split(',')].filter(email => email);

    // store invalid / duplicate emails from user input for error generation
    const invalid = sanitized.filter(email => !validEmails.includes(email));
    // checks for duplicates in clipboardData or state (emails)
    const duplicate = sanitized.filter((email, index) => sanitized.indexOf(email) !== index || isIncluded(email));

    // remove duplicates from paste input not yet stored in state
    if (validEmails) {
      const toBeAdded = new Set(validEmails.filter(email => !isIncluded(email)));
      setEmails([...emails, ...toBeAdded]);
    }

    if (invalid.length || duplicate.length) {
      isValid({ invalid, duplicate });
    } else if (emailInputErrors.length) {
      setEmailInputErrors([]);
    }
  };

  return (
    <FlexContainer>
      <StyledLabel htmlFor="email-input">
        {emails.length > 0 && (
          <ChipContainer className="chip-wrapper">
            {emails.map(email => (
              <StyledChip
                label={email}
                key={email}
                onDelete={() => {
                  handleDelete(email);
                  setEmailInputErrors([]);
                }}
              />
            ))}
          </ChipContainer>
        )}
        <StyledInputBase
          id="email-input"
          placeholder="Email(s)"
          type="email"
          inputProps={{ multiple: true }}
          value={emailInputValue}
          onChange={handleAddEmails}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          autoFocus
          className="email-input"
        />
      </StyledLabel>
      {emailInputErrors && (
        <StyledErrorMessageWrapper>
          {emailInputErrors.map((error: string) => (
            <KwFormErrorMessage key={error} error={Boolean(emailInputErrors)} reason={error} />
          ))}
        </StyledErrorMessageWrapper>
      )}
    </FlexContainer>
  );
});

const FlexContainer = styled('div')`
  flex: 1 1 0;
  margin-top: 8px;
`;

const StyledLabel = styled('label')`
  background-color: rgb(255, 255, 255);
  border: 1px solid #bdbdbd !important;
  border-radius: 4px;
  display: block;
  min-height: 38px;
  height: 100%;
  & > .MuiInputBase-root {
    border: none;
    appearance: none;
    font-size: 13px;
    margin: 0;
  }
`;

const StyledChip = styled(Chip)`
  margin: 2px;
`;

const StyledErrorMessageWrapper = styled('div')`
  margin: 14px 0;
`;

const ChipContainer = styled('div')`
  padding: 6px;
`;

const StyledInputBase = styled(InputBase)`
  background-color: rgba(255, 255, 255, 1);
  border: 0;
  border-radius: 4px;
  display: flex;
  height: 38px;
  margin: 14px 0;
  padding: 12px;
  input {
    font-size: 13px;
    opacity: 0.5;
  }
  &.Mui-disabled {
    background-color: ${props => props.theme.palette.greyOverride[200]};
  }
  &.Mui-focused {
    border: 1px solid ${props => props.theme.palette.accent.primary};
  }
  &.Mui-focused.email-input {
    border: none;
  }
`;
