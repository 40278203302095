import { styled } from '@mui/material/styles';
import { MouseEventHandler } from 'react';
import { KwTableCell } from './KwTableCell';
import { SortIcon } from '../components/shared/SortIcon';

type SortHeaderCellProps = {
  title: string;
  sortValue: 'ASC' | 'DESC' | null;
  onClick: MouseEventHandler<HTMLTableCellElement>;
};

export const KwColumnSortHeader = ({ title, sortValue, onClick }: SortHeaderCellProps) => {
  let ariaSort;
  if (sortValue === 'ASC') ariaSort = 'ascending';
  else if (sortValue === 'DESC') ariaSort = 'descending';
  else ariaSort = 'none';

  return (
    <KwTableCell
      {...(onClick && {
        ['aria-sort']: ariaSort,
        ['aria-label']: `filter-by-${title}-button`,
        onClick,
      })}
    >
      <StyledTableColumnHead>
        {title} <SortIcon order={sortValue} />
      </StyledTableColumnHead>
    </KwTableCell>
  );
};

const StyledTableColumnHead = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 800;
`;
