import { InputLabel, OutlinedInput } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { mergeDeep } from '../utils/mergeDeep';

export const KwSelect = styled(({ MenuProps, ...props }: SelectProps) => (
  <Select
    MenuProps={mergeDeep(
      {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          horizontal: 'left',
          vertical: 'top',
        },
        MenuListProps: { sx: { padding: 0 } },
        PaperProps: {
          sx: {
            borderRadius: '0 0 2px 2px',
          },
        },
      },
      MenuProps,
    )}
    {...props}
  />
))`
  height: 36px;
`;

export const StyledOutlineInput = styled(OutlinedInput)`
  background-color: #eeeeee;
  height: 32px;
  column-gap: 3px;
  .MuiSelect-select {
    ${props => props.theme.typography.label14Regular as any}
  }
  & > *:not(.MuiSelect-select) {
    flex-shrink: 0;
  }

  & .MuiOutlinedInput-notchedOutline {
    height: 32px;
    border: none;
  }
  & .MuiSelect-outlined {
    padding: 6px 10px;
    box-sizing: border-box;
    height: 32px;
  }
`;

export const SelectArrowDown = props => (
  <SelectArrowWrapper {...props}>
    <SelectIcon width={10} height={6} alt="select-down-arrow" src={'/icons/chevron-down.svg'} />
  </SelectArrowWrapper>
);

export const SelectArrowUp = props => (
  <SelectArrowWrapper {...props}>
    <SelectIcon width={10} height={6} alt="select-up-arrow" src={'/icons/chevron-up.svg'} />
  </SelectArrowWrapper>
);

export const SelectArrowWrapper = styled('div')`
  width: 16px;
  height: 16px;
  text-align: center;
  transform: translateY(-4px);
  position: absolute;
  pointer-events: none;
  right: 10px;
`;

export const SelectIcon = styled('img')`
  && {
    filter: ${props => props.theme.palette.greyFilter[800]};
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 14px;
  padding-top: 1px;
`;

export const StyledSelect = styled(KwSelect)`
  min-width: 330px;
  width: auto;
  height: 55px;
  div.MuiSelect-select.MuiOutlinedInput-input {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    font-size: 14px;
    line-height: 1.4375em;
    padding-right: 30px;
  }
  .MuiInputBase-input:focus {
    background-color: transparent;
  }
`;
