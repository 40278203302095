import { createContext, useState } from 'react';
// eslint-disable-next-line import/extensions
import { RiskIndicatorProfileMode } from './enum/RiskIndicatorProfileMode.enum';

export const RiskIndicatorInfoHook = () => {
  const [activeRiskIndicator, setActiveRiskIndicator] = useState({});
  const [activeOrganization, setActiveOrganization] = useState('');
  const [mode, setMode] = useState(RiskIndicatorProfileMode.CREATE);

  return {
    activeRiskIndicator,
    setActiveRiskIndicator,
    activeOrganization,
    setActiveOrganization,
    mode,
    setMode,
  };
};

export const RiskIndicatorInfoContext = createContext(undefined);
