import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import { styled } from '@mui/material/styles';

export interface DataItem {
  key: string;
  value: number;
  color: string;
}

export interface AppDetailsPieChartProps {
  data: DataItem[];
  title: string;
  emptyText?: string;
}

interface CustomLabelProps {
  viewBox?: { cx: number; cy: number };
  value1: number;
}

const CustomLabel: React.FC<CustomLabelProps> = ({ viewBox, value1 }) => {
  const { cx, cy } = viewBox!;
  const fontSize = value1.toString().length > 3 ? '30px' : '46px';
  return (
    <text x={cx} y={cy} fill="black" textAnchor="middle" dominantBaseline="middle">
      <tspan x={cx} dy="-0.05em" fontSize={fontSize} fontWeight="600">
        {value1}
      </tspan>
      <tspan x={cx} dy="2.25em" fontSize="14px" fontWeight="600">
        TOTAL
      </tspan>
    </text>
  );
};

const AppDetailsPieChart: React.FC<AppDetailsPieChartProps> = ({ data, title, emptyText }) => {
  const total = data.reduce((acc, item) => acc + item.value, 0);

  if (!total) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.667 24.5C46.667 25.7 45.8668 26.5 44.6664 26.5H24.6608C23.4605 26.5 22.6603 25.7 22.6603 24.5V4.5C22.6603 3.3 23.4605 2.5 24.6608 2.5C36.8643 2.5 46.667 12.3 46.667 24.5ZM44.8665 33.1C41.4655 41.5 33.2632 46.5 24.6608 46.5C21.8601 46.5 18.8592 45.9 16.0584 44.7C4.85531 39.9 -0.346141 27.1 4.4552 15.9C6.65581 10.7 10.6569 6.5 15.8584 4.3C16.8587 3.9 18.059 4.3 18.4591 5.3C18.8592 6.3 18.4591 7.5 17.4588 7.9C13.2577 9.9 9.85671 13.3 8.05621 17.5C4.25514 26.7 8.45632 37.3 17.6589 41.1C26.8615 44.9 37.4644 40.7 41.2655 31.5C41.6656 30.5 42.8659 30.1 43.8662 30.5C44.8665 30.9 45.2666 32.1 44.8665 33.1ZM26.6614 6.7C34.8637 7.5 41.4655 14.1 42.4658 22.5H26.6614V6.7Z"
              fill="#244C5A"
            />
          </svg>
          <p>{emptyText || 'No data available'}</p>
        </div>
      </div>
    )
  }

  return (
    <>
      <StyledCardTitle>{title}</StyledCardTitle>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{ marginRight: '20px', marginLeft: 'auto' }}>
          {data.map((item, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              {item.value ? (
                <>
                  <div
                    style={{
                      width: '18px',
                      height: '18px',
                      backgroundColor: item.color,
                      borderRadius: '50%',
                      marginRight: '5px',
                    }}
                  ></div>
                  <span>{`${item.key} (${item.value})`}</span>
                </>
              ) : null}
            </div>
          ))}
        </div>
        <div style={{ marginRight: 'auto' }}>
          <PieChart width={250} height={250}>
            <Pie data={data} cx="50%" cy="50%" innerRadius={50} outerRadius={100} fill="#8884d8" paddingAngle={1} dataKey="value">
              {data.map((entry, index) => (
                // eslint-disable-next-line react/forbid-component-props
                <Cell key={`cell-${index}`} fill={entry.color} style={{ outline: 'none' }} />
              ))}
              <Label content={<CustomLabel value1={total} />} position="center" />
            </Pie>
          </PieChart>
        </div>
      </div>
    </>
  );
};

const StyledCardTitle = styled('div')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: '500',
  color: '#000',
});

export default AppDetailsPieChart;
