import { useState } from 'react';
import { useCycleState } from '../../utils/useCycleState';

export const useDeviceFilterParams = () => {
  const [deviceNameOrder, toggleDeviceNameOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [osOrder, toggleOsOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [securityRiskLevelOrder, toggleSecurityRiskLevelOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [privacyRiskLevelOrder, togglePrivacyRiskLevelOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [numberOfAppsOrder, toggleNumberOfAppsOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [onlyPolicyFailures, toggleOnlyPolicyFailures] = useState(false);

  const toggles = [toggleDeviceNameOrder, toggleOsOrder, toggleSecurityRiskLevelOrder, toggleNumberOfAppsOrder];

  const toggleExclude = triggeredToggle => () => {
    triggeredToggle();
    toggles.filter(toggle => toggle !== triggeredToggle).forEach(toggle => toggle(0));
  };

  const resetToggles = () => {
    toggles.forEach(toggle => toggle(0));
  };

  return {
    deviceNameOrder,
    toggleDeviceNameOrder: toggleExclude(toggleDeviceNameOrder),
    osOrder,
    toggleOsOrder: toggleExclude(toggleOsOrder),
    securityRiskLevelOrder,
    toggleSecurityRiskLevelOrder: toggleExclude(toggleSecurityRiskLevelOrder),
    privacyRiskLevelOrder,
    togglePrivacyRiskLevelOrder: toggleExclude(togglePrivacyRiskLevelOrder),
    numberOfAppsOrder,
    toggleNumberOfAppsOrder: toggleExclude(toggleNumberOfAppsOrder),
    resetToggles,
    onlyPolicyFailures,
    toggleOnlyPolicyFailures,
  };
};
