import { styled } from '@mui/system';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';

interface IProps {
  onClick: () => void;
}

export const UninstallButton = ({ onClick }: IProps) => {
  return (
    <StyledUninstallButton variant="outlined" onClick={onClick}>
      Uninstall
    </StyledUninstallButton>
  );
};

const StyledUninstallButton = styled(KwButton)`
  height: 32px;
  box-shadow: none;
  margin-left: auto;
  float: right;
  &:focus {
    background-color: ${props => props.theme.palette.accent.primary};
  }
`;
