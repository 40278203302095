import { Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { RiskIndicatorInfoContext } from '../RiskIndicators/RiskIndicatorInfoContext';

export default function RiskIndicatorProfileHeader() {
  const { activeRiskIndicator } = useContext(RiskIndicatorInfoContext);
  return <StyledHeaderText>Edit: {activeRiskIndicator.title}</StyledHeaderText>;
}

const StyledHeaderText = styled(Typography)`
  line-height: 45px;
  color: '${props => props.theme.palette.text.primary}';
  // shorten leading trim on leftside to offset default whitespace
  margin-left: -4px;
  flex-grow: 1;
` as typeof Typography;

// @ts-ignore
StyledHeaderText.defaultProps = {
  component: 'h1',
  variant: 'h1Regular',
};
