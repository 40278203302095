import { Modal, Divider, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { EmailAddressEntry } from '../shared/EmailAddressEntry';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';

interface EmailModalProps {
  onClose: () => void;
  currentEmails: string[];
  setCurrentEmails: Dispatch<SetStateAction<string[]>>;
  isEdit?: boolean;
}
export const PolicyActionAdminEmailModal = ({ onClose, currentEmails, setCurrentEmails, isEdit }: EmailModalProps) => {
  const [emails, setEmails] = useState(currentEmails ?? []);
  const [emailInputValue, setEmailInputValue] = useState('');
  const emailInputRef = useRef(null);

  const onSave = e => {
    e.preventDefault();
    if (emails.length > 0) {
      setCurrentEmails(emails);
    } else if (emailInputValue.length > 0) {
      const isValid = emailInputRef.current.isValid(emailInputValue);
      if (isValid) {
        setCurrentEmails([...emails, emailInputValue]);
        setEmailInputValue('');
      }
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="device-owner-app-management-modal-title"
      aria-describedby="device-owner-app-management-modal-description"
      onClose={onClose}
    >
      <StyledBox>
        <Typography variant="h3Medium" id="email-alert-modal-title">
          Email Alert
          <StyledCloseIcon onClick={onClose} />
        </Typography>
        <StyledDivider />
        <StyledEmailTextContainer>
          <Typography variant="bodyRegular">Alert emails will not be triggered for policies involving device rules.</Typography>
        </StyledEmailTextContainer>
        <FlexContainer>
          <FlexTypography variant="h5Medium">Email</FlexTypography>
        </FlexContainer>
        <StyledEmailTextContainer>
          <Typography variant="bodyRegular">Add emails you want to send alerts to. (up to 50 comma-separated emails)</Typography>
        </StyledEmailTextContainer>
        <StyledEmailContainer>
          <EmailAddressEntry
            ref={emailInputRef}
            setEmails={setEmails}
            duplicateMessage="already been added"
            emails={emails}
            emailInputValue={emailInputValue}
            setEmailInputValue={setEmailInputValue}
          />
        </StyledEmailContainer>
        <StyledButtonContainer>
          <StyledCancelButton variant="basic" onClick={() => onClose()}>
            Cancel
          </StyledCancelButton>
          <KwButton variant="filled" onClick={onSave}>
            {isEdit ? 'Update' : 'Add'}
          </KwButton>
        </StyledButtonContainer>
      </StyledBox>
    </Modal>
  );
};

const StyledCancelButton = styled(KwButton)`
  background-color: transparent;
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

const StyledEmailContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  width: 100%;
  align-items: flex-start;
`;

const StyledBox = styled(Box)`
  padding: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  background-color: #fafafa;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const StyledDivider = styled(Divider)`
  margin-top: 5px;
  margin-bottom: 10px;
`;

const FlexContainer = styled('div')({
  display: 'flex',
});

const FlexTypography = styled(Typography)({
  flex: 1,
});

const StyledEmailTextContainer = styled(FlexContainer)`
  margin-top: 10px;
  margin-bottom: -10px;
  &:nth-of-type(1) {
    margin-top: 14px;
    margin-bottom: 14px;
  }
`;
