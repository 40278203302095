import AppBar from '@mui/material/AppBar';
import { css, styled } from '@mui/material/styles';
import { drawerWidth } from '../Navigation/Drawer';

const dontForwardOpenProp = {
  shouldForwardProp: prop => prop !== 'open',
};

interface AppBarProps {
  open?: boolean;
}

export const appBarHeight = '56px';
export const KwAppBar = styled(AppBar, {
  ...dontForwardOpenProp,
})<AppBarProps>`
  /** hack to increase specificity */
  && {
    height: ${appBarHeight};
    z-index: ${props => props.theme.zIndex.drawer + 1};
    transition: ${props =>
      props.theme.transitions.create(['width', 'margin'], {
        easing: props.theme.transitions.easing.sharp,
        duration: props.theme.transitions.duration.leavingScreen,
      })};
    background-color: #fafafa;
    box-shadow: none;
    border-bottom: 1px solid #bdbdbd;
    ${props =>
      props.open
        ? css`
            margin-left: ${drawerWidth}px;
            width: calc(100% - ${drawerWidth}px);
            transition: ${props.theme.transitions.create(['width', 'margin'], {
              easing: props.theme.transitions.easing.sharp,
              duration: props.theme.transitions.duration.enteringScreen,
            })};
          `
        : ''}
  }
`;
