/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import type { ListResponse } from '@kw/rules-service/dist/src/types/ListResponse';
import type { PolicyRule } from '@kw/rules-service/dist/src/policy/models/policyRule.model';
import type { PolicyEntity, RuleEntity } from '@kw/service-definitions-rule';
import type { ViolationCounts } from '@kw/rules-service/dist/src/policy/dtos/CurrentTriggeredDevicesApplications.dto';
import { type PolicyAction, getActions, getMdmActions, PolicyActionGroup } from './getData';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { PolicyListCard } from './PolicyListCard';
import { PolicyStatusCard } from './PolicyStatusCard';
import { PolicySelectedListCard } from './PolicySelectedListCard';
// eslint-disable-next-line import/extensions
import { PolicyTab } from './enum/PolicyTab.enum';
import { PolicyStatisticsCard } from './PolicyStatisticsCard';
import { PolicyCreationCard } from './PolicyCreationCard';
// eslint-disable-next-line import/extensions
import { PolicyProfileMode } from './enum/PolicyProfileMode.enum';
import { sendHttpRequest } from '../../utils/network.service';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useFetchService } from '../../utils/fetchService';
import { PolicyInfoContext } from './PolicyInfoContext';
import { LaunchDarklyFlags } from '../../launch-darkly';
import { useOrg } from '../Organization/org-provider';
import { orgHasSetting } from '../Organization/org-utils';

export function PolicyProfilePage({ mode }: { mode: PolicyProfileMode }) {
  const {
    policyName,
    policyEnabled,
    policyDescription,
    policyFormErrors,
    setPolicyName,
    setPolicyEnabled,
    setPolicyDescription,
    setPolicyCreatedOn,
    setPolicyFormErrors,
  } = useContext(PolicyInfoContext);

  const navigate = useNavigate();
  const { policyId } = useParams();

  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { orgData } = useOrg();
  const isMdmMode = orgHasSetting(orgData, 'mdm-integration');
  const [tab, setTab] = useState(PolicyTab.RULES);
  const [mdmActions] = useState(getMdmActions());
  const [baseActions] = useState(getActions());

  const [selectedRules, setSelectedRules] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [policyData, setPolicyData] = useState(null);
  const [modalOptions, setModalOptions] = useState({ visible: false, row: null, isEdit: false });

  const { enqueueSnackbar } = useSnackbar();

  const flags: LaunchDarklyFlags['flags'] = useFlags();

  let rulesPath = '/rule/rules/list';
  let currentTriggeredDevicesPath = `/rule/policies/current-triggered-devices-applications?ruleIds=${selectedRules
    .map(rule => rule.id)
    .join(',')}`;

  let policiesPath = '/rule/policies/list';

  if (flags?.evalService) {
    rulesPath = rulesPath.replace('/rule/', '/eval/');
    currentTriggeredDevicesPath = currentTriggeredDevicesPath.replace('/rule/', '/eval/');
    policiesPath = policiesPath.replace('/rule/', '/eval/');
  }
  const { data: rulesData } = useFetchService<ListResponse<RuleEntity>>(rulesPath, accessToken);

  const { data: policyResponse } = useFetchService<ListResponse<PolicyEntity>>(policiesPath, accessToken);
  const { data: triggeredDevicesAndApps } = useFetchService<ViolationCounts>(
    currentTriggeredDevicesPath,
    accessToken,
    selectedRules.length > 0,
  );
  let actions;
  if (isMdmMode) {
    actions = mdmActions;
  } else {
    actions = baseActions;
  }

  useEffect(() => {
    if ([PolicyProfileMode.EDIT, PolicyProfileMode.VIEW].includes(mode)) {
      if (!policyResponse) {
        return;
      }
      const data = policyResponse.results.find((policy: PolicyEntity) => policy.id === policyId);

      setPolicyData(data);
      setSelectedRules(data.policyRules.map((policyRule: PolicyRule) => policyRule.rule));
      const emailNotification = data.policyActions?.find(a => a.action.name === 'NOTIFICATION');
      if (emailNotification) {
        for (const action of actions) {
          for (const subAction of action.actions) {
            if (subAction.key === 'NOTIFICATION') {
              subAction.emails = data.notificationEmails;
            }
          }
        }
      }
      const filteredActions = actions.reduce((ret, val: PolicyActionGroup) => {
        const actionIncluded = val.actions.filter(action => data.remediations.includes(action.key));
        if (actionIncluded.length) {
          ret = [...ret, ...actionIncluded];
        }
        return ret;
      }, []);
      setSelectedActions(filteredActions);
      setPolicyName(data.name);
      setPolicyEnabled(data.enabled);
      setPolicyDescription(data.description);
      setPolicyCreatedOn(data.createdAt);
    } else {
      setPolicyName('');
      setPolicyDescription('');
      setPolicyEnabled(true);
      setPolicyCreatedOn(dayjs().format('MM/DD/YYYY'));
    }
  }, [mode, actions, policyId, setPolicyDescription, setPolicyEnabled, setPolicyName, setPolicyCreatedOn, policyResponse, isMdmMode]);

  const addRules = (item: RuleEntity) => {
    setSelectedRules([...selectedRules, item]);
    updatePolicyRuleError();
  };

  const addActions = (item: PolicyAction) => {
    const updatedActions = [...selectedActions];
    const existingIndex = updatedActions.findIndex(action => action.id === item.id);
    if (existingIndex > -1) {
      updatedActions.splice(existingIndex, 1, item);
    } else {
      updatedActions.push(item);
    }
    setSelectedActions(updatedActions);
  };

  const removeRules = (id: string) => {
    setSelectedRules(selectedRules.filter(rule => rule.id !== id));
    updatePolicyRuleError();
  };

  const removeActions = (id: number) => {
    const existingAction = selectedActions.find(action => action.id === id);
    if (existingAction.emails?.length > 0) {
      existingAction.emails = [];
    }
    setSelectedActions(selectedActions.filter(action => action.id !== id));
  };

  const validatePolicy = () => {
    setPolicyFormErrors({ name: !policyName.length, rules: !selectedRules.length, validated: true });
  };

  const updatePolicyRuleError = () => {
    const { rules, validated } = policyFormErrors;
    const errorStatus = rules && validated ? !!selectedRules.length : validated ? !selectedRules.length : rules;
    setPolicyFormErrors({ rules: errorStatus, ...policyFormErrors });
  };

  useEffect(() => {
    // only runs after initial error state is displayed (user tries to save without name || rules)
    if (policyFormErrors.validated) {
      if (policyFormErrors.name || policyFormErrors.rules) {
        validatePolicy();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyName, selectedRules]);

  const handlePolicySubmit = async ({
    savedPolicyId = '',
    savedPolicyName,
    savedRules,
    savedActions,
    savedPolicyDescription,
    savedPolicyEnabled,
  }: {
    savedPolicyId?: string;
    savedPolicyName: string;
    savedRules: RuleEntity[];
    savedActions: PolicyAction[];
    savedPolicyDescription: string;
    savedPolicyEnabled: boolean;
  }) => {
    validatePolicy();

    if (savedPolicyName.length && savedRules.length) {
      let path = `${process.env.RULES_SERVICE_URL}/rule/policies/${mode === PolicyProfileMode.CREATE ? 'create' : 'update'}`;

      if (flags?.evalService) {
        path = path.replace(`${process.env.RULES_SERVICE_URL}/rule/`, `${process.env.EVAL_SERVICE_URL}/eval/`);
      }

      const body = {
        id: savedPolicyId,
        name: savedPolicyName,
        description: savedPolicyDescription,
        enabled: savedPolicyEnabled,
        remediations: savedActions.map((action: PolicyAction) => action.key),
        policyRules: savedRules.map((rule: RuleEntity) => ({ ruleId: rule.id })),
        notificationEmails: savedActions?.find(action => action.key === 'NOTIFICATION')?.emails || [],
      };

      if (!savedPolicyId.length) {
        delete body.id;
      }

      await sendHttpRequest({
        path,
        method: 'POST',
        body,
        successMessage: 'Successfully saved policy',
        errorMessage: 'Error saving policy',
        enqueueSnackbar,
      });

      navigate('/policies');
    }
  };

  const StatusCard = () => {
    return (
      <StyledStack>
        <Typography variant="h5Medium">Status Update</Typography>
        <StyledStatusCards>
          <PolicyStatusCard title="Devices" value={triggeredDevicesAndApps?.numDevicesTriggered || '-'} />
          <PolicyStatusCard title="Applications" value={triggeredDevicesAndApps?.numApplicationsTriggering || '-'} />
        </StyledStatusCards>
      </StyledStack>
    );
  };

  const handleEdit = (action: PolicyAction) => {
    setModalOptions({ visible: true, row: action, isEdit: true });
  };

  return (
    <StyledContainer>
      <StyledSplitPanel>
        <StyledBackSection>
          <KwButton variant="light" startIcon={<ArrowBack />} onClick={() => navigate('/policies')}>
            Back to Policies
          </KwButton>
        </StyledBackSection>
        <StyledActionSection>
          {mode === PolicyProfileMode.EDIT ? (
            <>
              <KwButton variant="basic" onClick={() => navigate(`/policies/view/${policyId}`)}>
                Cancel
              </KwButton>
              <KwButton
                variant="filled"
                onClick={() =>
                  handlePolicySubmit({
                    savedPolicyId: policyId,
                    savedPolicyName: policyName,
                    savedPolicyDescription: policyDescription,
                    savedPolicyEnabled: policyEnabled,
                    savedRules: selectedRules,
                    savedActions: selectedActions,
                  })
                }
              >
                Save
              </KwButton>
            </>
          ) : mode === PolicyProfileMode.CREATE ? (
            <>
              <KwButton variant="basic" onClick={() => navigate('/policies')}>
                Cancel
              </KwButton>
              <KwButton
                variant="filled"
                onClick={() =>
                  handlePolicySubmit({
                    savedPolicyName: policyName,
                    savedPolicyDescription: policyDescription,
                    savedPolicyEnabled: policyEnabled,
                    savedRules: selectedRules,
                    savedActions: selectedActions,
                  })
                }
              >
                Create
              </KwButton>
            </>
          ) : (
            <KwButton variant="filled" onClick={() => navigate(`/policies/edit/${policyId}`)}>
              Edit
            </KwButton>
          )}
        </StyledActionSection>

        {[PolicyProfileMode.EDIT, PolicyProfileMode.VIEW].includes(mode) && (
          <>
            {policyId ? (
              <StyledStatisticsSection>
                <PolicyStatisticsCard policyId={policyId} />
              </StyledStatisticsSection>
            ) : null}
          </>
        )}

        <StyledDetailsSection>
          <Typography variant="h4Medium">Policy Details</Typography>

          {[PolicyProfileMode.EDIT, PolicyProfileMode.VIEW].includes(mode) && (
            <Box display="flex" flexDirection="column">
              <Typography variant="bodyLight">Created On: {dayjs(policyData?.createdAt).format('MM/DD/YYYY')}</Typography>
              <Typography variant="bodyLight">Last Modified: {dayjs(policyData?.updatedAt).format('MM/DD/YYYY')}</Typography>
            </Box>
          )}
        </StyledDetailsSection>
        <StyledProfileSection>
          <PolicyCreationCard disabled={![PolicyProfileMode.EDIT, PolicyProfileMode.CREATE].includes(mode)} />
        </StyledProfileSection>
        {mode === PolicyProfileMode.CREATE ? (
          <StyledStatisticsSection>
            <StatusCard />
          </StyledStatisticsSection>
        ) : (
          <StyledStatusSection>
            <StatusCard />
          </StyledStatusSection>
        )}
        <StyledRuleSection>
          <StyledStack>
            <Typography variant="h5Medium">Rules</Typography>
            <PolicySelectedListCard
              selectedList={selectedRules}
              originalList={rulesData ? rulesData.results : []}
              title="rules"
              removeAction={removeRules}
              isHighlight={tab === PolicyTab.RULES}
              mode={mode}
            />
          </StyledStack>
        </StyledRuleSection>
        <StyledActionsSection>
          <StyledStack>
            <Typography variant="h5Medium">Actions (Optional)</Typography>
            <PolicySelectedListCard
              selectedList={selectedActions}
              originalList={actions}
              title="actions"
              removeAction={removeActions}
              isHighlight={tab === PolicyTab.ACTIONS}
              mode={mode}
              handleEdit={handleEdit}
            />
          </StyledStack>
        </StyledActionsSection>
        <StyledPoliciesSection>
          <PolicyListCard
            tab={tab}
            setTab={setTab}
            rules={rulesData ? rulesData.results : []}
            actions={actions}
            selectedRules={selectedRules}
            selectedActions={selectedActions}
            handleAddingRules={addRules}
            handleAddingActions={addActions}
            mode={mode}
            modalOptions={modalOptions}
            setModalOptions={setModalOptions}
          />
        </StyledPoliciesSection>
      </StyledSplitPanel>
    </StyledContainer>
  );
}

const StyledContainer = styled(KwContainer)`
  padding: 1% 3%;
` as typeof KwContainer;

const StyledStack = styled('div')({ display: 'flex', flexDirection: 'column', gap: '10px' });

const StyledSplitPanel = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto auto auto auto auto auto',
  gridTemplateAreas: `
  'back action'
  'details details'
  'profile statistics' 
  'status statistics'
  'rule policies'
  'actions policies'`,
  gap: '20px 44px',
});

const StyledBackSection = styled('div')({
  gridArea: 'back',
});
const StyledActionSection = styled('div')({
  gridArea: 'action',
  display: 'inline-flex',
  justifyContent: 'flex-end',
});

const StyledProfileSection = styled('div')({
  gridArea: 'profile',
});

const StyledStatusSection = styled('div')({
  gridArea: 'status',
});

const StyledRuleSection = styled('div')({
  gridArea: 'rule',
});

const StyledActionsSection = styled('div')({
  gridArea: 'actions',
});

const StyledPoliciesSection = styled('div')({
  gridArea: 'policies',
  top: 0,
  position: 'sticky',
  alignSelf: 'start',
});

const StyledStatisticsSection = styled('div')({
  gridArea: 'statistics',
  paddingBottom: '60px',
});

const StyledDetailsSection = styled('div')({
  gridArea: 'details',
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledStatusCards = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gap: '24px',
});
