import { ApplicationVersion } from '@kw/device-service/dist/application/entities/applicationVersion.entity';
import { PolicyDetails } from '@kw/service-definitions-eval';
import { createQueryString } from '../../../utils/createQueryString';
import { useFetchService } from '../../../utils/fetchService';
import { useStickyResult } from '../../../utils/useStickyResult';

export const useAppVersions = (endpoint, appParams, accessToken) => {
  const queryParams = createQueryString(appParams);

  const path = `${endpoint}?${queryParams}`;

  const { data: appData, error: appsDataError } = useFetchService<{
    apps: ApplicationVersion[];
    policies: PolicyDetails[];
    totalApps: number;
    totalDevices: number;
    totalManagedApps: number;
    totalDiscoveredApps: number;
  }>(path, accessToken);
  const { apps, policies, totalApps = 0, totalDevices, totalManagedApps, totalDiscoveredApps } = useStickyResult(appData);
  const isLoading = !appData && !appsDataError;
  return { apps, policies, totalApps, totalDevices, totalManagedApps, totalDiscoveredApps, appsDataError, isLoading };
};

export const useHighRiskCountries = (accessToken: string) => {
  const { data, error: highRiskCountriesError } = useFetchService<{ highRiskCountries: string[]; total: number }>(
    '/list/high-risk-countries',
    accessToken,
  );
  const { highRiskCountries, total: highRiskCountriesTotal } = useStickyResult(data);

  return { highRiskCountries, highRiskCountriesTotal, highRiskCountriesError };
};

export const useCategories = (accessToken: string) => {
  const { data, error: categoriesError } = useFetchService<{ categories: string[] }>('/application/categories', accessToken);
  const { categories } = useStickyResult(data);

  return { categories, categoriesError };
};

export const useCountriesContactedCount = (params, accessToken: string) => {
  const queryParams = createQueryString(params);
  const path = `/eval/applications/countries-contacted-count?${queryParams}`;
  const { data, error: countriesCountError } = useFetchService<{ [key: string]: number }>(path, accessToken);
  const countriesCount = useStickyResult(data);

  return { countriesCount, countriesCountError };
};

export const useTopHighLevelIssues = (params, accessToken: string) => {
  const queryParams = createQueryString(params);
  const path = `/eval/applications/top-high-level-issues?${queryParams}`;
  const { data, error: topHighLevelIssuesError } = useFetchService<{ [key: string]: number }[]>(path, accessToken);
  const topHighLevelIssues = useStickyResult(data);
  return { topHighLevelIssues, topHighLevelIssuesError };
};

export const useTopImportantPermissions = (params, accessToken: string) => {
  const queryParams = createQueryString(params);
  const path = `/eval/applications/top-important-permissions?${queryParams}`;
  const { data, error: topImportantPermissionsError } = useFetchService<{ [key: string]: number }[]>(path, accessToken);
  const topImportantPermissions = useStickyResult(data);
  return { topImportantPermissions, topImportantPermissionsError };
};
