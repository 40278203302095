export const dateRange = [
  {
    label: 'Last 7 days',
    value: 7,
  },
  {
    label: 'Last 14 days',
    value: 14,
  },
  {
    label: 'Last 30 days',
    value: 30,
  },
  {
    label: 'Last 60 days',
    value: 60,
  },
  {
    label: 'Last 90 days',
    value: 90,
  },
];

export const appMetadata = 'https://krwr.net/app_metadata';
