// eslint-disable-next-line import/no-extraneous-dependencies
import { NumDevicesDto } from '@kw/device-service/dist/device/dtos/numDevices.dto';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NumApplicationsDto } from '@kw/device-service/dist/application/dtos/numApplications.dto';
import { useFetchService } from './fetchService';
import { dateFormatMonthDay } from './dates';

/*
These are some device-service fetch commands that are used in more than once place, so I pulled them into here so as not to repeat ourselves
 */

export function useNumberDevicesRegistered(range: number, accessToken) {
  const { data, error } = useFetchService<NumDevicesDto[]>(`/device/number-devices-registered?range=${range}`, accessToken);
  return {
    numberDevicesRegistered: dateFormatMonthDay(data),
    error,
    isLoading: !data && !error,
  };
}

export function useNumberApplicationsRegistered(range: number, accessToken) {
  const { data, error } = useFetchService<NumApplicationsDto[]>(`/application/number-applications-registered?range=${range}`, accessToken);
  return { numberAppsRegistered: dateFormatMonthDay(data), error, isLoading: !data && !error };
}
