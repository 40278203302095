import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import Pie from '@visx/shape/lib/shapes/Pie';
import { securityColorMap } from '../../utils/securityColorMap';

export const Gauge = ({ score, ...props }) => {
  const theme = useTheme();

  const scoreData = [
    {
      value: score,
      fill: securityColorMap(theme, score),
    },
    {
      value: 10 - score,
      fill: '#979797',
    },
  ];

  const outerRadius = 35;
  const innerRadius = 27.8;
  const getValue = data => data.value;
  return (
    <svg width={70} height={70} {...props}>
      <Group top={35} left={35}>
        <Pie
          outerRadius={outerRadius}
          fill={({ data }) => data.fill}
          innerRadius={innerRadius}
          data={scoreData}
          cornerRadius={1.9}
          pieValue={getValue}
          startAngle={-(Math.PI / 2)}
          endAngle={Math.PI / 2}
          // d3 automatically sorts by value, disable sort by passing in null
          pieSort={null}
        />
        {/* this second pie is here to hide the corner radius of the first pie located in the middle of the arc
         * https://stackoverflow.com/a/40450270
         */}
        <Pie
          outerRadius={outerRadius}
          fill={({ data }) => data.fill}
          innerRadius={innerRadius}
          data={scoreData}
          pieValue={getValue}
          startAngle={-(Math.PI / 2.1)}
          endAngle={Math.PI / 2.1}
          // d3 automatically sorts by value, disable sort by passing in null
          pieSort={null}
        />
      </Group>
    </svg>
  );
};
