import { Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { securityColorMap } from '../utils/securityColorMap';
/**
 * @param param0.value score value, between 0 and 10
 */

export const KwScoreBadge = ({ value }: { value: number }) => {
  return <StyledSecurityRiskLevel label={value} />;
};

const fontColorMap = {
  1: 'white',
  2: 'white',
  3: 'black',
  4: 'black',
  5: 'black',
  6: 'black',
  7: 'white',
  8: 'white',
  9: 'white',
  10: 'white',
};

const StyledSecurityRiskLevel = styled(Chip)<{ label: number }>`
  ${props => props.theme.typography.smallBold as any}
  border-radius: 12px;
  width: 52px;
  height: 24px;
  background-color: ${props => securityColorMap(props.theme, props.label)};
  color: ${props => fontColorMap[props.label]};
` as typeof Chip;
