import { styled, Box, Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { FailedDeviceStatCard } from './FailedDeviceStatCard';
import { CountriesContactCard } from './CountriesContactCard';
import { TopTenPoliciesCard } from './TopTenPoliciesCard';
import { RegisteredCard } from './RegisteredCard';
import { TopTenRiskScoreCard } from './TopTenRiskScoreCard';
import { TopIorsCard } from './TopIorsCard';
import { dateRange } from '../shared/constants';

import { LaunchDarklyFlags } from '../../launch-darkly';
import { BackgroundProgressBar } from '../BackgroundProgressBar/BackgroundProgressBar';

export function Dashboard() {
  const [selectedDateRange, setSelectedDateRange] = useState(7);
  const [custom, setCustom] = useState(false);

  const flags: LaunchDarklyFlags['flags'] = useFlags();

  const handleDateRangeChange = event => {
    setCustom(false);
    setSelectedDateRange(event.target.value);
  };

  return (
    <KwContainer>
      <BackgroundProgressBar />
      <StyledPanel>
        <StyledHeaderSection>
          <Box display="flex" justifyContent="space-between">
            {flags.uiTest && <h3>Test Flag</h3>}
          </Box>
          <Select variant="standard" value={custom ? 'custom' : selectedDateRange} onChange={handleDateRangeChange} disableUnderline>
            {custom && <MenuItem value={'custom'}>Custom</MenuItem>}
            {dateRange.map(range => (
              <MenuItem key={range.value} value={range.value}>
                {range.label}
              </MenuItem>
            ))}
          </Select>
        </StyledHeaderSection>
        <StyledFailedDeviceStatSection>
          <FailedDeviceStatCard initialDateRange={selectedDateRange} />
        </StyledFailedDeviceStatSection>
        <StyledCountriesContactedSection>
          <CountriesContactCard initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledCountriesContactedSection>
        <StyledTopPolicySection>
          <TopTenPoliciesCard initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledTopPolicySection>
        <StyledDevicesRegisteredSection>
          <RegisteredCard instanceType="Devices" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledDevicesRegisteredSection>
        <StyledAppsRegisteredSection>
          <RegisteredCard instanceType="Apps" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledAppsRegisteredSection>
        <StyledPrivacyDevicesSection>
          <TopTenRiskScoreCard scoreType="Privacy" instanceType="Devices" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledPrivacyDevicesSection>
        <StyledSecurityDevicesSection>
          <TopTenRiskScoreCard scoreType="Security" instanceType="Devices" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledSecurityDevicesSection>
        <StyledAndroidIorsSection>
          <TopIorsCard platform="Android" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledAndroidIorsSection>
        <StyledIosIorsSection>
          <TopIorsCard platform="iOS" initialDateRange={selectedDateRange} setCustom={setCustom} />
        </StyledIosIorsSection>
      </StyledPanel>
    </KwContainer>
  );
}

const StyledPanel = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  gridTemplateRows: 'auto auto auto auto auto auto auto auto',
  gridTemplateAreas: `
  'header header header header header header'
  'failedDeviceStat failedDeviceStat countriesContacted countriesContacted countriesContacted countriesContacted'
  'topPolicy topPolicy countriesContacted countriesContacted countriesContacted countriesContacted'
  'devicesRegistered devicesRegistered devicesRegistered appsRegistered appsRegistered appsRegistered'
  'privacyDevices privacyDevices privacyDevices securityDevices securityDevices securityDevices'
  'androidIors androidIors androidIors iosIors iosIors iosIors'`,
  gap: '44px 16px',
});

const StyledHeaderSection = styled('div')({
  gridArea: 'header',
});

const StyledSubHeaderSection = styled('div')({
  gridArea: 'subHeader',
});

const StyledFailedDeviceStatSection = styled('div')({
  gridArea: 'failedDeviceStat',
});

const StyledTopPolicySection = styled('div')({
  gridArea: 'topPolicy',
});

const StyledCountriesContactedSection = styled('div')({
  gridArea: 'countriesContacted',
});

const StyledDevicesRegisteredSection = styled('div')({
  gridArea: 'devicesRegistered',
});

const StyledAppsRegisteredSection = styled('div')({
  gridArea: 'appsRegistered',
});

const StyledPrivacyDevicesSection = styled('div')({
  gridArea: 'privacyDevices',
});

const StyledSecurityDevicesSection = styled('div')({
  gridArea: 'securityDevices',
});

const StyledAndroidIorsSection = styled('div')({
  gridArea: 'androidIors',
});

const StyledIosIorsSection = styled('div')({
  gridArea: 'iosIors',
});
