import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { Card, CardProps, Divider } from '@mui/material';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet';
import { Legend } from 'recharts';
import dayjs from 'dayjs';
import { Apple, Android, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import DeniedIcon from '@mui/icons-material/GppBad';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { AppVersionsTable } from './AppVersionsTable';
import { Link } from '../../Link';
import { PageLoader } from '../../PageLoader';
import { fetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { countriesMap } from '../../utils/countriesMap';
import CountriesContacted from './CountriesContactedTable';
import AppIssuesTable from './AppIssuesTable';
import { useHighRiskCountries } from './hooks';
import { BackgroundProgressBar } from '../BackgroundProgressBar/BackgroundProgressBar';
import PermissionsRequestedTable from './PermissionsRequestedTable';
import AppDetailsPieChart, { AppDetailsPieChartProps, DataItem } from './AppDetailsPieChart';
import { MapScrollWheelZoomNotifier, scrollInstructions } from '../shared/MapScrollWheelZoomNotifier';
import '../shared/css/mapScrollWheelZoomNotifier.css';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { ApplicationsPopover } from './ApplicationsPopover';
import { useOrganization } from '../Settings/SettingsDropDownMenu';
import { EmptyNetworkTrafficCard } from './EmptyNetworkTrafficCard';
import { NavigationContext } from '../Navigation/NavigationProvider';
import PolicyViolationsCard from './PolicyViolationsCard';

export default function ApplicationDetails() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');

  const [iconUrl, setIconUrl] = useState<string>('');
  const [actionPopoverIsOpen, setActionPopoverIsOpen] = useState(false);
  const [actionButtonAnchorEl, setActionButtonAnchorEl] = useState(null);
  const [deniedApps, setDeniedApps] = useState([]);
  const [appDetails, setAppDetails] = useState<any>({});
  const [permissionsWithProtectionLevels, setPermissionsWithProtectionLevels] = useState<{ name: string; protectionLevel: string }[]>([]);
  const { orgData, organizationError, isLoading: isLoadingOrg } = useOrganization(accessToken);

  const location = useLocation();

  const { appId, appVersion, os } = Object.fromEntries(new URLSearchParams(location.search));
  const { setAppName } = useContext(NavigationContext);

  const { highRiskCountries, highRiskCountriesError } = useHighRiskCountries(accessToken);

  const handleActionButtonPopoverClick = (event: { currentTarget: HTMLButtonElement | HTMLAnchorElement } = null) => {
    setActionButtonAnchorEl(event.currentTarget);
    setActionPopoverIsOpen(true);
  };

  const handlePopoverClose = () => {
    setActionButtonAnchorEl(null);
    setActionPopoverIsOpen(false);
  };

  const fetchDeniedApps = async () => {
    try {
      const response = await fetchService('/list/denied-apps', accessToken);
      setDeniedApps(response.deniedApps);
    } catch (error) {
      console.error('Error fetching denied apps', error);
    }
  };

  useEffect(() => {
    fetchDeniedApps();
  }, [accessToken]);

  const fetchAppDetails = async () => {
    try {
      const response = await fetchService(`/eval/applications/os/${os}/application/${appId}/version/${appVersion}`, accessToken);
      setAppDetails(response);
      setAppName(response?.current?.appDetails?.appName);
      setIconUrl(`data:image/png;base64,${response?.current?.appDetails?.appIconBase64}`);
    } catch (error) {
      console.error('Error fetching app details:', error);
    }
  };

  useEffect(() => {
    fetchAppDetails();
  }, [accessToken, appId, appVersion, os]);

  const fetchPermissionDetails = async () => {
    try {
      const response = await fetchService(`/application/os/${os}/app/${appId}/version/${appVersion}/permissions`, accessToken);
      setPermissionsWithProtectionLevels(response);
    } catch (error) {
      console.error('Error fetching permission details:', error);
    }
  };

  useEffect(() => {
    fetchPermissionDetails();
  }, [accessToken, appId, appVersion, os]);

  if (!appDetails || !highRiskCountries || !permissionsWithProtectionLevels) {
    return <PageLoader />;
  }

  const policyViolations = appDetails?.current?.appAnalysis?.result?.orgPolicyResults?.filter(opr => opr.didTrigger);
  const hasPolicyViolations = policyViolations?.length > 0;
  const contactedCountryCodes = appDetails?.current?.appDetails?.countriesContacted;
  const importantFinding = appDetails?.current?.appAnalysis?.hasHighRiskIors;

  let groupedIndicators: {
    [title: string]: {
      isSecurityConcern: boolean;
      isPrivacyConcern: boolean;
      weight: number;
      description: string;
      adminTitle: string;
      adminDescription: string;
    };
  } = {};

  appDetails?.iors
    ?.filter(ior => {
      const found = appDetails?.current?.appDetails?.riskIndicatorIds?.find(riskIndicatorId => riskIndicatorId === ior.id);
      return !!found;
    })
    .forEach(indicator => {
      groupedIndicators[indicator.title] = {
        isSecurityConcern: groupedIndicators[indicator.title]?.isSecurityConcern || indicator.isSecurityConcern,
        isPrivacyConcern: groupedIndicators[indicator.title]?.isPrivacyConcern || indicator.isPrivacyConcern,
        weight: indicator.weight,
        description: indicator.description,
        adminTitle: indicator.adminTitle,
        adminDescription: indicator.adminDescription,
      };
    });

  groupedIndicators = Object.fromEntries(Object.entries(groupedIndicators).sort(([, a], [, b]) => b.weight - a.weight));

  // const deviceVersionsPieChartData = formatDeviceVersionsPieChart(apps, appVersion);
  const issuesPieChartData = formatIssuesPieChart(groupedIndicators);
  const permissionsPieChartData = formatPermissionsPieChart(permissionsWithProtectionLevels);

  const formatDate = (timestamp: string): string => {
    return dayjs(timestamp).format('MM/DD/YY');
  };

  const platformIcon = appDetails?.current?.appDetails.os === 'ios' ? <StyledAppleIcon /> : <StyledAndroidIcon />;

  const renderAppTag = (isDenied, policyViolation, importantFindings) => {
    if (isDenied) {
      return (
        <>
          <StyledDeniedIcon color="error" />
          <StyledWarningTagText>Denied App</StyledWarningTagText>
        </>
      );
    }
    if (policyViolation) {
      return (
        <>
          <StyledWarningIcon color="error" />
          <StyledWarningTagText>Policy Violation</StyledWarningTagText>
        </>
      );
    }
    if (importantFindings) {
      return (
        <>
          <StyledWarningIcon color="error" />
          <StyledWarningTagText>Important Findings</StyledWarningTagText>
        </>
      );
    }
    return null;
  };

  const HighRiskMarker = new Icon({
    iconUrl: '/icons/high-risk-country-map-marker.svg',
    iconSize: [30, 46],
  });

  const showDeniedApps = orgData?.settings?.includes('denied-apps');

  return (
    <StyledKwContainer>
      <BackgroundProgressBar />
      <StyledButtonContainer>
        <StyledBackButton href="/applications">
          <StyledArrowBack />
          <StyledBackText>Back to App List</StyledBackText>
        </StyledBackButton>
        <StyledKwButton endIcon={<KeyboardArrowDownIcon />} variant="filled" onClick={event => handleActionButtonPopoverClick(event)}>
          Actions
        </StyledKwButton>
      </StyledButtonContainer>
      <ApplicationsPopover
        deniedApps={deniedApps}
        applicationId={appDetails?.current?.appDetails?.appId}
        applicationVersion={appDetails?.current?.appDetails?.analyzedVersion}
        os={appDetails?.current?.appDetails?.os}
        name={appDetails?.current?.appDetails?.appName}
        isOpen={actionPopoverIsOpen}
        anchorEl={actionButtonAnchorEl}
        handleClose={handlePopoverClose}
        fetchDeniedApps={fetchDeniedApps}
        showDeniedApps={showDeniedApps}
      />
      <StyledContent>
        <StyledAppSummary>
          {iconUrl ? (
            <StyledIcon alt={`${appId} app icon`} src={iconUrl} />
          ) : (
            <StyledIconPlaceholder alt={`${appId} app icon`} src={'/icons/app-placeholder-60x60.svg'} />
          )}
          {importantFinding && <StyledRedDivider />}
          <StyledAppSummaryDetails>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledAppName hasWarningTag={hasPolicyViolations || importantFinding}>
                {appDetails?.current?.appDetails?.appName}
              </StyledAppName>
              {renderAppTag(appDetails?.current?.appAnalysis?.isDenied, hasPolicyViolations, importantFinding)}
            </div>
            <StyledAppId>{appDetails?.current?.appDetails?.appId}</StyledAppId>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledIconWrapper>{platformIcon}</StyledIconWrapper>
              <StyledAppId>{appDetails?.current?.appDetails?.analyzedVersion}</StyledAppId>
            </div>
            <StyledAppId>Analyzed on {formatDate(appDetails?.current?.appDetails?.analysisDate)}</StyledAppId>
          </StyledAppSummaryDetails>
        </StyledAppSummary>
        {hasPolicyViolations && <PolicyViolationsCard policyViolations={policyViolations} />}
        <div style={{ display: 'flex' }}>
          {/* <StyledCard>
            <StyledCardTitle>Devices on All Versions</StyledCardTitle>
            <AppDetailsPieChart data={deviceVersionsPieChartData} />
          </StyledCard> */}
          <StyledCard>
            <AppDetailsPieChart data={issuesPieChartData} title="Issues" emptyText="No issues detected" />
          </StyledCard>
          <StyledCard>
            <AppDetailsPieChart data={permissionsPieChartData} title="Permissions" emptyText="No permissions detected" />
          </StyledCard>
        </div>
        {!contactedCountryCodes || contactedCountryCodes?.length === 0 ? (
          <EmptyNetworkTrafficCard />
        ) : (
          <>
            <div style={{ display: 'flex', gap: '25px' }}>
              <StyledCard>
                <StyledCardTitle>Network Map</StyledCardTitle>
                <StyledMap
                  id="map"
                  center={[39.8397, 24.0297]}
                  zoom={2}
                  zoomControl={true}
                  maxZoom={6}
                  scrollWheelZoom={false}
                  whenReady={() => {
                    const mapElement = document.getElementById('map');
                    mapElement.setAttribute('data-scroll-instructions', scrollInstructions);
                  }}
                >
                  <MapScrollWheelZoomNotifier />
                  <TileLayer
                    url={`https://api.maptiler.com/maps/bright-v2/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
                    attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    tileSize={512}
                    zoomOffset={-1}
                  />
                  {contactedCountryCodes?.map((countryCode: string) => {
                    return highRiskCountries.includes(countryCode) ? (
                      <Marker
                        key={countryCode}
                        position={[countriesMap[countryCode].latitude, countriesMap[countryCode].longitude]}
                        icon={HighRiskMarker}
                      >
                        <Popup>{countriesMap[countryCode].countryName}</Popup>
                      </Marker>
                    ) : (
                      <Marker key={countryCode} position={[countriesMap[countryCode].latitude, countriesMap[countryCode].longitude]}>
                        <Popup>{countriesMap[countryCode].countryName}</Popup>
                      </Marker>
                    );
                  })}
                  <Legend />
                </StyledMap>
              </StyledCard>
              {/* eslint-disable-next-line react/forbid-component-props */}
              <StyledCard style={{ width: '60%', maxHeight: '670px', overflow: 'auto' }}>
                <StyledCardTitle>Countries Contacted</StyledCardTitle>
                <CountriesContacted contactedCountryCodes={contactedCountryCodes} highRiskCountries={highRiskCountries} />
              </StyledCard>
            </div>
          </>
        )}

        <div style={{ display: 'flex', gap: '25px' }}>
          {/* eslint-disable-next-line react/forbid-component-props */}
          <StyledCard style={{ maxHeight: '670px', overflow: 'auto' }}>
            <AppIssuesTable riskIndicators={groupedIndicators} />
          </StyledCard>
          {/* eslint-disable-next-line react/forbid-component-props */}
          <StyledCard style={{ width: '60%', maxHeight: '670px', overflow: 'auto' }}>
            <PermissionsRequestedTable permissionsRequested={permissionsWithProtectionLevels} />
          </StyledCard>
        </div>

        <div style={{ maxHeight: '670px', overflow: 'auto', marginRight: '10px' }}>
          <AppVersionsTable
            appName={appDetails?.current?.appDetails?.appName}
            os={appDetails?.current?.appDetails?.os}
            appVersions={appDetails?.otherVersions}
          />
        </div>
      </StyledContent>
    </StyledKwContainer>
  );
}

/** styles */

const StyledBackButton = styled(Link)`
  display: flex;
  height: 30px;
  padding-left: 18px;
  padding-right: 22px;
  column-gap: 11px;
  width: 177px;
  border-radius: 4px;
  margin-bottom: 7px;

  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.palette.greyOverride[800]};

  &:hover {
    background-color: ${props => props.theme.palette.greyOverride[200]};
  }
`;

const StyledBackText = styled('div')`
  font-size: 13px;
  line-height: 13px;
`;

const StyledArrowBack = styled(ArrowBackIcon)`
  width: 16px;
`;

const StyledKwContainer = styled(KwContainer)`
  padding: 8px 32px;
`;

const StyledContent = styled('div')`
  display: grid;
  grid-row-gap: 24px;
  margin-bottom: 35px;
`;

const StyledIcon = styled('img')`
  height: 150px;
  width: 150px;
  margin-right: 20px;
  border-radius: 20px;
`;

const StyledIconPlaceholder = styled('img')`
  height: 150px;
  width: 150px;
  margin-right: 20px;
  border-radius: 20px;
  background-color: #ccc;
`;

const StyledRedDivider = styled(Divider)`
  height: 152px;
  width: 8px;
  margin-right: 10px;
  background-color: red;
`;

const StyledAppSummary = styled('div')`
  display: flex;
  height: 100%;
`;

const StyledAppSummaryDetails = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledAppName = styled('div')<HTMLAttributes<HTMLDivElement> & { hasWarningTag?: boolean }>`
  color: ${props => (props.hasWarningTag ? '#df2e21' : '#000')};
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 33px;
  font-weight: 700;
  line-height: 49px;
  text-align: left;
`;

const StyledWarningTagText = styled('div')`
  color: #df2e21;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 800;
  line-height: 21px;
  text-align: left;
  margin-left: 5px;
`;

const StyledAppId = styled('div')`
  color: #757575;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
`;

const StyledMap = styled(MapContainer)({ height: '517px', width: '100%' });

type StyledCardProps = {
  width?: string;
} & CardProps;

const StyledCard = styled((props: StyledCardProps) => <Card {...props} />)<StyledCardProps>`
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  padding: 8px;
  margin-right: 10px;
  width: ${({ width }) => width || '100%'};
`;

const StyledCardTitle = styled('div')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: '500',
  color: '#000',
});

const StyledIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const StyledAppleIcon = styled(Apple)`
  font-size: 32px;
  color: #a2aaad;
`;

const StyledAndroidIcon = styled(Android)`
  font-size: 32px;
  color: #a4c639;
`;

const StyledWarningIcon = styled(WarningIcon)`
  margin: 0 0 0 10px;
`;

const StyledDeniedIcon = styled(DeniedIcon)`
  margin: 0 0 0 10px;
`;

const StyledKwButton = styled(KwButton)`
  margin-left: auto;
  min-width: 110px;
`;

const StyledButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const formatDeviceVersionsPieChart = (apps: any[], appVersion: string): AppDetailsPieChartProps['data'] => {
  if (!apps) {
    return [];
  }

  let thisVersionCount = 0;
  let otherVersionsCount = 0;

  // Calculate counts for current version and other versions
  apps.forEach(app => {
    if (app.applicationVersion === appVersion) {
      thisVersionCount += app.deviceCount;
    } else {
      otherVersionsCount += app.deviceCount;
    }
  });

  // Construct data array
  const data: DataItem[] = [
    {
      key: 'This version',
      value: thisVersionCount,
      color: '#FFA38B',
    },
    {
      key: 'Other versions',
      value: otherVersionsCount,
      color: '#B8DCD1',
    },
  ];

  return data;
};

export type PermissionResult = {
  properties?: {
    name?: string;
    '@android:name'?: string;
    permission?: string;
    protection_level?: string;
    '@android:protectionLevel'?: string;
    protectionLevel?: string;
  };
};

export type Permissions = {
  results: PermissionResult[];
};

const formatIssuesPieChart = (riskIndicators: {
  [title: string]: { isSecurityConcern: boolean; isPrivacyConcern: boolean; weight: number; description: string };
}): AppDetailsPieChartProps['data'] => {
  const countMap: Map<string, number> = new Map();

  // Count indicators for each category
  Object.entries(riskIndicators).forEach(([title, { isSecurityConcern, isPrivacyConcern, weight, description }]) => {
    const category = getCategory(weight);
    const count = countMap.get(category) || 0;
    countMap.set(category, count + 1);
  });

  // Convert map to array of DataItem objects
  const data: DataItem[] = Array.from(countMap.entries()).map(([category, count]) => ({
    key: category,
    value: count,
    color: getColor(category),
  }));

  data.sort((a, b) => {
    const categoryOrder = { High: 0, Medium: 1, Low: 2 };
    return categoryOrder[a.key] - categoryOrder[b.key];
  });

  return data;
};

const getCategory = (weight: number): string => {
  if (weight >= 75) {
    return 'High';
  }
  if (weight >= 35) {
    return 'Medium';
  }
  return 'Low';
};

const getColor = (category: string): string => {
  switch (category) {
    case 'High':
      return '#F44336';
    case 'Medium':
      return '#FF9800';
    case 'Low':
      return '#2196F3';
    default:
      return '#2196F3';
  }
};

const formatPermissionsPieChart = (permissions: { name: string; protectionLevel: string }[]): AppDetailsPieChartProps['data'] => {
  const countMap: Map<string, number> = new Map();

  // Count permissions for each protection level
  permissions.forEach(permission => {
    const { protectionLevel } = permission;
    const count = countMap.get(protectionLevel) || 0;
    countMap.set(protectionLevel, count + 1);
  });

  // Convert map to array of DataItem objects
  const data: DataItem[] = Array.from(countMap.entries()).map(([protectionLevel, count]) => ({
    key: protectionLevel === 'Important' ? 'Important' : 'Normal',
    value: count,
    color: protectionLevel === 'Important' ? '#FFA38B' : '#B8DCD1',
  }));

  return data;
};
