// column order helper functions
export function descendingComparator(a, b, orderBy) {
  // checks boolean column values - 'ASC' = false, 'DESC' = true
  if (typeof a[orderBy] === 'boolean' && typeof b[orderBy] === 'boolean') {
    return a[orderBy] ? -1 : !a[orderBy] ? 1 : 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'DESC' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
