import { Theme } from '@mui/material';

export const riskLevelMap = (theme: Theme) => ({
  1: theme.palette.state.success,
  2: theme.palette.state.success,
  3: theme.palette.state.success,
  4: theme.palette.state.info,
  5: theme.palette.state.info,
  6: theme.palette.state.warning,
  7: theme.palette.state.warning,
  8: theme.palette.state.danger,
  9: theme.palette.state.danger,
  10: theme.palette.state.danger,
});
