import { createContext, ReactNode, useContext, useState } from 'react';
import { ProgressEventProps, socket } from '../../utils/rt';

export const BackgroundProgressBarInfoHook = () => {
  const [isConnected, setIsConnected] = useState<boolean>(socket.connected);
  const [statusEvent, setStatusEvent] = useState<string>('');
  const [progressEvent, setProgressEvent] = useState<ProgressEventProps>({
    progressValue: 0,
    progressMax: 0,
    message: '',
    description: '',
    status: '',
  });

  return {
    isConnected,
    setIsConnected,
    statusEvent,
    setStatusEvent,
    progressEvent,
    setProgressEvent,
  };
};

export const BackgroundProgressBarContext = createContext(undefined);

export const BackgroundProgressBarProvider = ({ children }: { children: ReactNode }) => {
  const { isConnected, setIsConnected, statusEvent, setStatusEvent, progressEvent, setProgressEvent } = BackgroundProgressBarInfoHook();

  return (
    <BackgroundProgressBarContext.Provider
      value={{
        isConnected,
        setIsConnected,
        statusEvent,
        setStatusEvent,
        progressEvent,
        setProgressEvent,
      }}
    >
      {children}
    </BackgroundProgressBarContext.Provider>
  );
};

export const useBackgroundProgressBar = () => useContext(BackgroundProgressBarContext);
