import { Route, Routes } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { RiskIndicatorInfoHook, RiskIndicatorInfoContext } from './RiskIndicatorInfoContext';
import RiskIndicatorsTable from './RiskIndicatorsTable';
import RiskIndicatorProfilePage from './RiskIndicatorProfilePage';

export default function RiskIndicators() {
  const { activeRiskIndicator, setActiveRiskIndicator, activeOrganization, setActiveOrganization, mode, setMode } = RiskIndicatorInfoHook();

  return (
    <RiskIndicatorInfoContext.Provider
      value={{
        activeRiskIndicator,
        setActiveRiskIndicator,
        activeOrganization,
        setActiveOrganization,
        mode,
        setMode,
      }}
    >
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<RiskIndicatorsTable />} />
          <Route path="edit/:riskIndicatorId" element={<RiskIndicatorProfilePage />} />
        </Route>
      </Routes>
    </RiskIndicatorInfoContext.Provider>
  );
}
