import dayjs from 'dayjs';
/* 
  expects a parseable date string or empty string
  returns amount of time that has elapsed in seconds, minutes, hours and days
*/

// eslint-disable-next-line consistent-return
export function calculateElapsedTime(date: Date | '') {
  if (date !== '') {
    const today = dayjs();
    const dateToCompare = dayjs(date);
    const seconds = today.diff(dateToCompare, 'second');
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
    }
    if (minutes < 60) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    }
    if (hours <= 24) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    }
    if (hours > 24) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    }
  } else {
    return '';
  }
}
