import { Button, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { sendHttpRequest } from '../../utils/network.service';

interface ToolbarProps {
  numSelected: number;
  selected: string[];
  onClickSelectAll: (event: any) => void;
  setSelected: Dispatch<SetStateAction<string[]>>;
  mutate: () => void;
}
export default function PoliciesTableToolbar({ numSelected, selected, onClickSelectAll, setSelected, mutate }: ToolbarProps) {
  const { enqueueSnackbar } = useSnackbar();

  const resetInterface = () => {
    setSelected([]);
    mutate();
  };

  const handleClickDeleteAllPolicies = async () => {
    // send api request to delete all selected policies
    await sendHttpRequest({
      path: `${process.env.RULES_SERVICE_URL}/rule/policies/bulk`,
      method: 'DELETE',
      body: { policyIds: selected },
      successMessage: 'Successfully deleted policies',
      errorMessage: 'Error deleting policies',
      enqueueSnackbar,
    });

    resetInterface();
  };

  return (
    <>
      {numSelected > 0 ? (
        <StyledTableToolbar>
          <Typography>
            {numSelected > 1 ? <span>{numSelected} policies selected</span> : <span>{numSelected} policy selected</span>}
          </Typography>
          <StyledTableToolbarButtons>
            <StyledPoliciesTableToolbarButton variant="contained" startIcon={<DeleteOutlineIcon />} onClick={handleClickDeleteAllPolicies}>
              Delete
            </StyledPoliciesTableToolbarButton>
            <StyledPoliciesTableToolbarButton variant="contained" startIcon={<ClearIcon />} onClick={onClickSelectAll}>
              Deselect
            </StyledPoliciesTableToolbarButton>
          </StyledTableToolbarButtons>
        </StyledTableToolbar>
      ) : // in case we want to avoid toolbar pop down
      // <div style={{height: '56px', width: '100%', visibility: 'hidden'}}></div>
      null}
    </>
  );
}

// table toolbar
const StyledTableToolbar = styled(Toolbar)`
  background-color: ${props => props.theme.palette.accent.primary};
  display: flex;
  flex-direction: row;
  align-content: space-between;
  color: #ffffff;
`;

const StyledTableToolbarButtons = styled('div')`
  margin: auto 0 auto auto;
  button {
    border-radius: 0;
    &:nth-of-type(4) {
      border-left: 1px solid white;
    }
  }
`;

const StyledPoliciesTableToolbarButton = styled(Button)`
  background-color: inherit;
  box-shadow: none;
  text-transform: capitalize;
`;
