import { Checkbox, IconButton, InputBase } from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  CheckBoxOutlined as CheckBoxOutlinedIcon,
  Done as DoneIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { css, styled, ThemeProvider } from '@mui/material/styles';
import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { useDebounce } from 'use-debounce';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { sendHttpRequest } from '../../utils/network.service';
import { calculateElapsedTime } from '../../utils/calculateElapsedTime';
import { theme } from '../../theme';
// components
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { ResetButton } from '../shared/ResetButton';
import { PageLoader } from '../../PageLoader';
// pagination
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useFetchService } from '../../utils/fetchService';
import { useStickyResult } from '../../utils/useStickyResult';
import { createQueryString } from '../../utils/createQueryString';
import { useDeviceOwnerSortParams } from './useDeviceOwnersSortParams';
// eslint-disable-next-line import/extensions
import { DeviceOwnerColumnOrderSortKeyMap, DeviceOwnerQueryParamKeyMap, DeviceOwnerStatus } from './enum/DeviceOwnerKeyMaps.enum';
import { DeviceOwnersPopover } from './DeviceOwnersPopover';
import DeviceOwnersTableHead from './DeviceOwnersTableHead';
import DeviceOwnersTableToolbar from './DeviceOwnersTableToolbar';
import DeviceOwnersDateRangeSelect from './DeviceOwnersDateRangeSelect';
import { DeviceOwnerAppManagementModal } from './DeviceOwnerAppManagementModal';
import { DeviceOwnerUninstallAppModal } from './DeviceOwnerUninstallAppModal';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { DeviceOwnerInviteUnenrolledModal } from './DeviceOwnerInviteUnenrolledModal';

interface QueryParams {
  [key: string]: string;
}
interface IProps {
  ownerStatus: (typeof DeviceOwnerStatus)[keyof typeof DeviceOwnerStatus];
}

export const useDeviceOwners = (
  queryParams: QueryParams,
  debouncedSearchQuery: string,
  selectedEnrolledDateRange: string,
  selectedLastCheckInDateRange: string,
  selectedInvitedDateRange: string,
  selectedUnenrolledDateRange: string,
  accessToken: string,
  ownerStatus: string,
) => {
  const queryString = createQueryString(queryParams);
  /* 
    NOTE: additional query filter parameters require special syntax incompatible with URLSearchParams
    each filter in query must be separated by a space / ' ' (%20) using tuples separated by ':' (%3A)
  */
  let filterParamString = `&q=`;
  if (!selectedEnrolledDateRange) {
    if (ownerStatus === DeviceOwnerStatus.ENROLLED) {
      filterParamString += encodeURIComponent('inviteAcceptedAt:not:null unenrolledAt:is:null');
    }
    if (ownerStatus === DeviceOwnerStatus.PENDING) {
      // default pending device owners order to inviteSentAt DESC
      if (!queryParams.sort) {
        filterParamString = '&sort=-inviteSentAt&q=';
      }
      filterParamString += encodeURIComponent('inviteAcceptedAt:is:null unenrolledAt:is:null');
    }
    if (ownerStatus === DeviceOwnerStatus.UNENROLLED) {
      filterParamString += encodeURIComponent('unenrolledAt:not:null');
    }
  } else {
    filterParamString += encodeURIComponent(DeviceOwnerQueryParamKeyMap.inviteAcceptedAt + selectedEnrolledDateRange);
  }

  if (debouncedSearchQuery) {
    filterParamString += encodeURIComponent(` ${DeviceOwnerQueryParamKeyMap.nameOrEmail + debouncedSearchQuery}`);
  }

  if (selectedLastCheckInDateRange) {
    filterParamString += encodeURIComponent(` ${DeviceOwnerQueryParamKeyMap.lastCheckinAt + selectedLastCheckInDateRange}`);
  }

  if (selectedInvitedDateRange) {
    filterParamString += encodeURIComponent(` ${DeviceOwnerQueryParamKeyMap.inviteSentAt + selectedInvitedDateRange}`);
  }

  if (selectedUnenrolledDateRange) {
    filterParamString += encodeURIComponent(` ${DeviceOwnerQueryParamKeyMap.unenrolledAt + selectedUnenrolledDateRange}`);
  }

  const queryParamString = queryString + filterParamString;

  const {
    data: deviceOwnersData,
    error: deviceOwnersError,
    mutate,
  } = useFetchService(`/organizations/deviceowners/search?${queryParamString}`, accessToken);

  const isLoading = !deviceOwnersData && !deviceOwnersError;

  const { deviceOwners, page: totals } = useStickyResult(deviceOwnersData);

  return { deviceOwners, deviceOwnersError, totals, isLoading, mutate };
};

export default function DeviceOwnersTable({ ownerStatus }: IProps) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [selectedEnrolledDateRange, setSelectedEnrolledDateRange] = useState('');
  const [selectedLastCheckInDateRange, setSelectedLastCheckInDateRange] = useState('');
  const [selectedInvitedDateRange, setSelectedInvitedDateRange] = useState('');
  const [selectedUnenrolledDateRange, setSelectedUnenrolledDateRange] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [popoverId, setPopoverId] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [currentManagedAppsOwner, setCurrentManagedAppsOwner] = useState(null);
  const [currentManagedApp, setCurrentManagedApp] = useState(null);
  const [editInfo, setEditInfo] = useState({ id: null, firstName: null, lastName: null, index: null });
  const [showDeviceOwnerAppManagementModal, setShowDeviceOwnerAppManagementModal] = useState(false);
  const [showDeviceOwnerUninstallAppModal, setShowDeviceOwnerUninstallAppModal] = useState(false);
  const [showDeviceOwnerInviteUnenrolledModal, setShowDeviceOwnerInviteUnenrolledModal] = useState(false);
  const [selectedInviteUnenrolledModalDeviceOwners, setSelectedInviteUnenrolledModalDeviceOwners] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const { page, pageSize, handleChangePage, handleChangeRowsPerPage, setPage } = useKwPagination(10);

  // splits strings on one or more space(s) and limits the search query to the first two terms provided
  const sanitizedSearchQueryTerms = searchQuery.toLowerCase().trim().split(/\s+/, 2);
  const searchTerms = sanitizedSearchQueryTerms
    .map((str, i) => {
      if (sanitizedSearchQueryTerms.length === 2 && i === 0) {
        return `${str}++`;
      }
      return str;
    })
    .join('');

  const query = searchQuery.length > 0 ? searchTerms : '';

  const [debouncedSearchQuery] = useDebounce(query, 500);

  const {
    deviceOwnerFirstNameOrder,
    toggleDeviceOwnerFirstNameOrder,
    deviceOwnerLastNameOrder,
    toggleDeviceOwnerLastNameOrder,
    deviceOwnerEmailOrder,
    toggleDeviceOwnerEmailOrder,
    deviceOwnerDateEnrolledOrder,
    toggleDeviceOwnerDateEnrolledOrder,
    deviceOwnerLastDeviceCheckInOrder,
    toggleDeviceOwnerLastDeviceCheckInOrder,
    deviceOwnerNumberOfDevicesOrder,
    toggleDeviceOwnerNumberOfDevicesOrder,
    deviceOwnerDateInvitedOrder,
    toggleDeviceOwnerDateInvitedOrder,
    deviceOwnerDateUnenrolledOrder,
    toggleDeviceOwnerDateUnenrolledOrder,
    resetToggles,
  } = useDeviceOwnerSortParams();

  const findSortParameter = (key: string, direction: null | 'ASC' | 'DESC') => {
    return direction === 'ASC'
      ? DeviceOwnerColumnOrderSortKeyMap[key]
      : direction === 'DESC'
      ? `-${DeviceOwnerColumnOrderSortKeyMap[key]}`
      : null;
  };

  const sort = [];
  sort.push(
    findSortParameter('firstName', deviceOwnerFirstNameOrder),
    findSortParameter('lastName', deviceOwnerLastNameOrder),
    findSortParameter('email', deviceOwnerEmailOrder),
    findSortParameter('dateEnrolled', deviceOwnerDateEnrolledOrder),
    findSortParameter('lastDeviceCheckIn', deviceOwnerLastDeviceCheckInOrder),
    findSortParameter('numberOfDevices', deviceOwnerNumberOfDevicesOrder),
    findSortParameter('dateInvited', deviceOwnerDateInvitedOrder),
    findSortParameter('dateUnenrolled', deviceOwnerDateUnenrolledOrder),
  );

  const {
    deviceOwners,
    deviceOwnersError,
    totals = {},
    isLoading,
    mutate,
  } = useDeviceOwners(
    {
      page: String(page),
      size: String(pageSize),
      sort: sort.filter(order => order)[0],
    },
    debouncedSearchQuery,
    selectedEnrolledDateRange,
    selectedLastCheckInDateRange,
    selectedInvitedDateRange,
    selectedUnenrolledDateRange,
    accessToken,
    ownerStatus,
  );

  const { totalItems: total } = totals;
  const totalPageCount = Math.ceil(total / pageSize);

  const tableHeaders = {
    enrolled: [
      {
        title: 'First Name',
        onClick: toggleDeviceOwnerFirstNameOrder,
        sortValue: deviceOwnerFirstNameOrder,
      },
      {
        title: 'Last Name',
        onClick: toggleDeviceOwnerLastNameOrder,
        sortValue: deviceOwnerLastNameOrder,
      },
      {
        title: 'Email',
        onClick: toggleDeviceOwnerEmailOrder,
        sortValue: deviceOwnerEmailOrder,
      },
      {
        title: 'Date Enrolled',
        onClick: toggleDeviceOwnerDateEnrolledOrder,
        sortValue: deviceOwnerDateEnrolledOrder,
      },
      {
        title: 'Last Device Check-In',
        onClick: toggleDeviceOwnerLastDeviceCheckInOrder,
        sortValue: deviceOwnerLastDeviceCheckInOrder,
      },
      {
        title: '# of Devices',
        onClick: toggleDeviceOwnerNumberOfDevicesOrder,
        sortValue: deviceOwnerNumberOfDevicesOrder,
      },
      {
        // edit enrolled device owner column
        title: '',
        onClick: () => {},
        sortValue: null,
      },
    ],
    pending: [
      {
        title: 'First Name',
        onClick: toggleDeviceOwnerFirstNameOrder,
        sortValue: deviceOwnerFirstNameOrder,
      },
      {
        title: 'Last Name',
        onClick: toggleDeviceOwnerLastNameOrder,
        sortValue: deviceOwnerLastNameOrder,
      },
      {
        title: 'Email',
        onClick: toggleDeviceOwnerEmailOrder,
        sortValue: deviceOwnerEmailOrder,
      },
      {
        title: 'Date Invited',
        onClick: toggleDeviceOwnerDateInvitedOrder,
        sortValue: deviceOwnerDateInvitedOrder,
      },
      {
        // edit pending device owner column
        title: '',
        onClick: () => {},
        sortValue: null,
      },
    ],
    unenrolled: [
      {
        title: 'First Name',
        onClick: toggleDeviceOwnerFirstNameOrder,
        sortValue: deviceOwnerFirstNameOrder,
      },
      {
        title: 'Last Name',
        onClick: toggleDeviceOwnerLastNameOrder,
        sortValue: deviceOwnerLastNameOrder,
      },
      {
        title: 'Email',
        onClick: toggleDeviceOwnerEmailOrder,
        sortValue: deviceOwnerEmailOrder,
      },
      {
        title: 'Date Unenrolled',
        onClick: toggleDeviceOwnerDateUnenrolledOrder,
        sortValue: deviceOwnerDateUnenrolledOrder,
      },
      {
        // edit unrolled device owner column (invite)
        title: '',
        onClick: () => {},
        sortValue: null,
      },
    ],
  };

  const selectDropDownInfo = {
    enrolled: [
      {
        title: 'Date Enrolled',
        label: 'date-enrolled',
        stateValue: selectedEnrolledDateRange,
      },
      {
        title: 'Last Device Check-In',
        label: 'last-device-check-in',
        stateValue: selectedLastCheckInDateRange,
      },
    ],
    pending: [
      {
        title: 'Date Invited',
        label: 'date-invited',
        stateValue: selectedInvitedDateRange,
      },
    ],
    unenrolled: [
      {
        title: 'Date Unenrolled',
        label: 'date-unenrolled',
        stateValue: selectedUnenrolledDateRange,
      },
    ],
  };

  const selectDropDownCategory =
    ownerStatus === DeviceOwnerStatus.ENROLLED
      ? selectDropDownInfo.enrolled
      : ownerStatus === DeviceOwnerStatus.PENDING
      ? selectDropDownInfo.pending
      : selectDropDownInfo.unenrolled;

  const handleDateRangeChange = (event: ChangeEvent<HTMLInputElement>, category: string) => {
    if (ownerStatus === DeviceOwnerStatus.ENROLLED && category === 'date-enrolled') {
      setSelectedEnrolledDateRange(event.target.value);
    } else if (ownerStatus === DeviceOwnerStatus.ENROLLED && category === 'last-device-check-in') {
      setSelectedLastCheckInDateRange(event.target.value);
    } else if (ownerStatus === DeviceOwnerStatus.PENDING && category === 'date-invited') {
      setSelectedInvitedDateRange(event.target.value);
    } else if (ownerStatus === DeviceOwnerStatus.UNENROLLED && category === 'date-unenrolled') {
      setSelectedUnenrolledDateRange(event.target.value);
    }
  };

  const resetFilterAndSorts = () => {
    resetToggles();
    setPage(0);
    setSearchQuery('');
    setSelectedEnrolledDateRange('');
    setSelectedLastCheckInDateRange('');
    setSelectedInvitedDateRange('');
    setSelectedUnenrolledDateRange('');
  };

  // popover - pending tab - more button
  const handlePopoverClick = (event: MouseEvent<HTMLButtonElement>, deviceOwnerId: string) => {
    setPopoverId(deviceOwnerId);
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
    setPopoverAnchorEl(null);
  };

  // checkboxes
  const isSelected = (id: string) => {
    const index = selected.findIndex(owner => owner.id === id);
    return index !== -1;
  };

  const selectDeviceOwner = (id: string, email: string) => {
    const selectedIndex = selected.findIndex(owner => owner.id === id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { id, email });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const selectAllDeviceOwners = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedPolicies = deviceOwners.map(n => ({ id: n.id, email: n.email }));
      setSelected(newSelectedPolicies);
      return;
    }
    setSelected([]);
  };

  // editable cells
  const editRow = (id: string) => {
    deviceOwners.forEach((owner, index) => {
      if (owner.id === id) {
        setEditInfo({ ...editInfo, id, index });
      }
    });
  };

  const updateDeviceOwner = async () => {
    const { id, firstName, lastName } = editInfo;

    if (!firstName && !lastName) {
      closeEditableRow();
      return;
    }

    await sendHttpRequest({
      path: `${process.env.ORGANIZATION_SERVICE_URL}/organizations/deviceowners/${id}`,
      method: 'PUT',
      body: { firstName, lastName },
      successMessage: 'Successfully updated device owner',
      errorMessage: 'Error updating device owner',
      enqueueSnackbar,
    });

    closeEditableRow();
    mutate();
  };

  const closeEditableRow = () => {
    setEditInfo({ id: null, firstName: null, lastName: null, index: null });
  };

  // workaround to focus first name input when editing - autoFocus prop does not work
  // https://github.com/mui/material-ui/issues/1594
  // eslint-disable-next-line consistent-return
  const firstNameInput = useCallback(inputElement => {
    if (inputElement) {
      const timeout = setTimeout(() => {
        inputElement.focus();
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  if (deviceOwnersError) {
    console.error(deviceOwnersError);
    return <div>failed to load</div>;
  }

  if (!deviceOwners) return <PageLoader />;

  return (
    <>
      {isLoading && <PageLoader />}
      <DeviceOwnerAppManagementModal
        isOpen={showDeviceOwnerAppManagementModal}
        onClose={() => setShowDeviceOwnerAppManagementModal(false)}
        currentManagedAppsOwner={currentManagedAppsOwner}
        setShowDeviceOwnerUninstallModal={setShowDeviceOwnerUninstallAppModal}
        setCurrentManagedApp={setCurrentManagedApp}
      />
      <DeviceOwnerUninstallAppModal
        isOpen={showDeviceOwnerUninstallAppModal}
        onClose={() => setShowDeviceOwnerUninstallAppModal(false)}
        currentManagedAppsOwner={currentManagedAppsOwner}
        currentManagedApp={currentManagedApp}
      />
      <DeviceOwnerInviteUnenrolledModal
        isOpen={showDeviceOwnerInviteUnenrolledModal}
        onClose={() => setShowDeviceOwnerInviteUnenrolledModal(false)}
        selected={selectedInviteUnenrolledModalDeviceOwners}
        setSelected={setSelectedInviteUnenrolledModalDeviceOwners}
        mutate={mutate}
      />
      <ThemeProvider theme={theme}>
        <StyledFilterWrapper>
          {selectDropDownCategory.map(({ title, label, stateValue }) => {
            return (
              <DeviceOwnersDateRangeSelect
                key={`${label}-date-range-select-drop-down`}
                title={title}
                label={label}
                stateValue={stateValue}
                handleDateRangeChange={handleDateRangeChange}
              />
            );
          })}
          <ResetButton
            onClick={() => {
              resetFilterAndSorts();
            }}
          />
        </StyledFilterWrapper>
        <StyledTableWrapper>
          <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder="Search device owners" />
          <KwTablePaginationWrapper>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50, 100]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
          </KwTablePaginationWrapper>
          <DeviceOwnersTableToolbar
            numSelected={selected.length}
            selected={selected}
            onClickSelectAll={selectAllDeviceOwners}
            setSelected={setSelected}
            closeEditableRow={closeEditableRow}
            mutate={mutate}
            ownerStatus={ownerStatus}
          />
          <KwTable aria-label="Device-Owners-Table">
            <DeviceOwnersTableHead
              ownerStatus={ownerStatus}
              onSelectAllClick={selectAllDeviceOwners}
              numSelected={selected.length}
              rowCount={deviceOwners.length}
              tableHeaders={tableHeaders}
              setPage={setPage}
            />
            <KwTableBody>
              {deviceOwners.map((owner, index) => {
                const isOwnerSelected = isSelected(owner.id);
                const labelId = `device-owners-table-checkbox-${index}`;
                return (
                  <StyledTableRow
                    role="row"
                    key={owner.id}
                    selected={isOwnerSelected}
                    aria-label={`${owner.email} row`}
                    aria-checked={isOwnerSelected}
                  >
                    {ownerStatus !== DeviceOwnerStatus.ENROLLED && (
                      <KwTableCell
                        onClick={() => selectDeviceOwner(owner.id, owner.email)}
                        tabIndex={-1}
                        key={owner.id}
                        selected={isOwnerSelected}
                      >
                        <Checkbox
                          checked={isOwnerSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                            'aria-label': `${owner.email} checkbox`,
                            'aria-checked': isOwnerSelected,
                          }}
                          checkedIcon={<CheckBoxOutlinedIcon />}
                        />
                      </KwTableCell>
                    )}
                    {/* shared columns */}
                    {editInfo.index === index && editInfo.id === owner.id ? (
                      <>
                        <KwTableCell>
                          <StyledInput
                            defaultValue={owner.firstName}
                            placeholder="First Name"
                            onChange={e => setEditInfo({ ...editInfo, firstName: e.currentTarget.value })}
                            inputRef={firstNameInput}
                          />
                        </KwTableCell>
                        <KwTableCell>
                          <StyledInput
                            defaultValue={owner.lastName}
                            placeholder="Last Name"
                            onChange={e => setEditInfo({ ...editInfo, lastName: e.currentTarget.value })}
                          />
                          <IconButton onClick={updateDeviceOwner}>
                            <StyledDoneIcon />
                          </IconButton>
                          <IconButton onClick={closeEditableRow}>
                            <StyledCloseIcon />
                          </IconButton>
                        </KwTableCell>
                      </>
                    ) : (
                      <>
                        <KwTableCell>{owner.firstName}</KwTableCell>
                        <KwTableCell>{owner.lastName}</KwTableCell>
                      </>
                    )}
                    <KwTableCell>{owner.email}</KwTableCell>
                    {ownerStatus === DeviceOwnerStatus.ENROLLED ? (
                      <>
                        <KwTableCell>
                          {owner.inviteAcceptedAt.length > 0 ? dayjs(owner.inviteAcceptedAt).format('M/DD/YYYY') : ''}
                        </KwTableCell>
                        <KwTableCell>{calculateElapsedTime(owner.lastCheckinAt)}</KwTableCell>
                        <KwTableCell>{owner.deviceCount}</KwTableCell>
                      </>
                    ) : ownerStatus === DeviceOwnerStatus.PENDING ? (
                      <>
                        <KwTableCell>{calculateElapsedTime(owner.inviteSentAt)}</KwTableCell>
                      </>
                    ) : (
                      <>
                        <KwTableCell>{calculateElapsedTime(owner.unenrolledAt)}</KwTableCell>
                      </>
                    )}
                    <StyledKwTableCell>
                      {ownerStatus !== DeviceOwnerStatus.UNENROLLED ? (
                        <IconButton onClick={e => handlePopoverClick(e, owner.id)}>
                          <StyledMoreVertIcon />
                        </IconButton>
                      ) : (
                        <StyledInviteButton
                          variant="filled"
                          onClick={() => {
                            setShowDeviceOwnerInviteUnenrolledModal(true);
                            setSelectedInviteUnenrolledModalDeviceOwners([owner]);
                          }}
                        >
                          Invite
                        </StyledInviteButton>
                      )}
                    </StyledKwTableCell>
                    <DeviceOwnersPopover
                      ownerStatus={ownerStatus}
                      deviceOwner={owner}
                      deviceOwnerId={popoverId}
                      anchorEl={popoverAnchorEl}
                      handleClose={handlePopoverClose}
                      mutate={mutate}
                      selected={selected}
                      setSelected={setSelected}
                      editRow={() => editRow(owner.id)}
                      setShowDeviceOwnerAppManagementModal={setShowDeviceOwnerAppManagementModal}
                      setCurrentManagedAppsOwner={setCurrentManagedAppsOwner}
                    />
                  </StyledTableRow>
                );
              })}
            </KwTableBody>
          </KwTable>
          {/* pagination */}
          {total > 10 ? (
            <KwTablePaginationWrapper>
              <KwRowsPerPage
                rowsPerPageOptions={[10, 20, 50, 100]}
                pageSize={pageSize}
                page={page}
                totalNumberOfEntries={total}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
            </KwTablePaginationWrapper>
          ) : null}
        </StyledTableWrapper>
      </ThemeProvider>
    </>
  );
}

const StyledFilterWrapper = styled('div')`
  display: flex;
  column-gap: 16px;
  margin-bottom: 30px;
  margin-top: 30px;
  align-items: center;
`;

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledTableWrapper = styled('div')`
  width: 100%;
`;

const StyledTableRow = styled(KwTableRow)<{ selected?: boolean }>`
  ${KwTableBody} & {
    background-color: ${({ selected }) => (selected ? '#EEEEEE' : 'transparent')};
  }
`;

const iconStyles = props => css`
  color: ${props.theme.palette.accent.primary};
`;

const StyledMoreVertIcon = styled(MoreVertIcon)`
  ${iconStyles}
`;

const StyledDoneIcon = styled(DoneIcon)`
  ${iconStyles}
`;

const StyledCloseIcon = styled(CloseIcon)`
  ${iconStyles}
`;

const StyledKwTableCell = styled(KwTableCell)<{ selected?: boolean }>`
  text-align: right;
`;

const StyledInput = styled(InputBase)`
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 0 5px;
  margin-right: 5px;
  &.Mui-focused {
    border: 1px solid ${props => props.theme.palette.accent.primary};
  }
`;

const StyledInviteButton = styled(KwButton)`
  height: 32px;
  box-shadow: none;
  margin-left: auto;
  float: right;
  &:focus {
    background-color: ${props => props.theme.palette.accent.primary};
  }
`;
