/*
  expects a list of data, each entry of which has a "day" field which is a parseable date.
  Converts day to a field more appropriate for display on a graph, etc
 */
export function dateFormatMonthDay(data: Array<{ day: Date } & { [id: string]: unknown }>) {
  if (!data) return data;

  return data.map(d => ({
    ...d,
    day: new Date(d.day).toLocaleString('default', { month: 'short', day: 'numeric' }),
  }));
}
