import { useMap } from 'react-leaflet';
import UAParser from 'ua-parser-js';

const parser = new UAParser();
const {
  os: { name: deviceOs },
} = parser.getResult();

const metaKey = deviceOs === 'Mac OS' ? '⌘' : 'ctrl';
export const scrollInstructions = `Use ${metaKey} + scroll to zoom the map`;

/* eslint-disable func-names */
export const MapScrollWheelZoomNotifier = () => {
  const map = useMap();
  const mapElement = document.getElementById('map');
  let messageTimer: NodeJS.Timeout;
  let scrollWheelTimer: NodeJS.Timeout;

  mapElement.addEventListener('wheel', function (event: WheelEvent) {
    clearTimeout(messageTimer);
    clearTimeout(scrollWheelTimer);
    event.stopPropagation();
    if (event.metaKey === true || event.ctrlKey === true) {
      event.preventDefault();
      map.scrollWheelZoom.enable();
      mapElement.classList.remove('map-scroll');

      scrollWheelTimer = setTimeout(function () {
        map.scrollWheelZoom.disable();
      }, 800);
    } else {
      map.scrollWheelZoom.disable();
      mapElement.classList.add('map-scroll');

      messageTimer = setTimeout(function () {
        mapElement.classList.remove('map-scroll');
      }, 800);
    }
  });

  const leafControlZoom = document.querySelector('.leaflet-control-zoom').children;
  for (const node of leafControlZoom) {
    node.addEventListener('click', function () {
      mapElement.classList.remove('map-scroll');
    });
  }

  window.addEventListener('wheel', function () {
    mapElement.classList.remove('map-scroll');
  });

  return null;
};
