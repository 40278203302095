import { useSnackbar } from 'notistack';
import { Button, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import { sendHttpRequest } from '../../utils/network.service';
import { KwConfirmationModal } from '../../kw-ui-components/KwConfirmationModal';
import { getUnenrollDeviceExtraDetails } from './getUnenrollDeviceExtraDetails';

interface ToolbarProps {
  numSelected: number;
  selectedDevices: { id: number; name: string }[];
  onClickSelectAll: (event: any) => void;
  afterSuccessFunc: () => void;
}
export function DevicesTableToolbar({ numSelected, selectedDevices, onClickSelectAll, afterSuccessFunc }: ToolbarProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleClickUnenrollAllDevices = async () => {
    // send api request to unenroll all selected Devices
    await sendHttpRequest({
      path: `${process.env.DEVICE_SERVICE_URL}/device/bulk/unenroll`,
      method: 'PUT',
      body: { deviceIds: selectedDevices.map(device => device.id) },
      successMessage: 'Successfully unenrolled device(s)',
      errorMessage: 'Error unenrolling device(s)',
      enqueueSnackbar,
    });

    afterSuccessFunc();
  };

  const firstThreeDevices = selectedDevices.slice(0, 3);

  const numberOfRemainingDevices = numSelected - firstThreeDevices.length;

  const extraDetails = getUnenrollDeviceExtraDetails();

  return (
    <>
      {numSelected > 0 ? (
        <StyledTableToolbar>
          <KwConfirmationModal
            title={'Unenroll Devices'}
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            onConfirm={() => handleClickUnenrollAllDevices()}
            description={`unenroll devices ${firstThreeDevices.map(device => device.name).join(', ')} ${
              numberOfRemainingDevices > 0
                ? `and ${numberOfRemainingDevices}
             other device(s)`
                : ''
            } from the organization`}
            extraDetails={extraDetails}
          />
          <Typography>
            {numSelected > 1 ? <span>{numSelected} devices selected</span> : <span>{numSelected} device selected</span>}
          </Typography>
          <StyledTableToolbarButtons>
            <StyledDevicesTableToolbarButton
              variant="contained"
              startIcon={<DeleteOutlineIcon />}
              onClick={() => setShowConfirmationModal(!showConfirmationModal)}
            >
              Remove
            </StyledDevicesTableToolbarButton>
            <StyledDevicesTableToolbarButton variant="contained" startIcon={<ClearIcon />} onClick={onClickSelectAll}>
              Deselect
            </StyledDevicesTableToolbarButton>
          </StyledTableToolbarButtons>
        </StyledTableToolbar>
      ) : // in case we want to avoid toolbar pop down
      // <div style={{height: '56px', width: '100%', visibility: 'hidden'}}></div>
      null}
    </>
  );
}

// table toolbar
const StyledTableToolbar = styled(Toolbar)`
  background-color: ${props => props.theme.palette.accent.primary};
  display: flex;
  flex-direction: row;
  align-content: space-between;
  color: #ffffff;
`;

const StyledTableToolbarButtons = styled('div')`
  margin: auto 0 auto auto;
  button {
    border-radius: 0;
    &:nth-of-type(2) {
      border-left: 1px solid white;
    }
  }
`;

const StyledDevicesTableToolbarButton = styled(Button)`
  background-color: inherit;
  box-shadow: none;
  text-transform: capitalize;
`;
