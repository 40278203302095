import { Auth0ContextInterface } from '@auth0/auth0-react';

export function verifyAuthExpiration(jwtPayload: any, auth0: Auth0ContextInterface) {
  const { exp } = jwtPayload;
  const expirationTime = (exp || 0) * 1000;
  if (Date.now() >= expirationTime) {
    console.log('Expired token. Logging out.');
    window.localStorage.setItem('accessToken', '');
    auth0.logout({ returnTo: window.location.origin });
  }
}
