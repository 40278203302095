import React from 'react';
import './css/activityindicator.css';

interface IProps {
  active?: boolean;
  message?: string;
  subtitle?: string;
}

export function ActivityIndicator(props: IProps) {
  return props.active && <>
    <div className="activity-indicator-container">
      <div className='activity-indicator'></div>
      <div className="activity-indicator-text">{props.message}</div>
      <div className="activity-indicator-text-subtitle">{props.subtitle}</div>
    </div>
  </>
}