import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { httpRequest } from '../../utils/network.service';
import { KwConfirmationModal } from '../../kw-ui-components/KwConfirmationModal';
import { getUnenrollDeviceExtraDetails } from './getUnenrollDeviceExtraDetails';

export const UnenrollDeviceConfirmationModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  selectedDevice,
  afterSuccessFunc,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUnenrollDevice = async ({ device }: { device: { id: string; name: string } }) => {
    const { error } = await httpRequest({
      path: `${process.env.DEVICE_SERVICE_URL}/device/unenroll/${device.id}`,
      method: 'PUT',
    });
    if (error) {
      enqueueSnackbar(`Error unenrolling device ${device.name}`, {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(`Successfully unenrolled device ${device.name}`, {
        variant: 'success',
      });
      afterSuccessFunc();
    }
  };

  const extraDetails = getUnenrollDeviceExtraDetails();

  return (
    <KwConfirmationModal
      title={'Unenroll Device'}
      isOpen={showConfirmationModal}
      onClose={() => setShowConfirmationModal(false)}
      onConfirm={() => handleUnenrollDevice({ device: selectedDevice })}
      description={`unenroll device ${selectedDevice.name} from the organization`}
      extraDetails={extraDetails}
    />
  );
};

interface IProps {
  showConfirmationModal: boolean;
  setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
  selectedDevice: { id: string; name: string };
  afterSuccessFunc: () => void;
}
