import { GetDeviceStatePolicyStatusResponse } from '@kw/service-definitions-rule';

export function getTriggeredPoliciesListString(response: GetDeviceStatePolicyStatusResponse) {
  let triggeredPoliciesListString = '';
  let triggeredPolicies = [];
  if (response) {
    const result: GetDeviceStatePolicyStatusResponse[] = Object.entries(response.status) as unknown as GetDeviceStatePolicyStatusResponse[];
    triggeredPolicies = result.filter(a => a[1].didTrigger === true);
    // console.log(`Triggered policies ${triggeredPolicies}`);

    if (triggeredPolicies.length > 0) {
      triggeredPoliciesListString = '\nThis device has failed ';
    }

    triggeredPoliciesListString += triggeredPolicies.reduce((ret, val, idx) => {
      if (idx === triggeredPolicies.length - 2) {
        ret += `<a href='/#/policies/view/${val[0]}'>policy [${val[1].name}]</a> and `;
      } else if (idx === triggeredPolicies.length - 1) {
        ret += `<a href='/#/policies/view/${val[0]}'>policy [${val[1].name}]</a>.`;
      } else {
        ret += `<a href='/#/policies/view/${val[0]}'>policy [${val[1].name}]</a>, `;
      }
      return ret;
    }, '');
  }
  return triggeredPoliciesListString;
}
