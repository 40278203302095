// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Cluster*/
.appsCustomMarker {
  background-position: center;
  background-color: rgba(16, 89, 191, 0.38);
  border-radius: 50%;
}

.appsCustomMarker div {
  margin-top: 30%;
  text-align: center;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.appsCustomMarker span {
  text-align: center;
}

.devicesCustomMarker {
  background-position: center;
  background-color: rgba(255, 204, 9, 0.38);
  border-radius: 50%;
}

.devicesCustomMarker div {
  margin-top: 30%;
  text-align: center;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.devicesCustomMarker span {
  text-align: center;
}

/*Legend*/
.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/css/customizedMap.css"],"names":[],"mappings":"AAAA,UAAU;AACV;EACE,2BAA2B;EAC3B,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,yDAAyD;EACzD,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,yDAAyD;EACzD,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA,SAAS;AACT;EACE,gBAAgB;EAChB,4CAA4C;EAC5C,iBAAiB;EACjB,oCAAoC;EACpC,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB","sourcesContent":["/*Cluster*/\n.appsCustomMarker {\n  background-position: center;\n  background-color: rgba(16, 89, 191, 0.38);\n  border-radius: 50%;\n}\n\n.appsCustomMarker div {\n  margin-top: 30%;\n  text-align: center;\n  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;\n  font-weight: bold;\n}\n\n.appsCustomMarker span {\n  text-align: center;\n}\n\n.devicesCustomMarker {\n  background-position: center;\n  background-color: rgba(255, 204, 9, 0.38);\n  border-radius: 50%;\n}\n\n.devicesCustomMarker div {\n  margin-top: 30%;\n  text-align: center;\n  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;\n  font-weight: bold;\n}\n\n.devicesCustomMarker span {\n  text-align: center;\n}\n\n/*Legend*/\n.info {\n  padding: 6px 8px;\n  font: 14px/16px Arial, Helvetica, sans-serif;\n  background: white;\n  background: rgba(255, 255, 255, 0.8);\n  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);\n  border-radius: 5px;\n}\n\n.legend {\n  text-align: left;\n  line-height: 18px;\n  color: #555;\n}\n\n.legend i {\n  width: 18px;\n  height: 18px;\n  float: left;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
