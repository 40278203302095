import { createContext, useState } from 'react';
// eslint-disable-next-line import/extensions
import { AppManagementStatus } from './enum/AppManagementKeyMaps.enum';

export const ManagedAppsInfoHook = () => {
  const [platform, setPlatform] = useState(null);
  const [mode, setMode] = useState(null);
  const [app, setApp] = useState({});
  const [searchHistory, setSearchHistory] = useState('');
  const [appManagementStatus, setAppManagementStatus] = useState<(typeof AppManagementStatus)[keyof typeof AppManagementStatus]>(
    AppManagementStatus.MANAGED,
  );

  return {
    platform,
    setPlatform,
    mode,
    setMode,
    app,
    setApp,
    searchHistory,
    setSearchHistory,
    appManagementStatus,
    setAppManagementStatus,
  };
};

export const ManagedAppsInfoContext = createContext(undefined);
// issue reloading /add page...
