import { Button, ButtonProps } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';

interface IProps extends ButtonProps {
  children?: any;
  variant?: 'filled' | 'light' | 'basic' | 'outlined';
}

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            color: 'white',
            backgroundColor: '#487F87',
            '&:hover': {
              backgroundColor: '#487F87',
            },
            '&:focus': {
              backgroundColor: '#00318e',
            },
            '&:disabled': {
              color: 'white',
              backgroundColor: '#e0e0e0',
            },
          },
        },
        {
          props: { variant: 'light' },
          style: {
            color: 'black',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#EEEEEE',
            },
            '&:focus': {
              color: 'white',
              backgroundColor: '#487F87',
            },
            '&:disabled': {
              color: '#DDDBDA',
              backgroundColor: '#FFFFFF',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: '#487F87',
            backgroundColor: '#FFFFFF',
            border: '1px solid #487F87',
            '&:hover': {
              color: '#00318E',
              border: '1px solid #1A4DA4',
            },
            '&:focus': {
              border: '1px solid #00318E',
              color: '#00318E',
            },
            '&:disabled': {
              color: '#DDDBDA',
              border: '1px solid #E0E0E0',
            },
          },
        },
        {
          props: { variant: 'basic' },
          style: {
            color: '#487F87',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              color: '#00318E',
            },
            '&:focus': {
              color: '#00318E',
            },
            '&:disabled': {
              color: '#DDDBDA',
            },
          },
        },
      ],
    },
  },
});

const StyledButton = styled(Button)({
  textAlign: 'center',
  borderRadius: '4px',
  lineHeight: '30px',
  textTransform: 'capitalize',
  height: '32px',
  fontSize: '13px',
  minWidth: '74px',
  fontFamily: 'Nunito Sans',
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    filled: true;
    light: true;
    outlined: true;
    basic: true;
  }
}

export const KwButton = ({ children, ...props }: IProps) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledButton {...props}>{children}</StyledButton>
    </ThemeProvider>
  );
};
