import CachedIcon from '@mui/icons-material/Cached';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Container = styled(Button)`
  width: 88px;
  height: 32px;
  padding: 9px 16px;
`;

const StyledCacheIcon = styled(CachedIcon)`
  color: ${props => props.theme.palette.accent.primary};
  margin-right: 10px;
`;

const StyledTypography = styled(Typography)`
  font-size: 13px;
  line-height: 20px;
  color: #3b3b3b;
  text-transform: none;
  font-weight: 700;
`;

export const ResetButton = ({ onClick }) => (
  <Container variant="text" onClick={onClick}>
    <StyledCacheIcon fontSize="small" />
    <StyledTypography>Reset</StyledTypography>
  </Container>
);
