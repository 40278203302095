import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import usePagination from '@mui/material/usePagination';
import * as React from 'react';
import { useKwPagination } from './useKwPagination';

type UseKwPaginationDependency = Pick<ReturnType<typeof useKwPagination>, 'page' | 'handleChangePage'>;
type IKwPaginationRouter = UseKwPaginationDependency & {
  totalPageCount: number;
};

// custom pagination documentation: https://mui.com/components/pagination/#usepagination
export function KwPaginationRouter({ page, totalPageCount, handleChangePage, ...navProps }: IKwPaginationRouter) {
  const { items } = usePagination({
    page: page + 1,
    count: totalPageCount,
    onChange: handleChangePage,
  });

  return (
    <nav {...navProps} aria-label="pagination navigation">
      <StyledList>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = <StyledEllipsis>…</StyledEllipsis>;
          } else if (type === 'page') {
            children = (
              <StyledPageButton selected={selected} type="button" variant="text" aria-label={`Go to page ${page}`} {...item}>
                <StyledPageText selected={selected} variant="smallMedium">
                  {page}
                </StyledPageText>
              </StyledPageButton>
            );
          } else if (type === 'previous') {
            children = (
              <StyledArrows aria-label={`Go to previous page`} direction="left" type="button" {...item}>
                <img width={12} src={'/icons/chevrons-left.svg'} alt="previous-page" />
              </StyledArrows>
            );
          } else if (type === 'next') {
            children = (
              <StyledArrows aria-label={`Go to next page`} direction="right" type="button" {...item}>
                <img width={12} src={'/icons/chevrons-left.svg'} alt="next-page" />
              </StyledArrows>
            );
          } else {
            children = <div></div>;
          }

          return <StyledListItem key={index}>{children}</StyledListItem>;
        })}
      </StyledList>
    </nav>
  );
}

/**
 * styles
 * */

const StyledEllipsis = styled('div')`
  color: ${props => props.theme.palette.accent.primary};
  transform: translateY(-3px);
  font-weight: 700;
  text-align: center;
`;

const StyledList = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  gap: 8px;
`;

const StyledListItem = styled('li')`
  width: 24px;
  height: 24px;
`;

const StyledPageText = styled(Typography)<{ selected: boolean }>`
  color: ${props => (props.selected ? ' white' : props.theme.palette.text.primary)};
`;

const StyledPageButton = styled(Button)<{ selected: boolean }>`
  min-width: 0;
  width: 24px;
  height: 24px;
  background-color: ${props => (props.selected ? props.theme.palette.accent.primary : props.theme.palette.greyOverride[100])};
  border-color: #487F87;
  &:hover {
    ${StyledPageText} {
      ${props => (props.selected ? `color: ${props.theme.palette.text.primary};` : '')}
    }
  }
`;

const StyledArrows = styled(Button)<{
  direction: 'left' | 'right';
  disabled: boolean;
}>`
  min-width: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  border: 1px solid ${props => props.theme.palette.accent.primary};
  ${props => (props.disabled ? `opacity: 50%;` : '')}
  img {
    // color generated using https://codepen.io/sosuke/pen/Pjoqqp
    filter: ${props => props.theme.palette.accent.filter500};
    transform: ${props => (props.direction === 'right' ? 'rotate(180deg)' : '')} scale(0.7);
  }
`;
