import { styled } from '@mui/material/styles';
import { Chip, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useState } from 'react';
import { IssueDescriptionModal } from './IssueDescriptionModal';
import { IssueTypesModal } from './IssueTypesModal';

interface IProps {
  riskIndicators: {
    [title: string]: {
      isSecurityConcern: boolean;
      isPrivacyConcern: boolean;
      weight: number;
      description: string;
      adminTitle: string;
      adminDescription: string;
    };
  };
}

export default function AppIssuesTable({ riskIndicators }: IProps) {
  const [selectedIssue, setSelectedIssue] = useState<{ title: string; description: string } | null>(null);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const handleOpenModal = (title: string, description: string) => {
    setSelectedIssue({ title, description });
  };

  const handleCloseModal = () => {
    setSelectedIssue(null);
  };

  const handleHelpIconClick = () => {
    setIsHelpModalOpen(true);
  };

  const handleHelpModalClose = () => {
    setIsHelpModalOpen(false);
  };

  if (riskIndicators == null || Object.keys(riskIndicators).length === 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', height: '600px' }}>
        <div style={{ textAlign: 'center' }}>
          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 46.5C11.8 46.5 2 36.7 2 24.5C2 12.3 11.8 2.5 24 2.5C36.2 2.5 46 12.3 46 24.5C46 36.7 36.2 46.5 24 46.5ZM24 6.5C14 6.5 6 14.5 6 24.5C6 34.5 14 42.5 24 42.5C34 42.5 42 34.5 42 24.5C42 14.5 34 6.5 24 6.5ZM14.4867 29.8011C15.0809 30.5121 18.4135 34.5 24 34.5C29.5865 34.5 32.919 30.5122 33.5133 29.8011C33.5572 29.7485 33.5862 29.7138 33.6 29.7C34.2 28.9 34 27.5 33.2 26.9C32.4 26.3 31 26.5 30.4 27.3C30.4 27.3 27.8 30.5 24 30.5C20.2 30.5 17.6 27.3 17.6 27.3C17 26.5 15.6 26.3 14.8 26.9C14 27.5 13.8 28.9 14.4 29.7C14.4138 29.7138 14.4427 29.7484 14.4866 29.801L14.4867 29.8011L14.4867 29.8011ZM16.8867 21.3889C15.42 21.3889 14.4422 20.4111 14.4422 18.9444C14.4422 17.4778 15.42 16.5 16.8867 16.5C18.3533 16.5 19.3311 17.4778 19.3311 18.9444C19.3311 20.4111 18.3533 21.3889 16.8867 21.3889ZM28.2222 18.9444C28.2222 20.4111 29.2 21.3889 30.6667 21.3889C32.1333 21.3889 33.1111 20.4111 33.1111 18.9444C33.1111 17.4778 32.1333 16.5 30.6667 16.5C29.2 16.5 28.2222 17.4778 28.2222 18.9444Z"
              fill="#244C5A"
            />
          </svg>
          <div style={{ marginTop: '16px' }}>No issues detected</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <StyledCardTitle>Issues</StyledCardTitle>
      <StyledTable aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="right">
              <HeaderContainer>
                Type
                <StyledHelpIcon onClick={handleHelpIconClick} />
              </HeaderContainer>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(riskIndicators).map(
            ([title, { isSecurityConcern, isPrivacyConcern, weight, description, adminTitle, adminDescription }]) => {
              let warningIconColor: 'inherit' | 'error' | 'warning' = 'inherit';
              if (weight >= 75) {
                warningIconColor = 'error';
              } else if (weight >= 35) {
                warningIconColor = 'warning';
              }
              const indicatorTitle = adminTitle || title;
              const indicatorDescription = adminDescription || description;
              return (
                <StyledTableRow key={title} onClick={() => handleOpenModal(indicatorTitle, indicatorDescription)}>
                  <TableCell>
                    <StyledWarningIcon color={warningIconColor} />
                    {indicatorTitle}
                  </TableCell>
                  <TableCell align="right">
                    <StyledChip
                      label={isSecurityConcern && isPrivacyConcern ? 'Security & Privacy' : isSecurityConcern ? 'Security' : 'Privacy'}
                    />
                  </TableCell>
                </StyledTableRow>
              );
            },
          )}
        </TableBody>
        {selectedIssue && (
          <IssueDescriptionModal
            title={selectedIssue.title}
            description={selectedIssue.description}
            isOpen={Boolean(selectedIssue)}
            onClose={handleCloseModal}
          />
        )}
      </StyledTable>
      <IssueTypesModal isOpen={isHelpModalOpen} onClose={handleHelpModalClose} />
    </>
  );
}

const StyledTable = styled(Table)({
  backgroundColor: 'white',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    color: theme.palette.common.black,
    borderBottom: 'none',
    paddingRight: 32,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledChip = styled(Chip)`
  background-color: #dce7e8;
  color: #244c5a;
  height: 36px;
  margin-right: 10px;
  font-weight: 800;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  &:hover {
    background-color: rgba(79, 134, 142, 0.1);
  }
`;

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.base.grey,
  '&.MuiSvgIcon-colorError': {
    color: theme.palette.error.main,
  },
  '&.MuiSvgIcon-colorWarning': {
    color: theme.palette.warning.main,
  },
  display: 'inline-block',
  margin: '5px 5px -6px 0',
}));

const StyledHelpIcon = styled(HelpIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const HeaderContainer = styled('div')({
  display: 'inline-flex',
  gap: '4px',
});

const StyledCardTitle = styled('div')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: '500',
  color: '#000',
});
