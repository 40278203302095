export const createQueryString = (params: { [key: string]: string | string[] }) => {
  const deviceParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (value === null || typeof value === 'undefined') {
      continue;
    }
    if (Array.isArray(value)) {
      for (const entry of value) {
        deviceParams.append(key, entry);
      }
    } else {
      deviceParams.append(key, value);
    }
  }
  return deviceParams.toString();
};
