import type { ApplicationVersion } from '@kw/device-service/dist/application/entities/applicationVersion.entity';
import { createQueryString } from '../../utils/createQueryString';
import { useFetchService } from '../../utils/fetchService';
import { useStickyResult } from '../../utils/useStickyResult';

export const useInstalledApps = (shouldFetch: boolean, queryParams, accessToken) => {
  const queryParamString = createQueryString({ page: '0', ...queryParams });

  const { data: appData, error: appDataError } = useFetchService<{
    apps: ApplicationVersion[];
    total: number;
  }>(`/application/versions?${queryParamString}`, accessToken, shouldFetch);
  const { apps, total } = useStickyResult(appData);

  return { apps, appDataError, total };
};
