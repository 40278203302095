export const CenteringContainer = ({ children, vertical = true, horizontal = true }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: horizontal ? 'center' : '',
        alignItems: vertical ? 'center' : '',
      }}
    >
      {children}
    </div>
  );
};
