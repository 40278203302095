import { Theme } from '@mui/material';

/** styles */
export const securityColorMap = (theme: Theme, score) => {
  if (score < 3) {
    return theme.palette.base.green;
  }
  if (score < 5) {
    return theme.palette.chart.lime;
  }

  if (score < 7) {
    return theme.palette.base.yellow;
  }
  if (score < 9) {
    return theme.palette.base.orange;
  }

  return theme.palette.state.danger;
};
