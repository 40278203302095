import type { DeviceState } from '@kw/device-service/dist/device/entities/deviceState.entity';
import { Checkbox, Chip, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useState } from 'react';
import { useDebounce } from 'use-debounce';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  CheckBoxOutlined as CheckBoxOutlinedIcon,
  IndeterminateCheckBoxOutlined as IndeterminateCheckBoxOutlinedIcon,
} from '@mui/icons-material';
import { ResetButton } from '../shared/ResetButton';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { useDeviceFilterParams } from './useDeviceSortParams';
import { KwMultiSelect, useKwMultiSelect } from '../../kw-ui-components/KwMultiSelect/KwMultiSelect';
import { KwRangeSelect, useKwRangeSelect } from '../../kw-ui-components/KwRangeSelect/KwRangeSelect';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { KwFilterWrapper } from '../../kw-ui-components/KwFilterWrapper';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { PageLoader } from '../../PageLoader';
import { createQueryString } from '../../utils/createQueryString';
import { getMostRecentState } from '../../utils/getMostRecentState';
import { riskLevelMap } from '../../utils/privacyLevelMap';
import { useStickyResult } from '../../utils/useStickyResult';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { AuthPermissions } from '../../auth/AuthPermissions';
import { AddDeviceOwnerButton } from '../DeviceOwners/AddDeviceOwnerButton';
import { AddDeviceOwnerModal } from '../DeviceOwners/AddDeviceOwnerModal';
import { CenteringContainer } from '../shared/CenteringContainer';
import { useFetchService } from '../../utils/fetchService';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { UnenrollDeviceConfirmationModal } from './UnenrollDeviceConfirmationModal';
import { DevicesTableToolbar } from './DevicesTableToolbar';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { formatPlatform } from '../../utils/formatPlatform';
import { BackgroundProgressBar } from '../BackgroundProgressBar/BackgroundProgressBar';
import { NavigationContext } from '../Navigation/NavigationProvider';

export const useDevices = (queryParams, accessToken) => {
  const queryParamString = createQueryString(queryParams);
  const { data: devicesData, error: devicesError, mutate } = useFetchService(`/device/bulk?${queryParamString}`, accessToken);

  const { devices, total } = useStickyResult(devicesData);
  const isLoading = !devicesData && !devicesError;

  return { devices, devicesError, total, isLoading, mutate };
};

export default function Devices() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddDeviceOwnerModal, setShowAddDeviceOwnerModal] = useState(false);
  const [showUnenrollDeviceConfirmationModal, setShowUnenrollDeviceConfirmationModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({} as any);
  const [multipleSelected, setMultipleSelected] = useState([]);
  const { setDeviceDetails } = useContext(NavigationContext);

  const { page, pageSize, setPage, handleChangePage, handleChangeRowsPerPage } = useKwPagination(10);

  const osSelectHook = useKwMultiSelect();
  const osVersionSelectHook = useKwMultiSelect();
  const securityRiskLevelRangeHook = useKwRangeSelect();
  const privacyRiskLevelRangeHook = useKwRangeSelect();
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const {
    deviceNameOrder,
    toggleDeviceNameOrder,
    osOrder,
    toggleOsOrder,
    securityRiskLevelOrder,
    toggleSecurityRiskLevelOrder,
    privacyRiskLevelOrder,
    togglePrivacyRiskLevelOrder,
    numberOfAppsOrder,
    toggleNumberOfAppsOrder,
    resetToggles,
  } = useDeviceFilterParams();

  const { devices, total, devicesError, isLoading, mutate } = useDevices(
    {
      page: String(page),
      pageSize: String(pageSize),
      minSecurityRiskLevel: securityRiskLevelRangeHook.value[0],
      maxSecurityRiskLevel: securityRiskLevelRangeHook.value[1],
      minPrivacyRiskLevel: privacyRiskLevelRangeHook.value[0],
      maxPrivacyRiskLevel: privacyRiskLevelRangeHook.value[1],
      os: osSelectHook.value,
      osVersion: osVersionSelectHook.value,
      query: debouncedSearchQuery,
      deviceNameOrder,
      osOrder,
      securityRiskLevelOrder,
      privacyRiskLevelOrder,
      numberOfAppsOrder,
    },
    accessToken,
  );

  const { data: osVersionData, error: osVersionError } = useFetchService('/device/os-versions', accessToken);

  let tableHeaders = [
    {
      title: 'Device Name',
      onClick: toggleDeviceNameOrder,
      sortValue: deviceNameOrder,
    },
    { title: 'Platform', onClick: toggleOsOrder, sortValue: osOrder },
    { title: 'Platform Version', onClick: () => {}, sortValue: null },
    {
      title: '# of Apps',
      onClick: toggleNumberOfAppsOrder,
      sortValue: numberOfAppsOrder,
    },
    {
      title: 'Security Risk Level',
      onClick: toggleSecurityRiskLevelOrder,
      sortValue: securityRiskLevelOrder,
    },
    { title: 'Privacy Risk Level', onClick: togglePrivacyRiskLevelOrder, sortValue: privacyRiskLevelOrder },
    { title: 'Action', onClick: () => {}, sortValue: null },
  ];

  if (!verifyAuthPermission(AuthPermissions.LIST_DEVICE_APPS)) {
    tableHeaders = tableHeaders.filter(header => header.title !== '# of Apps');
  }

  const osVersionsSelectData =
    osVersionData?.osVersions?.map(version => ({
      value: version,
      label: version,
    })) ?? [];

  const osSelectData = [
    { value: 'android', label: 'Android' },
    { value: 'ios', label: 'iOS' },
  ];

  const totalPageCount = Math.ceil(total / pageSize);

  if (devicesError || osVersionError) {
    if (devicesError) console.error(devicesError);
    if (osVersionError) console.error(osVersionError);

    return <div>failed to load</div>;
  }
  const resetFilterAndSorts = () => {
    resetToggles();
    setPage(0);
    osSelectHook.reset();
    osVersionSelectHook.reset();
    securityRiskLevelRangeHook.reset();
    privacyRiskLevelRangeHook.reset();
    setSearchQuery('');
  };

  if (!devices || !osVersionData) {
    return <PageLoader />;
  }

  const isSelected = (id: number) => multipleSelected.some(selection => selection.id === id);

  const selectDevice = ({ id, name }: { id: number; name: string }) => {
    const selectedIndex = multipleSelected.findIndex(selection => selection.id === id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(multipleSelected, { id, name });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(multipleSelected.slice(1));
    } else if (selectedIndex === multipleSelected.length - 1) {
      newSelected = newSelected.concat(multipleSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(multipleSelected.slice(0, selectedIndex), multipleSelected.slice(selectedIndex + 1));
    }

    setMultipleSelected(newSelected);
  };

  const selectAllDevices = (event?) => {
    if (event.target.checked) {
      const newSelectedDevices = devices.map(({ id, name }) => ({ id, name }));
      setMultipleSelected(newSelectedDevices);
      return;
    }
    setMultipleSelected([]);
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <KwContainer>
        <BackgroundProgressBar />
        <KwFilterWrapper>
          <KwMultiSelect
            label="Platform"
            items={osSelectData}
            {...osSelectHook}
            handleChange={(...args) => {
              osSelectHook.handleChange(...args);
              setPage(0);
            }}
          />
          <KwMultiSelect
            label="Platform Version"
            items={osVersionsSelectData}
            {...osVersionSelectHook}
            handleChange={(...args) => {
              osVersionSelectHook.handleChange(...args);
              setPage(0);
            }}
          />
          <KwRangeSelect
            label="Security Risk Level"
            sliderAriaLabel="security-risk-score-range-slider"
            {...securityRiskLevelRangeHook}
            handleChange={(...args) => {
              securityRiskLevelRangeHook.handleChange(...args);
              setPage(0);
            }}
          />
          <KwRangeSelect
            label="Privacy Risk Level"
            sliderAriaLabel="security-risk-score-range-slider"
            {...privacyRiskLevelRangeHook}
            handleChange={(...args) => {
              privacyRiskLevelRangeHook.handleChange(...args);
              setPage(0);
            }}
          />

          <ResetButton
            onClick={() => {
              resetFilterAndSorts();
            }}
          />

          <AddDeviceOwnerButton onClick={() => setShowAddDeviceOwnerModal(true)} />
          <AddDeviceOwnerModal isOpen={showAddDeviceOwnerModal} onClose={() => setShowAddDeviceOwnerModal(false)} />
          <UnenrollDeviceConfirmationModal
            showConfirmationModal={showUnenrollDeviceConfirmationModal}
            setShowConfirmationModal={setShowUnenrollDeviceConfirmationModal}
            selectedDevice={selectedDevice}
            afterSuccessFunc={mutate}
          />
        </KwFilterWrapper>
        <div>
          <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder="Search devices" />
          <KwTablePaginationWrapper>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50, 100]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
          </KwTablePaginationWrapper>

          <DevicesTableToolbar
            numSelected={multipleSelected.length}
            selectedDevices={multipleSelected}
            onClickSelectAll={selectAllDevices}
            afterSuccessFunc={() => {
              mutate();
              setMultipleSelected([]);
            }}
          />

          <KwTable aria-label="Devices-Table">
            <KwTableHead>
              <KwTableRow>
                <KwTableCell padding="checkbox">
                  <Checkbox
                    indeterminate={multipleSelected.length > 0 && multipleSelected.length < devices.length}
                    checked={devices.length > 0 && multipleSelected.length === devices.length}
                    onChange={selectAllDevices}
                    inputProps={{
                      'aria-label': 'select all devices',
                    }}
                    indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
                  />
                </KwTableCell>
                {tableHeaders.map(({ title, onClick, sortValue }) => (
                  <KwColumnSortHeader
                    key={title}
                    title={title}
                    onClick={() => {
                      onClick();
                      setPage(0);
                    }}
                    sortValue={sortValue}
                  />
                ))}
              </KwTableRow>
            </KwTableHead>
            <KwTableBody>
              {devices.map((device, index) => {
                const deviceState = getMostRecentState<DeviceState>(device.states);
                const isItemSelected = isSelected(device.id);
                const labelId = `device-table-checkbox-${index}`;
                return (
                  <KwTableRow key={device.id} selected={isItemSelected}>
                    <KwTableCell
                      onClick={() => selectDevice({ id: device.id, name: device.name })}
                      tabIndex={-1}
                      key={device.id}
                      selected={isItemSelected}
                    >
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                          'aria-label': `${device.name} checkbox`,
                          'aria-checked': isItemSelected,
                        }}
                        checkedIcon={<CheckBoxOutlinedIcon />}
                      />
                    </KwTableCell>
                    <KwTableCell selected={deviceNameOrder}>
                      <CenteringContainer horizontal={false}>
                        <Link
                          underline="hover"
                          href={`#/device-details?deviceId=${device.id}&deviceName=${device.name}`}
                          onClick={() => {
                            setDeviceDetails({ deviceId: device.id, deviceName: device.name });
                          }}
                        >
                          {device.name}
                        </Link>
                      </CenteringContainer>
                    </KwTableCell>
                    <KwTableCell selected={osOrder}>{formatPlatform(device.os)}</KwTableCell>
                    <KwTableCell>{deviceState.osVersion}</KwTableCell>
                    {verifyAuthPermission(AuthPermissions.LIST_DEVICE_APPS) && (
                      <KwTableCell selected={numberOfAppsOrder}>{deviceState.applicationCount}</KwTableCell>
                    )}
                    <KwTableCell selected={securityRiskLevelOrder}>
                      <StyledRiskLevel label={deviceState.securityRiskLevel} />
                    </KwTableCell>
                    <KwTableCell selected={privacyRiskLevelOrder}>
                      <StyledRiskLevel label={deviceState.privacyRiskLevel} />
                    </KwTableCell>
                    <KwTableCell>
                      <KwButton
                        startIcon={<DeleteOutlineIcon />}
                        onClick={() => {
                          setSelectedDevice(device);
                          setShowUnenrollDeviceConfirmationModal(!showUnenrollDeviceConfirmationModal);
                        }}
                      />
                    </KwTableCell>
                  </KwTableRow>
                );
              })}
            </KwTableBody>
          </KwTable>
          {total > 10 ? (
            <KwTablePaginationWrapper>
              <KwRowsPerPage
                rowsPerPageOptions={[10, 20, 50, 100]}
                pageSize={pageSize}
                page={page}
                totalNumberOfEntries={total}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
            </KwTablePaginationWrapper>
          ) : null}
        </div>
      </KwContainer>
    </>
  );
}

/** styles */
const StyledRiskLevel = styled(Chip)<{ label: string }>`
  color: white;
  border-radius: 12px;
  width: 52px;
  height: 24px;
  font-size: 12px;
  background-color: ${props => riskLevelMap(props.theme)[props.label]};
` as typeof Chip;

const StyledPagination = styled(KwPaginationRouter)`
  display: flex;
  align-items: center;
`;
