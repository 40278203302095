import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import { useKwPagination } from './useKwPagination';

type UseKwPaginationDependency = Pick<ReturnType<typeof useKwPagination>, 'page' | 'pageSize' | 'handleChangeRowsPerPage'>;
type IKwRowsPerPageProps = UseKwPaginationDependency & {
  rowsPerPageOptions: number[];
  totalNumberOfEntries: number;
  page: number;
};

const KwRowsPerPageComponent = ({
  pageSize,
  page,
  handleChangeRowsPerPage,
  rowsPerPageOptions,
  totalNumberOfEntries,
  ...props
}: IKwRowsPerPageProps) => (
  <TablePagination
    component="div"
    rowsPerPageOptions={totalNumberOfEntries > 10 ? rowsPerPageOptions : []}
    rowsPerPage={pageSize}
    page={page}
    count={totalNumberOfEntries}
    onPageChange={() => {}}
    // use StyledPagination instead
    ActionsComponent={() => <div></div>}
    labelDisplayedRows={({ from, to, count }) =>
      count <= 1 ? `Displaying ${from} of ${count}` : `Displaying ${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
    }
    labelRowsPerPage="Rows per page"
    onRowsPerPageChange={handleChangeRowsPerPage}
    {...props}
  />
);

export const KwRowsPerPage = styled(KwRowsPerPageComponent)`
  height: 100%;
  grid-area: rows-per-page;
  .MuiSelect-select {
    height: 25px;
    width: 60px;
    min-height: 0;
    border: 1px solid ${({ theme }) => theme.palette.accent.primary};
    border-radius: 5px;
    padding-bottom: 0;
    padding-top: 2px;
    vertical-align: middle;
    box-sizing: border-box;
  }

  .MuiTablePagination-selectLabel {
    display: none;
  }

  .MuiTablePagination-toolbar {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 2px;
    height: 100%;
    column-gap: 14px;
  }
  .MuiTablePagination-displayedRows {
    padding-top: 3px;
  }
  .MuiTablePagination-input {
    text-align-last: center;
    margin: 0;
  }
` as typeof KwRowsPerPageComponent;
