export const validateEmail = (email: string) => {
  // non-global search to prevent tracking lastIndex - strictly used to validate manual input one email at a time
  const validEmailPattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return validEmailPattern.test(email);
};

/* 
  RFC 2822 - https://stackoverflow.com/a/16168103
  Global search captures all instances of valid emails in clipboard data string 
  - assumes that clipboard data may contain a list of comma-separated emails
*/
export const globalValidEmailPattern =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
