import { useState } from 'react';
import { useCycleState } from '../../utils/useCycleState';

export const usePoliciesSortParams = () => {
  const [policyNameOrder, togglePolicyNameOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [numberOfDevicesOrder, toggleNumberOfDevicesOrder] = useCycleState([null, 'ASC', 'DESC']);
  const toggles = [togglePolicyNameOrder, toggleNumberOfDevicesOrder];

  const [orderBy, setOrderBy] = useState(null);

  const toggleExclude = triggeredToggle => () => {
    triggeredToggle();
    toggles.filter(toggle => toggle !== triggeredToggle).forEach(toggle => toggle(0));
  };

  const resetToggles = () => {
    toggles.forEach(toggle => toggle(0));
  };

  return {
    policyNameOrder,
    togglePolicyNameOrder: toggleExclude(togglePolicyNameOrder),
    numberOfDevicesOrder,
    toggleNumberOfDevicesOrder: toggleExclude(toggleNumberOfDevicesOrder),
    orderBy,
    setOrderBy,
    resetToggles,
  };
};
