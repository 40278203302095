import CountryFlag from 'react-country-flag';
import { styled } from '@mui/material/styles';
import { countriesMap } from '../../utils/countriesMap';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';

interface IProps {
  contactedCountryCodes: string[];
  highRiskCountries: string[];
}

export default function CountriesContacted({ contactedCountryCodes, highRiskCountries }: IProps) {
  const highRiskCountriesCodes = contactedCountryCodes.filter(countryCode => highRiskCountries.includes(countryCode));
  const otherCountriesCodes = contactedCountryCodes.filter(countryCode => !highRiskCountries.includes(countryCode));

  highRiskCountriesCodes.sort((a, b) => countriesMap[a].countryName.localeCompare(countriesMap[b].countryName));
  otherCountriesCodes.sort((a, b) => countriesMap[a].countryName.localeCompare(countriesMap[b].countryName));
  const sortedCountryCodes = [...highRiskCountriesCodes, ...otherCountriesCodes];

  return (
    <KwTable>
      <KwTableBody>
        {sortedCountryCodes.map(countryCode => (
          <KwTableRow key={countryCode}>
            <KwTableCell>
              <StyledCountryFlag countryCode={countryCode} svg />
              {highRiskCountries?.includes(countryCode) ? (
                <StyledTypography>{countriesMap[countryCode].countryName}</StyledTypography>
              ) : (
                countriesMap[countryCode].countryName
              )}
            </KwTableCell>
          </KwTableRow>
        ))}
      </KwTableBody>
    </KwTable>
  );
}

const StyledCountryFlag = styled(CountryFlag)`
  width: 24px;
  height: 16px;
  margin-right: 8px;
`;

const StyledTypography = styled('span')`
  color: red;
  display: inline;
`;
