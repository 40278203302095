import { Divider, List, ListItem, Modal, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { KwButton } from '../../../kw-ui-components/KwButton/KwButton';
import { sendHttpRequest } from '../../../utils/network.service';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  app: any; // replace
  category: string;
  mutate: () => void;
}

export const ManagedAppsModal = ({ isOpen, onClose, app, category, mutate }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const removeManagedApp = async () => {
    await sendHttpRequest({
      path: `${process.env.PDM_SERVICE_URL}/pdm/managed-apps/${app.managedAppId}`,
      method: 'DELETE',
      successMessage: `Successfully removed ${app.name}`,
      errorMessage: `Error removing ${app.name}`,
      enqueueSnackbar,
    });
    mutate();
    onClose();
  };

  const publishManagedApp = async () => {
    const { packageName, platform, published } = app;
    await sendHttpRequest({
      path: `${process.env.PDM_SERVICE_URL}/pdm/managed-apps/${app.managedAppId}`,
      method: 'PUT',
      body: { published: !published, packageName, platform },
      successMessage: `Successfully ${!published ? 'published' : 'unpublished'} ${app.name}`,
      errorMessage: `Error ${!published ? 'publishing' : 'unpublishing'} ${app.name}`,
      enqueueSnackbar,
    });
    mutate();
    onClose();
  };

  const appName = app.name;
  const text = {
    remove: {
      header: `Remove ${appName}?`,
      subheader: `What happens when you remove ${appName}:`,
      list: [
        `${appName} will be unpublished and deleted from your list of Managed Apps`,
        `${appName} will be removed from any Device that has it installed`,
        `${appName} will no longer show up as an available Managed App in the Q-Scout Mobile app for enrolled Devices`,
      ],
      cta: 'Remove app',
    },
    publish: {
      header: `Publish ${appName}?`,
      subheader: `What happens when you publish ${appName}:`,
      list: [
        `${appName} will show up as an available Managed App in the Q-Scout Mobile app for enrolled Devices`,
        `As Device Owners install the app, the # of Devices count should grow`,
        `Device Owners will be offered the latest version of the app available from the App Store`,
      ],
      cta: 'Publish app',
    },
    unpublish: {
      header: `Unpublish ${appName}?`,
      subheader: `What happens when you unpublish ${appName}:`,
      list: [
        `${appName} will be removed from any Device that has it installed`,
        `${appName} will no longer show up as an available Managed App in the Q-Scout mobile app for enrolled Devices`,
      ],
      cta: 'Unpublish app',
    },
  };

  const modalText = text[category];
  const { header, subheader, list, cta } = modalText;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledBox>
        <Typography variant="h3Medium" id={`${category}-managed-app-modal-title`}>
          <StyledCloseIcon onClick={onClose} />
          {header}
        </Typography>
        <StyledDivider />
        <StyledTypography variant="bodyRegular">{subheader}</StyledTypography>
        <StyledTypography variant="bodyRegular">
          <StyledList>
            {list.map((listitem: string, index: number) => {
              return <StyledListItem key={`${category}-list-item-${index}`}>{listitem}</StyledListItem>;
            })}
          </StyledList>
        </StyledTypography>
        <StyledButtonsContainer>
          <KwButton variant="outlined" onClick={onClose}>
            Cancel
          </KwButton>
          <KwButton variant="filled" onClick={category === 'remove' ? removeManagedApp : publishManagedApp}>
            {cta}
          </KwButton>
        </StyledButtonsContainer>
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)`
  padding: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  background-color: #fafafa;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

const StyledButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  & button {
    margin-left: 24px;
  }
`;

const StyledList = styled(List)`
  list-style-type: disc;
  margin-bottom: 10px;
`;

const StyledListItem = styled(ListItem)`
  display: list-item;
  margin-left: 16px;
  padding-left: 0;
`;
