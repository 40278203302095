import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import { Apple, Android } from '@mui/icons-material';
import { DashboardExpandableDataCard } from './DashboardExpandableDataCard';
import { KwScoreBadge } from '../../kw-ui-components/KwRiskBadge';
import { useFetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';

const prettifyCategory = (category: string) => {
  return category
    .split('_')
    .map(word => {
      const lword = (word.length ? word : 'unknown').toLowerCase();
      return ['or', 'and'].includes(lword) ? lword : lword[0].toUpperCase() + lword.slice(1);
    })
    .join(' ');
};

const useTopRiskData = ({
  instanceType,
  riskType,
  range,
  accessToken,
}: {
  instanceType: 'Apps' | 'Devices';
  riskType: 'security' | 'privacy';
  range: number;
  accessToken: string;
}) => {
  let path;
  if (instanceType === 'Apps') {
    path = `/application/top-risk-applications?riskType=${riskType}&range=${range}&limit=10`;
  } else {
    path = `/device/top-risk-levels?riskType=${riskType}&range=${range}&limit=10`;
  }
  const { data, error } = useFetchService(path, accessToken);

  // return data if Apps
  if (instanceType === 'Apps') {
    const isLoading = !data && !error;
    return {
      topData: (data || []).map((d, i) => ({
        ...d,
        id: i,
        os: d.os === 'android' ? 'Android' : 'iOS',
        name: (d.applicationCategories && d.applicationCategories.length ? d.applicationCategories : [''])
          .map(category => prettifyCategory(category))
          .join(', '),
      })),
      isLoading,
    };
  }

  // return data if Devices
  if (instanceType === 'Devices') {
    const isLoading = !data && !error;
    return {
      topData: (data || []).map((d, i) => ({
        ...d,
        id: i,
        os: d.os === 'android' ? 'Android' : 'iOS',
        riskLevel: d.riskLevel,
        name: d.model,
      })),
      isLoading,
    };
  }
};

export function TopTenRiskScoreCard({
  scoreType,
  instanceType,
  initialDateRange,
  setCustom,
}: {
  scoreType: 'Security' | 'Privacy';
  instanceType: 'Apps' | 'Devices';
  initialDateRange: number;
  setCustom: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [range, setRange] = useState(initialDateRange);
  const [accessToken = ''] = useLocalStorage('accessToken', '');

  const riskType = scoreType === 'Security' ? 'security' : 'privacy';
  const { topData, isLoading } = useTopRiskData({ instanceType, riskType, range, accessToken });

  return (
    <DashboardExpandableDataCard
      initialDateRange={initialDateRange}
      isLoading={isLoading}
      title={`Top 10 ${scoreType} Risk Score ${instanceType}`}
      data={topData}
      minRowShow={5}
      maxRowShow={10}
      rowIdField={'id'}
      columns={[
        {
          label: instanceType === 'Devices' ? 'Name' : 'Categories',
          keyName: 'name',
          align: 'left',
          ...(instanceType === 'Apps'
            ? null
            : {
                component: ({ row }) => (
                  <Box display="flex" alignItems="center">
                    <div>{row.name}</div>
                  </Box>
                ),
              }),
        },
        { label: 'OS', keyName: 'os', align: 'center' },
        {
          label: 'Risk Score',
          keyName: 'riskLevel',
          align: 'right',
          component: ({ value }) => <KwScoreBadge value={value} />,
        },
      ]}
      functionToFetch={(params: { range: number }) => {
        setRange(params.range);
        if (params.range !== initialDateRange) {
          setCustom(true);
        }
      }}
    />
  );
}

const StyledAppleIcon = styled(Apple)({ marginRight: '10px' });
const StyledAndroidIcon = styled(Android)({ marginRight: '10px' });
