import { useState } from 'react';
import { useCycleState } from '../../utils/useCycleState';

export const useRiskIndicatorsSortParams = () => {
  const [indicatorNameOrder, toggleIndicatorNameOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [keyOrder, toggleKeyOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [platformOrder, togglePlatformOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [weightOrder, toggleWeightOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [modifiedOrder, toggleModifiedOrder] = useCycleState([null, 'ASC', 'DESC']);
  const toggles = [toggleIndicatorNameOrder, toggleKeyOrder, togglePlatformOrder, toggleWeightOrder, toggleModifiedOrder];

  const [orderBy, setOrderBy] = useState(null);

  const toggleExclude = triggeredToggle => () => {
    triggeredToggle();
    toggles.filter(toggle => toggle !== triggeredToggle).forEach(toggle => toggle(0));
  };

  const resetToggles = () => {
    toggles.forEach(toggle => toggle(0));
  };

  return {
    indicatorNameOrder,
    toggleIndicatorNameOrder: toggleExclude(toggleIndicatorNameOrder),
    keyOrder,
    toggleKeyOrder: toggleExclude(toggleKeyOrder),
    platformOrder,
    togglePlatformOrder: toggleExclude(togglePlatformOrder),
    weightOrder,
    toggleWeightOrder: toggleExclude(toggleWeightOrder),
    modifiedOrder,
    toggleModifiedOrder: toggleExclude(toggleModifiedOrder),
    orderBy,
    setOrderBy,
    resetToggles,
  };
};
