import dayjs from 'dayjs';
import { createContext, useState } from 'react';

export const PolicyInfoHook = () => {
  const [policyName, setPolicyName] = useState('');
  const [policyEnabled, setPolicyEnabled] = useState(true);
  const [policyDescription, setPolicyDescription] = useState('');
  const [policyCreatedOn, setPolicyCreatedOn] = useState(dayjs().format('MM/DD/YYYY'));
  const [policyFormErrors, setPolicyFormErrors] = useState({ name: false, rules: false, validated: false });

  return {
    policyName,
    setPolicyName,
    policyEnabled,
    setPolicyEnabled,
    policyDescription,
    setPolicyDescription,
    policyCreatedOn,
    setPolicyCreatedOn,
    policyFormErrors,
    setPolicyFormErrors,
  };
};

export const PolicyInfoContext = createContext(undefined);
