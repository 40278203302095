import { styled } from '@mui/material/styles';

export const KwTablePaginationWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  padding-right: 16px;
  gap: 16px;
  height: 64px;
`;
