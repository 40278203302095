/**
 * Returns the formatted platform string
 * 'ios' => 'iOS', 'android' => 'Android'
 *
 * @param {platform} platform
 * @returns {platform} Formatted OS / Platform string
 */

export const formatPlatform = (platform: string): string => {
  const map = {
    ios: 'iOS',
    android: 'Android',
  };

  return map[platform.toLowerCase()];
};
