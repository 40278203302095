export enum DeviceOwnerColumnOrderSortKeyMap {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  dateEnrolled = 'inviteAcceptedAt',
  lastDeviceCheckIn = 'lastCheckinAt',
  numberOfDevices = 'deviceCount',
  dateInvited = 'inviteSentAt',
  dateUnenrolled = 'unenrolledAt',
}

export enum DeviceOwnerQueryParamKeyMap {
  inviteAcceptedAt = 'inviteAcceptedAt:between:',
  nameOrEmail = 'nameOrEmail:ilike:',
  lastCheckinAt = 'lastCheckinAt:between:',
  inviteSentAt = 'inviteSentAt:between:',
  unenrolledAt = 'unenrolledAt:between:',
}

export enum DeviceOwnerStatus {
  ENROLLED = 'ENROLLED',
  PENDING = 'PENDING',
  UNENROLLED = 'UNENROLLED',
}
