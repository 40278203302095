import { useState, useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { fetchService } from '../../utils/fetchService';
import { jwtPayload } from '../../auth';
import { appMetadata } from '../shared/constants';

const useOrgData = accessToken => {
  const [orgData, setData] = useState(null);
  const [orgLoading, setLoading] = useState(true);
  const [orgError, setError] = useState(null);
  const ldClient = useLDClient();

  useEffect(() => {
    const fetchData = async () => {
      if (!accessToken) return;
      try {
        const org = await fetchService('/organizations/', accessToken);
        setData(org);
        const orgId = jwtPayload(accessToken)[appMetadata].organizationId;
        if (orgId === org.id && ldClient) {
          ldClient.identify({
            kind: 'user',
            key: org.id, // LaunchDarkly is targeted to orgs
            name: org.name,
          });
        } else {
          console.warn('Unable to initialize LaunchDarkly, no org associated that matches token');
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (accessToken) {
      fetchData();
    }
  }, [accessToken, ldClient]);

  return { orgData, orgLoading, orgError };
};

export default useOrgData;
