import { styled, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrg } from '../Organization/org-provider';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';

export default function OrgMismatchError() {
  const orgData = useOrg();
  const { isAuthenticated, user, logout } = useAuth0();
  return (
    <StyledDiv>
      {isAuthenticated && (
        <>
          <StyledTypography>
            Oops! The link you clicked is for a different Q-Scout organization. Log into the correct organization and try the link again.
          </StyledTypography>
          <StyledUserMessage>
            You&apos;re signed into <b>{orgData?.orgData?.name}</b> as <b>{user.email}</b>
          </StyledUserMessage>
          <StyledKwButton
            variant="filled"
            onClick={() => {
              window.localStorage.setItem('accessToken', '');
              logout({ returnTo: window.location.origin });
            }}
          >
            Log out
          </StyledKwButton>
        </>
      )}
    </StyledDiv>
  );
}

const StyledDiv = styled('div')`
  max-width: 740px;
  margin: 100px auto auto auto;
  padding: 0 20px;
`;

const StyledTypography = styled(Typography)`
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
`;

const StyledUserMessage = styled('p')`
  font-size: 18px;
  line-height: 25px;
  margin: 50px 0 10px 0;
`;

const StyledKwButton = styled(KwButton)`
  padding: 0 50px;
`;
