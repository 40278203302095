import { Card, CardProps, Divider } from '@mui/material';

export function EmptyNetworkTrafficCard() {
  return (
    <Card
      style={{
        borderRadius: '8px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fafafa',
        paddingTop: '80px',
        paddingBottom: '80px',
        marginRight: '10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24 4.5C35.0469 4.5 44 13.4531 44 24.5C44 35.5469 35.0469 44.5 24 44.5C12.9531 44.5 4 35.5469 4 24.5C4 13.4531 12.9531 4.5 24 4.5ZM24 42C25.3047 42 27.1562 40.875 28.8359 37.5234C29.6094 35.9766 30.2578 34.0391 30.6484 32H17.2812C17.7422 34.0391 18.3906 35.9766 19.1641 37.5234C20.8438 40.875 22.6953 42 24 42ZM16.8359 29.5H31.1641C31.3828 27.9219 31.5 26.2422 31.5 24.5C31.5 22.7578 31.3828 21.0781 31.1641 19.5H16.8359C16.6172 21.0781 16.5 22.7578 16.5 24.5C16.5 26.2422 16.6172 27.9219 16.8359 29.5ZM30.6484 17C30.2578 14.8906 29.6094 13.0234 28.8359 11.4758C27.1562 8.12266 25.3047 7 24 7C22.6953 7 20.8438 8.12266 19.1641 11.4758C18.3906 13.0234 17.7422 14.8906 17.2812 17H30.6484ZM33.6172 19.5C33.8906 21.1016 34 22.7734 34 24.5C34 26.2266 33.8906 27.8984 33.6172 29.5H40.7734C41.25 27.9141 41.5 26.2344 41.5 24.5C41.5 22.7656 41.25 21.0859 40.7734 19.5H33.6172ZM29.5859 7.91094C31.2266 10.1242 32.5234 13.2812 33.2734 17H39.8125C37.7969 12.7422 34.1016 9.43125 29.5859 7.91094ZM18.4141 7.91094C13.8984 9.43125 10.2063 12.7422 8.18438 17H14.7266C15.4766 13.2812 16.7734 10.1242 18.4141 7.91094ZM6.5 24.5C6.5 26.2344 6.75312 27.9141 7.225 29.5H14.3125C14.1094 27.8984 14 26.2266 14 24.5C14 22.7734 14.1094 21.1016 14.3125 19.5H7.225C6.75312 21.0859 6.5 22.7656 6.5 24.5ZM39.8125 32H33.2734C32.5234 35.7188 31.2266 38.875 29.5859 41.0859C34.1016 39.5703 37.7969 36.2578 39.8125 32ZM14.7266 32H8.18438C10.2063 36.2578 13.8984 39.5703 18.4141 41.0859C16.7734 38.875 15.4766 35.7188 14.7266 32Z"
            fill="#244C5A"
          />
        </svg>
        <p>No network traffic detected</p>
      </div>
    </Card>
  );
}
