import { Typography, styled } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const KwFormErrorMessage = ({ error, reason }: { error: boolean; reason: string }) => {
  return (
    error && (
      <ErrorMessageWrapper>
        <StyledErrorOutlineIcon />
        <StyledTypography>{reason}</StyledTypography>
      </ErrorMessageWrapper>
    )
  );
};

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)({
  color: 'rgb(223,46,33)',
  margin: '0 8px 0 0',
});

const StyledTypography = styled(Typography)({
  color: 'rgb(223,46,33)',
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: '600',
});

const ErrorMessageWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '18px 0 0 0',
});
