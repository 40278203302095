import { Outlet, useLocation } from 'react-router-dom';
import usePageTracking from '../Rules/usePageTracking';
import { Navigation } from '../Navigation';

export default function Layout() {
  const { pathname } = useLocation();
  const page = pathname.includes('-')
    ? pathname
        .replaceAll('/', '')
        .replaceAll('-', ' ')
        .split(' ')
        .map(str => str.charAt(0).toUpperCase() + str.slice(1))
        .join(' ')
    : pathname.slice(1).charAt(0).toUpperCase() + pathname.slice(2);

  // Track page navigation with Google Analytics
  usePageTracking();

  return (
    <Navigation title={page}>
      <Outlet />
    </Navigation>
  );
}
