import _ from 'lodash';
import type { DeviceState } from '@kw/device-service/dist/device/entities/deviceState.entity';
import type { ApplicationState } from '@kw/device-service/dist/application/entities/applicationState.entity';
import type { ApplicationVersion } from '@kw/device-service/dist/application/entities/applicationVersion.entity';
/**
 * Get the most recent app/device state by get most recently created
 * @param entities { Array<DeviceState | ApplicationState> }
 * @returns { DeviceState | ApplicationState }
 */
export const getMostRecentState = <T extends DeviceState | ApplicationState | ApplicationVersion>(entities: T[]): T =>
  _.maxBy(entities, entity => entity.createdDate);
