import { useCycleState } from '../../utils/useCycleState';

export const useDeviceOwnerSortParams = () => {
  // shared
  const [deviceOwnerFirstNameOrder, toggleDeviceOwnerFirstNameOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [deviceOwnerLastNameOrder, toggleDeviceOwnerLastNameOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [deviceOwnerEmailOrder, toggleDeviceOwnerEmailOrder] = useCycleState([null, 'ASC', 'DESC']);
  // enrolled
  const [deviceOwnerDateEnrolledOrder, toggleDeviceOwnerDateEnrolledOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [deviceOwnerLastDeviceCheckInOrder, toggleDeviceOwnerLastDeviceCheckInOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [deviceOwnerNumberOfDevicesOrder, toggleDeviceOwnerNumberOfDevicesOrder] = useCycleState([null, 'ASC', 'DESC']);
  // pending
  const [deviceOwnerDateInvitedOrder, toggleDeviceOwnerDateInvitedOrder] = useCycleState([null, 'ASC', 'DESC']);
  // unenrolled
  const [deviceOwnerDateUnenrolledOrder, toggleDeviceOwnerDateUnenrolledOrder] = useCycleState([null, 'ASC', 'DESC']);

  const toggles = [
    toggleDeviceOwnerFirstNameOrder,
    toggleDeviceOwnerLastNameOrder,
    toggleDeviceOwnerEmailOrder,
    toggleDeviceOwnerDateEnrolledOrder,
    toggleDeviceOwnerLastDeviceCheckInOrder,
    toggleDeviceOwnerNumberOfDevicesOrder,
    toggleDeviceOwnerDateInvitedOrder,
    toggleDeviceOwnerDateUnenrolledOrder,
  ];

  const toggleExclude = triggeredToggle => () => {
    triggeredToggle();
    toggles.filter(toggle => toggle !== triggeredToggle).forEach(toggle => toggle(0));
  };

  const resetToggles = () => {
    toggles.forEach(toggle => toggle(0));
  };

  return {
    deviceOwnerFirstNameOrder,
    toggleDeviceOwnerFirstNameOrder: toggleExclude(toggleDeviceOwnerFirstNameOrder),
    deviceOwnerLastNameOrder,
    toggleDeviceOwnerLastNameOrder: toggleExclude(toggleDeviceOwnerLastNameOrder),
    deviceOwnerEmailOrder,
    toggleDeviceOwnerEmailOrder: toggleExclude(toggleDeviceOwnerEmailOrder),
    deviceOwnerDateEnrolledOrder,
    toggleDeviceOwnerDateEnrolledOrder: toggleExclude(toggleDeviceOwnerDateEnrolledOrder),
    deviceOwnerLastDeviceCheckInOrder,
    toggleDeviceOwnerLastDeviceCheckInOrder: toggleExclude(toggleDeviceOwnerLastDeviceCheckInOrder),
    deviceOwnerNumberOfDevicesOrder,
    toggleDeviceOwnerNumberOfDevicesOrder: toggleExclude(toggleDeviceOwnerNumberOfDevicesOrder),
    deviceOwnerDateInvitedOrder,
    toggleDeviceOwnerDateInvitedOrder: toggleExclude(toggleDeviceOwnerDateInvitedOrder),
    deviceOwnerDateUnenrolledOrder,
    toggleDeviceOwnerDateUnenrolledOrder: toggleExclude(toggleDeviceOwnerDateUnenrolledOrder),
    resetToggles,
  };
};
