import { useContext } from 'react';
import { FormGroup, TextField, Typography, Switch, styled } from '@mui/material';
import { KwFormErrorMessage } from '../../kw-ui-components/KwFormErrorMessage';
import { PolicyInfoContext } from './PolicyInfoContext';

export const PolicyCreationCard = ({ disabled }: { disabled: boolean }) => {
  const { policyName, policyEnabled, policyDescription, policyFormErrors, setPolicyName, setPolicyEnabled, setPolicyDescription } =
    useContext(PolicyInfoContext);

  return (
    <StyledFormGroup>
      {!disabled && (
        <>
          <StyledStackRow>
            <Typography variant="h5Medium">Policy Name</Typography>
            <StyledTextField
              id="outlined"
              value={policyName}
              onChange={e => setPolicyName(e.target.value)}
              error={Boolean(policyFormErrors.name)}
            />
            <KwFormErrorMessage error={Boolean(policyFormErrors.name)} reason="Unable to save policy. Policy must include a name." />
          </StyledStackRow>
          <StyledStackRow>
            <StyledStack>
              <AntSwitch
                inputProps={{ 'aria-label': 'ant design' }}
                checked={policyEnabled}
                onClick={() => setPolicyEnabled(!policyEnabled)}
              />
              <Typography>{policyEnabled ? 'Enable' : 'Disable'} Policy</Typography>
            </StyledStack>
          </StyledStackRow>
        </>
      )}
      <StyledStackRow>
        <Typography variant="h5Medium">Description (Optional)</Typography>
        {disabled ? (
          <Typography>{policyDescription}</Typography>
        ) : (
          <StyledTextField
            id="outlined-multiline-static"
            rows={3}
            multiline
            value={policyDescription}
            onChange={e => setPolicyDescription(e.target.value)}
          />
        )}
      </StyledStackRow>
    </StyledFormGroup>
  );
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#487F87',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const StyledFormGroup = styled(FormGroup)({
  width: '100%',
});

const StyledStack = styled('div')({
  display: 'flex',
  alignItems: 'center',
  textIndent: '5px',
  padding: '20px 0px 20px 0px',
});

const StyledStackRow = styled('div')({ display: 'flex', flexDirection: 'column', gap: '10px' });

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root.Mui-error': {
    '& fieldset': {
      borderWidth: '2px',
      borderColor: '#f44336',
    },
  },
});
