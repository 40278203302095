import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

interface IProps {
  value: string;
  placeholder: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

export const KwSearchInput = ({ value, placeholder, onChange, ...props }: IProps) => (
  <StyledSearchWrapper {...props}>
    <StyledSearchField>
      <StyledSearchIconWrapper>
        <img alt="search-icon" width={20} height={20} src={'/icons/search.svg'} />
      </StyledSearchIconWrapper>
      <StyledInputBase
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
      />
    </StyledSearchField>
  </StyledSearchWrapper>
);

/** styles */

const StyledSearchField = styled('div')`
  display: flex;
  background-color: ${props => props.theme.palette.greyOverride[200]};
  border-radius: 4px 4px 0 0;
  height: 48px;
  width: 100%;
`;

const StyledInputBase = styled(InputBase)`
  .MuiInputBase-input::placeholder {
    ${props => props.theme.typography.placeholderRest as any}
    opacity: 1;
  }
  width: 100%;
`;

const StyledSearchIconWrapper = styled('div')`
  height: 100%;
  padding-left: 16px;
  padding-right: 18px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearchWrapper = styled('div')`
  display: grid;
  place-items: center;
`;
