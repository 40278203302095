import { Button, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSnackbar } from 'notistack';
import { sendHttpRequest } from '../../utils/network.service';
import { fetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';
// eslint-disable-next-line import/extensions
import { DeviceOwnerStatus } from './enum/DeviceOwnerKeyMaps.enum';

interface SelectedDeviceOwner {
  id: string;
  email: string;
}
interface IProps {
  numSelected: number;
  selected: SelectedDeviceOwner[];
  onClickSelectAll: (event) => void;
  setSelected: Dispatch<SetStateAction<string[]>>;
  closeEditableRow: () => void;
  mutate: () => void;
  ownerStatus: (typeof DeviceOwnerStatus)[keyof typeof DeviceOwnerStatus];
}

export default function DeviceOwnersTableToolbar({
  numSelected,
  selected,
  onClickSelectAll,
  setSelected,
  closeEditableRow,
  mutate,
  ownerStatus,
}: IProps) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const deleteAllDeviceOwners = async () => {
    setIsLoading(true);
    const ids = selected.map(owner => owner.id);
    const owner = ids.length > 1 ? 'owners' : 'owner';

    await sendHttpRequest({
      path: `${process.env.ORGANIZATION_SERVICE_URL}/organizations/deviceowners?ids=${ids.join(',')}`,
      method: 'DELETE',
      successMessage: `Successfully deleted device ${owner}`,
      errorMessage: `Error deleting device ${owner}`,
      enqueueSnackbar,
    });

    resetInterface();
  };

  const reinviteAllDeviceOwners = async () => {
    setIsLoading(true);
    const emails = selected.map(owner => owner.email);
    const owner = emails.length > 1 ? 'owners' : 'owner';
    try {
      const response = await fetchService(`/organizations/invite/bulk?emails=${encodeURIComponent(emails.join(','))}`, accessToken);
      if (response.error) {
        enqueueSnackbar(`Error reinviting device ${owner}`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(`Successfully reinvited device ${owner}`, {
          variant: 'success',
        });
      }
    } catch (e) {
      console.error('Error reinviting device owners:', e);
    } finally {
      resetInterface();
    }
  };

  const resetInterface = () => {
    setIsLoading(false);
    closeEditableRow();
    setSelected([]);
    mutate();
  };

  return (
    <>
      {numSelected > 0 && (
        <StyledTableToolbar>
          <Typography>
            {numSelected > 1 ? <span>{numSelected} device owners selected</span> : <span>{numSelected} device owner selected</span>}
          </Typography>
          <StyledTableToolbarButtons>
            {ownerStatus === DeviceOwnerStatus.PENDING && (
              <>
                <StyledDeviceOwnersTableToolbarButton
                  variant="contained"
                  startIcon={<DeleteOutlineIcon />}
                  onClick={deleteAllDeviceOwners}
                  disabled={isLoading}
                >
                  Delete
                </StyledDeviceOwnersTableToolbarButton>
                <StyledDeviceOwnersTableToolbarButton
                  variant="contained"
                  startIcon={<MailOutlinedIcon />}
                  onClick={reinviteAllDeviceOwners}
                  disabled={isLoading}
                >
                  Reinvite
                </StyledDeviceOwnersTableToolbarButton>
              </>
            )}
            {ownerStatus === DeviceOwnerStatus.UNENROLLED && (
              <>
                <StyledDeviceOwnersTableToolbarButton
                  variant="contained"
                  startIcon={<MailOutlinedIcon />}
                  onClick={reinviteAllDeviceOwners}
                  disabled={isLoading}
                >
                  Invite
                </StyledDeviceOwnersTableToolbarButton>
              </>
            )}
            <StyledDeviceOwnersTableToolbarButton
              variant="contained"
              startIcon={<ClearIcon />}
              onClick={onClickSelectAll}
              disabled={isLoading}
              className="deselect"
            >
              Deselect
            </StyledDeviceOwnersTableToolbarButton>
          </StyledTableToolbarButtons>
        </StyledTableToolbar>
      )}
    </>
  );
}

const StyledTableToolbar = styled(Toolbar)`
  background-color: ${props => props.theme.palette.accent.primary};
  display: flex;
  flex-direction: row;
  align-content: space-between;
  color: #ffffff;
`;

const StyledTableToolbarButtons = styled('div')`
  margin: auto 0 auto auto;
  button {
    border-radius: 0;
    &:hover {
      background-color: ${props => props.theme.palette.accent.primary};
    }
    &.deselect {
      border-left: 1px solid white;
    }
  }
`;

const StyledDeviceOwnersTableToolbarButton = styled(Button)`
  background-color: inherit;
  box-shadow: none;
  text-transform: capitalize;
`;
