import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';
import { KwTableRow } from './KwTableRow';
import { KwTableCell } from './KwTableCell';

export const KwTableHead = styled(TableHead)`
  ${KwTableRow} {
    background-color: #f5f5f5;
  }
  ${KwTableCell} {
    cursor: pointer;
    &:hover,
    &:hover > div,
    &:hover > div > img {
      background-color: #e0e0e0;
    }
  }
`;
