import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import App from './app';

// Leaflet CSS
import 'react-leaflet-markercluster/dist/styles.min.css';

// Initializing LaunchDarkly prior to the first render (awaiting). This ensures no "flickering"
// occurs. See: https://docs.launchdarkly.com/sdk/client-side/react/react-web#initializing-the-react-sdk
(async () => {
  const container = document.getElementById('root');
  const root = createRoot(container);
  const LDProvider = await asyncWithLDProvider({
    // first initializing with an anonymous user since
    // auth hasn't initialized yet
    clientSideID: process.env.LD_CLIENT_KEY,
    context: {
      kind: 'user',
      key: 'anonymous-mat', // first initializing with an anonymous static user. This prohibits LD from creating excessive users
    },
  });

  root.render(
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.AUTH0_ISSUER_BASE_URL}
        clientId={process.env.AUTH0_CLIENT_ID}
        audience={process.env.AUTH0_AUDIENCE_URL}
        redirectUri={process.env.AUTH0_BASE_URL}
      >
        <LDProvider>
          <App />
        </LDProvider>
      </Auth0Provider>
    </React.StrictMode>,
  );
})();
