import React, { useEffect, useState } from 'react';
import { Box, Card, CardHeader, MenuItem, Paper, Select, styled, Typography } from '@mui/material';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis, Text } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { useFetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { PageLoader } from '../../PageLoader';
import { dateRange } from '../shared/constants';

const useTopIors = ({ platform, range, accessToken }: { platform: string; range: number; accessToken: string }) => {
  const path = `/device/top-risk-indicators?os=${platform === 'Android' ? 'android' : 'ios'}&range=${range}&limit=10`;
  const response = useFetchService(path, accessToken);
  let { data } = response;
  const { error } = response;

  const isLoading = !data && !error;
  if (!isLoading) {
    // Remove duplicate names
    data = data?.filter((value, index, self) => index === self.findIndex(t => t.riskIndicatorName === value.riskIndicatorName)) || [];
  }
  return { topIors: data, isLoading };
};

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <Text x={x} y={y} width={190} textAnchor="end" verticalAnchor="middle" maxLines={1}>
      {payload.value}
    </Text>
  );
};

export function TopIorsCard({
  platform,
  initialDateRange,
  setCustom,
}: {
  platform: 'iOS' | 'Android';
  initialDateRange: number;
  setCustom: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { topIors, isLoading } = useTopIors({ range: selectedDateRange, platform, accessToken });

  useEffect(() => setSelectedDateRange(initialDateRange), [initialDateRange]);

  const handleDateRangeChange = event => {
    setSelectedDateRange(event.target.value);
    if (event.target.value !== initialDateRange) {
      setCustom(true);
    }
  };

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <StyledPaper>
          <Typography variant="bodyRegular" color="white">
            {label}
          </Typography>
          <hr />
          <Typography variant="bodyRegular" color="white">
            Number of Applications: {payload?.[0].value}
          </Typography>
        </StyledPaper>
      );
    }

    return null;
  };

  const numApps = (topIors || [100]).map(ior => Number(ior.numApplications));
  const domain = [0, Math.max(...numApps)];

  return (
    <StyledCard>
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4Medium">Top Indicators of Risk ({platform})</Typography>
            <Select variant="standard" value={selectedDateRange} onChange={handleDateRangeChange} disableUnderline>
              {dateRange.map(range => (
                <MenuItem key={range.value} value={range.value}>
                  <em>{range.label}</em>
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
      />
      {isLoading && <PageLoader />}
      <ResponsiveContainer width="100%" height="80%">
        {topIors?.length ? (
          <BarChart data={topIors} layout="vertical" barCategoryGap={28} margin={{ top: 0, right: 50, left: 0, bottom: 0 }}>
            <XAxis type="number" domain={domain} tickCount={4} />
            <YAxis type="category" width={200} dataKey="riskIndicatorName" interval={0} tick={<CustomizedAxisTick />} />
            <CartesianGrid horizontal={false} />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="numApplications"
              fill={platform === 'Android' ? '#9cd484' : 'rgba(0,122,255,0.7)'}
              barSize={14}
              radius={[0, 7, 7, 0]}
            />
          </BarChart>
        ) : (
          <div />
        )}
      </ResponsiveContainer>
    </StyledCard>
  );
}

const StyledCard = styled(Card)({
  borderRadius: '8px',
  boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  backgroundColor: '#FAFAFA',
  padding: '8px',
  height: '392px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: 'black',
  width: '240px',
  height: 'auto',
  padding: '10px 20px',
});
