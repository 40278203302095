// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-indicator-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.activity-indicator {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #244c5a;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.activity-indicator-text {
  color: white;
  font-size: 20px;
  margin-top: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.activity-indicator-text-subtitle {
  color: white;
  font-size: 16px;
  margin-top: 5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/components/Applications/css/activityindicator.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,oCAAoC;EACpC,cAAc;AAChB;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;AAEA;EACE,0BAA0B;EAC1B,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,kCAAkC;AACpC;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,2CAA2C;AAC7C;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,eAAe;EACf,2CAA2C;AAC7C","sourcesContent":[".activity-indicator-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 10000;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n.activity-indicator {\n  border: 16px solid #f3f3f3;\n  border-top: 16px solid #244c5a;\n  border-radius: 50%;\n  width: 120px;\n  height: 120px;\n  animation: spin 2s linear infinite;\n}\n\n.activity-indicator-text {\n  color: white;\n  font-size: 20px;\n  margin-top: 20px;\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);\n}\n\n.activity-indicator-text-subtitle {\n  color: white;\n  font-size: 16px;\n  margin-top: 5px;\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
