import { createContext, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useOrgData from './use-org-data';

const OrgContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const OrgProvider = ({ accessToken, children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orgData = useOrgData(accessToken);
  const orgId = orgData?.orgData?.id;

  useEffect(() => {
    if (orgId && searchParams.has('orgId') && searchParams.get('orgId') !== orgId) {
      navigate('/organization-mismatch-error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return <OrgContext.Provider value={orgData}>{children}</OrgContext.Provider>;
};

export const useOrg = () => {
  return useContext(OrgContext);
};
