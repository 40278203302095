import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React from 'react';
import Parser from 'html-react-parser';
import { KwScoreGauge } from '../../kw-ui-components/KwScoreGauge/KwScoreGauge';

type RiskyScorePaneProps = {
  title: string;
  canListApps: boolean;
  score: number;
  numberOfIors: number;
  topRiskyApps: [string, React.ReactElement][];
  triggeredPoliciesListString?: string;
};
export const RiskLevelPane = ({
  title,
  canListApps,
  score,
  numberOfIors,
  topRiskyApps,
  triggeredPoliciesListString,
}: RiskyScorePaneProps) => (
  <StyledScorePane aria-labelledby={_.snakeCase(`${title} title`)}>
    <StyledScoreSummary>
      <StyledScoreLabel id={_.snakeCase(`${title} title`)}>{title}</StyledScoreLabel>
      <KwScoreGauge value={score} />
      {canListApps && (
        <StyledIorCount>
          <div>
            Found {numberOfIors} IOR
            {numberOfIors === 0 || numberOfIors === 1 ? '' : 's'}
          </div>
        </StyledIorCount>
      )}
    </StyledScoreSummary>
    {canListApps && <StyledRiskLevelDivider orientation="vertical" variant="middle" />}
    {canListApps && (
      <StyledTopRiskApps>
        <StyledTopRiskAppsLabel id="top-five-risk-apps-label">Top 5 Risk Apps</StyledTopRiskAppsLabel>
        <StyledTopRiskAppList aria-labelledby="top-five-risk-apps-label">
          {topRiskyApps.map(([appName, icon]) => (
            <StyledRiskyApp key={appName}>
              {icon}
              <EllipsisText title={appName} component="div" variant="bodyRegular">
                {appName}
              </EllipsisText>
            </StyledRiskyApp>
          ))}
        </StyledTopRiskAppList>
      </StyledTopRiskApps>
    )}
    <StyledLine />
    {!canListApps && (
      <StyledText>
        Sorry, it looks like you do not have permission to access the application data about this unmanaged device.
        {triggeredPoliciesListString && Parser(triggeredPoliciesListString)}
      </StyledText>
    )}
  </StyledScorePane>
);

/** styles */
const StyledRiskyApp = styled('li')`
  display: flex;
  align-items: center;
  column-gap: 8px;
  min-width: 117px;
  height: 32px;
`;

const StyledTopRiskApps = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 14px;
  padding-bottom: 15px;
  width: 100%;
`;

const StyledTopRiskAppList = styled('ol')`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 0;
  padding: 0;
`;

const StyledTopRiskAppsLabel = styled('div')`
  color: ${props => props.theme.typography.tinyMedium.color};
  font-size: ${props => props.theme.typography.tinyMedium.fontSize};
  line-height: ${props => props.theme.typography.tinyMedium.lineHeight};
`;

const StyledScoreLabel = styled('div')`
  grid-area: title;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.palette.text.primary};
`;

const StyledIorCount = styled('div')`
  grid-area: count;
  font-size: ${props => props.theme.typography.smallRegular.fontSize};
  color: ${props => props.theme.palette.greyOverride.secondary};
`;

const StyledScorePane = styled('section')`
  display: flex;
  min-height: 85px;
  height: 100%;
  background-color: ${props => props.theme.palette.greyOverride[100]};
`;

const StyledScoreSummary = styled('div')`
  padding: 16px;
  display: grid;
  width: 240px;
  grid-template:
    'title score' 21px
    'count score' auto
    'meter .....' auto
    / 135px auto;
`;

const StyledRiskLevelDivider = styled(props => <Divider flexItem {...props} />)`
  background-color: ${props => props.theme.palette.greyOverride[300]};
  margin-bottom: 15px;
  margin-top: 15px;
` as typeof Divider;

const EllipsisText = styled(Typography)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
` as typeof Typography;

const StyledLine = styled('div')`
  position: relative;
  width: 1px;
  height: 55px;
  left: 0px;
  top: 15.5px;

  border: 1px solid #e0e0e0;
`;

const StyledText = styled(Typography)`
  position: relative;
  left: 19px;
  margin-top: auto;
  margin-bottom: auto;
  white-space: pre-line;
  height: auto;

  a:link {
    color: #487f87;
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: #487f87;
    background-color: transparent;
    text-decoration: none;
  }
`;
