/* eslint-disable react/forbid-component-props */
import { styled } from '@mui/material/styles';
import CountryFlag from 'react-country-flag';
import { countriesMap } from '../../utils/countriesMap';

const CountriesContactedCellContent = ({
  contactedCountryCodes = [],
  highRiskCountries,
  highRiskCountriesError,
}: {
  contactedCountryCodes: string[];
  highRiskCountries: string[];
  highRiskCountriesError: boolean;
}) => {
  const total = contactedCountryCodes.length;
  const highRisk = [];
  const lowRisk = [];

  for (const countryCode of contactedCountryCodes) {
    if (highRiskCountries?.includes(countryCode)) {
      highRisk.push(countryCode);
    } else {
      lowRisk.push(countryCode);
    }
  }
  // alphabetically sorts high risk, low risk
  const countryCodes = [...highRisk, ...lowRisk].slice(0, 3);
  const remaining = total - countryCodes.length;

  return (
    <StyledCountriesWrapper>
      <div>
        {countryCodes.map(countryCode => (
          <StyledCountryContent key={countryCode}>
            <StyledCountryFlag countryCode={countryCode} svg style={{ width: '24px', height: '16px' }} />
            {!highRiskCountriesError && highRiskCountries?.includes(countryCode) ? (
              <StyledTypography>{countriesMap[countryCode]?.countryName}</StyledTypography>
            ) : (
              countriesMap[countryCode]?.countryName
            )}
          </StyledCountryContent>
        ))}
      </div>
      {remaining > 0 && <StyledRemaining> +{remaining} more</StyledRemaining>}
    </StyledCountriesWrapper>
  );
};

const StyledCountryContent = styled('div')`
  margin-bottom: 8px;
`;

const StyledCountryFlag = styled(CountryFlag)`
  margin-right: 8px;
`;

const StyledTypography = styled('span')`
  color: red;
  display: inline;
`;

const StyledCountriesWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const StyledRemaining = styled('div')`
  color: #487f87;
  font-size: 13px;
  line-height: 30px;
  font-weight: 700;
  padding-left: 10px;
  margin-top: 52px;
`;

export default CountriesContactedCellContent;
