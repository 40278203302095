import { List, ListItemButton, ListItemText, Popover } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { sendHttpRequest } from '../../utils/network.service';
import { fetchService } from '../../utils/fetchService';
import { LaunchDarklyFlags } from '../../launch-darkly';
// eslint-disable-next-line import/extensions
import { DeviceOwnerStatus } from './enum/DeviceOwnerKeyMaps.enum';

export interface DeviceOwner {
  id: string;
  organizationId: string;
  email: string;
  inviteSentAt: Date;
  inviteAcceptedAt: Date;
  lastCheckInAt: Date;
  unenrolledAt: Date;
  firstName: string;
  lastName: string;
}

export interface ManagedApp {
  id: number;
  managedAppId: string;
  iconUrl: string;
  name: string;
  platform: 'iOS' | 'Android';
}

interface SelectedDeviceOwner {
  id: string;
  email: string;
}

interface IProps {
  ownerStatus: (typeof DeviceOwnerStatus)[keyof typeof DeviceOwnerStatus];
  deviceOwner: DeviceOwner;
  deviceOwnerId: string;
  anchorEl: HTMLElement;
  handleClose: () => void;
  mutate: () => void;
  selected: SelectedDeviceOwner[];
  setSelected: Dispatch<SetStateAction<SelectedDeviceOwner[]>>;
  editRow: (id: string) => void;
  setShowDeviceOwnerAppManagementModal: Dispatch<SetStateAction<boolean>>;
  setCurrentManagedAppsOwner: Dispatch<SetStateAction<DeviceOwner>>;
}

export function DeviceOwnersPopover({
  ownerStatus,
  deviceOwner,
  deviceOwnerId,
  anchorEl,
  handleClose,
  mutate,
  selected,
  setSelected,
  editRow,
  setShowDeviceOwnerAppManagementModal,
  setCurrentManagedAppsOwner,
}: IProps) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { enqueueSnackbar } = useSnackbar();
  const flags: LaunchDarklyFlags['flags'] = useFlags();

  const options = {
    PENDING: ['Edit', 'Delete', 'Reinvite'],
    ENROLLED: ['Edit', 'Reinvite'],
    UNENROLLED: [''], // placeholder
  };

  if (flags.managedApps) {
    options.ENROLLED.push('View managed apps');
  }

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handlePopoverClick = async (value: 'Edit' | 'Delete' | 'Reinvite') => {
    const deviceOwnerInfo = `${deviceOwner.firstName} ${deviceOwner.lastName} (${deviceOwner.email})`;
    if (value === 'Edit') {
      editRow(deviceOwnerId);
    } else if (value === 'Delete') {
      await sendHttpRequest({
        path: `${process.env.ORGANIZATION_SERVICE_URL}/organizations/deviceowners/${deviceOwnerId}`,
        method: 'DELETE',
        successMessage: `Successfully deleted ${deviceOwnerInfo}`,
        errorMessage: `Error deleting ${deviceOwnerInfo}`,
        enqueueSnackbar,
      });

      removeFromSelected(selected, deviceOwnerId);
      mutate();
    } else if (value === 'Reinvite') {
      try {
        const response = await fetchService(`/organizations/invite?email=${encodeURIComponent(deviceOwner.email)}`, accessToken);
        if (response.error) {
          enqueueSnackbar(`Error reinviting ${deviceOwnerInfo}`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(`Successfully reinvited ${deviceOwnerInfo}`, {
            variant: 'success',
          });
        }
      } catch (err) {
        console.error('Error re-inviting device owner', err);
      } finally {
        removeFromSelected(selected, deviceOwnerId);
        mutate();
      }
    } else if (value === 'View managed apps') {
      setCurrentManagedAppsOwner(deviceOwner);
      setShowDeviceOwnerAppManagementModal(true);
    }
    handleClose();
  };

  /* 
    accounts for case where if 1 or more rows are selected using checkboxes and kebab icon is used to delete / reinvite 
    an individual device owner who has already been selected using checkboxes - selected device owners get updated accordingly
  */
  const removeFromSelected = (selectedDeviceOwners: SelectedDeviceOwner[], id: string) => {
    setSelected([...selectedDeviceOwners.filter(owner => owner.id !== id)]);
  };

  return (
    <StyledPopover id={popoverId} open={deviceOwnerId === deviceOwner.id} anchorEl={anchorEl} onClose={() => handleClose()}>
      <StyledList>
        {options[ownerStatus].map((option: 'Edit' | 'Delete' | 'Reinvite') => (
          <ListItemButton onClick={() => handlePopoverClick(option)} key={option}>
            <ListItemText primary={option} />
          </ListItemButton>
        ))}
      </StyledList>
    </StyledPopover>
  );
}

const StyledList = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
`;

const rulesPopoverStyles = css`
  & div.popover-text {
    max-width: 300px;
    font-size: 0.875rem;
    padding: 12px;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const StyledPopover = styled(Popover)`
  ${rulesPopoverStyles}
`;
