// eslint-disable-next-line import/no-extraneous-dependencies
import { Divider, Modal, Typography, styled, Box } from '@mui/material';
import { KwButton } from './KwButton/KwButton';

export const KwConfirmationModal = ({ title, isOpen, onClose, description, onConfirm, extraDetails }: IProps) => {
  return (
    <Modal open={isOpen} aria-labelledby="confirmation-modal-title" aria-describedby="confirmation-modal-description" onClose={onClose}>
      <StyledBox>
        <Typography variant="h3Medium" id="confirmation-modal-title">
          {title}
        </Typography>

        <StyledDivider />

        <div id="confirmation-modal-description">
          <Typography variant="bodyRegular">Are you sure you want to {description}?</Typography>
        </div>

        {extraDetails && (
          <div id="confirmation-modal-extra-details" style={{ marginTop: '16px' }}>
            {extraDetails}
          </div>
        )}

        <StyledButtonsContainer>
          <KwButton onClick={onClose}>Cancel</KwButton>
          <KwButton
            variant="filled"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Confirm
          </KwButton>
        </StyledButtonsContainer>
      </StyledBox>
    </Modal>
  );
};
interface IProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  description: string;
  onConfirm: () => void;
  extraDetails?: React.ReactNode;
}

const StyledBox = styled(Box)(() => ({
  padding: 16,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  backgroundColor: '#fafafa',
}));

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
});

const StyledButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});
