// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lowRiskCustomMarker {
  background-position: center;
  background-color: rgba(170, 170, 238, 0.555);
  border: 2px solid rgba(170, 170, 238, 0.555);
  border-radius: 50%;
}

.highRiskCustomMarker {
  background-position: center;
  background-color: rgb(244, 67, 54, 0.3);
  border: 2px solid rgb(244, 67, 54, 0.3);
  border-radius: 50%;
}

.lowRiskCustomMarker div, .highRiskCustomMarker div {
  margin-top: 30%;
  text-align: center;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.lowRiskCustomMarker span, .highRiskCustomMarker span {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Applications/css/map.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,4CAA4C;EAC5C,4CAA4C;EAC5C,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,uCAAuC;EACvC,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,yDAAyD;EACzD,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".lowRiskCustomMarker {\n  background-position: center;\n  background-color: rgba(170, 170, 238, 0.555);\n  border: 2px solid rgba(170, 170, 238, 0.555);\n  border-radius: 50%;\n}\n\n.highRiskCustomMarker {\n  background-position: center;\n  background-color: rgb(244, 67, 54, 0.3);\n  border: 2px solid rgb(244, 67, 54, 0.3);\n  border-radius: 50%;\n}\n\n.lowRiskCustomMarker div, .highRiskCustomMarker div {\n  margin-top: 30%;\n  text-align: center;\n  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;\n  font-weight: bold;\n}\n\n.lowRiskCustomMarker span, .highRiskCustomMarker span {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
