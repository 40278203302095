import { Card, CardContent, CardHeader, Link, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';

type AppIorResultMap = {
  [name: string]: string[];
};

type PolicyViolationsCardProps = {
  policyViolations: {
    id: string;
    didTrigger: boolean;
    policyDescription: string;
    policyName: string;
    actionsTriggered: {
      name: string;
      description: string;
    }[];
    appIorEvalResultMap: AppIorResultMap[];
    associatedRules: {
      appIorResultsMap: AppIorResultMap[];
      didTrigger: boolean;
      name: string;
      ruleId: string;
      type: string;
    }[];
  }[];
};

export default function PolicyViolationsCard({ policyViolations }: PolicyViolationsCardProps) {
  const [viewMore, setViewMore] = useState(false);

  const violations = viewMore ? policyViolations : policyViolations.slice(0, 2);
  const viewButton = viewMore ? 'View Less' : 'View More';

  return (
    <StyledCard>
      <StyledCardHeader title="Policy Violations" />
      <StyledCardContent>
        <StyledList>
          {violations.map(policy => (
            <StyledListItem key={policy.id}>
              <StyledLink href={`#/policies/view/${policy.id}`}>{policy.policyName}</StyledLink>
            </StyledListItem>
          ))}
        </StyledList>
        {policyViolations.length > 2 && <StyledButton onClick={() => setViewMore(!viewMore)}>{viewButton}</StyledButton>}
      </StyledCardContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  margin-right: 10px;
  background-color: #fafafa;
  padding: 8px 8px 12px 8px;
`;

const StyledCardHeader = styled(CardHeader)`
  font-family: 'Nunito Sans', sans-serif;
  color: #f44336;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  padding-bottom: 0;
`;

const StyledList = styled(List)`
  list-style-type: disc;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  flex-wrap: wrap;
  padding-top: 0;
  gap: 10px 20px;
`;

const StyledListItem = styled(ListItem)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: list-item;
  margin-left: 20px;
  margin: 2px 0 2px 20px;
  flex: 1 0 45%;
`;

const StyledLink = styled(Link)`
  color: #000000;
  text-decoration-color: #9e9e9e;
  &:hover {
    text-decoration-color: #000000;
  }
`;

const StyledButton = styled(KwButton)`
  font-weight: 700;
  color: #487f87;
  font-size: 1rem;
  line-height: 1.5;
`;

const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
`;
