import { Card, CardContent, Typography, styled } from '@mui/material';

export const PolicyStatusCard = ({ title, value }: { title: string; value: number | string }) => {
  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h2Medium">{value}</Typography>
        <Typography variant="h5Medium" component="div">
          {title}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  minWidth: 230,
  minHeight: 150,
  lineHeight: 5,
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: 'rgba(255,255,255,0.6)',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
});
