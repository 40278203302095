import { Box, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

const delay = 1000;
export const PageLoader = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeout;
    if (!show) {
      timeout = setTimeout(() => {
        setShow(true);
      }, delay);
    }
    return () => clearTimeout(timeout);
  }, [show]);

  return (
    show && (
      <StyledBox>
        <LinearProgress />
      </StyledBox>
    )
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  box-sizing: content-box;
  background-color: unset;
`;
