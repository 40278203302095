import { List, ListItemButton, ListItemText, Popover } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { createQueryString } from '../../utils/createQueryString';
import { fetchService } from '../../utils/fetchService';
import { AddDeniedAppConfirmationModal } from './AddDeniedAppConfirmationModal';
import { RemoveDeniedAppConfirmationModal } from './RemoveDeniedAppConfirmationModal';
import { ActivityIndicator } from './ActivityIndicator';

interface IProps {
  deniedApps: { bundleId: string; os: string }[];
  applicationId: string;
  applicationVersion: string;
  os: string;
  name: string;
  isOpen: boolean;
  anchorEl: HTMLElement;
  handleClose: () => void;
  fetchDeniedApps: () => void;
  showDeniedApps: boolean;
}

export function ApplicationsPopover({
  deniedApps,
  applicationId,
  applicationVersion,
  os,
  name,
  isOpen,
  anchorEl,
  handleClose,
  fetchDeniedApps,
  showDeniedApps,
}: IProps) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [vendorName, setVendorName] = useState('');
  const [showAddDeniedAppConfirmationModal, setShowAddDeniedAppConfirmationModal] = useState(false);
  const [showRemoveDeniedAppConfirmationModal, setShowRemoveDeniedAppConfirmationModal] = useState(false);
  const [showActivityIndicator, setShowActivityIndicator] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const isDenied = deniedApps.some(app => app.bundleId === applicationId && app.os === os);

  const options = showDeniedApps ? (isDenied ? ['Remove from Denied Apps'] : ['Add to Denied Apps']) : [];
  options.push('Generate PDF Report');

  const handlePopoverClick = async (value: string) => {
    const queryParamString = createQueryString({
      searchText: applicationId,
      pageNum: String(1),
      pageSize: String(10),
      os,
    });

    const response = await fetchService(`/pdm/app-catalog?${queryParamString}`, accessToken);
    const result = response.results.find(item => item.packageName === applicationId);
    setVendorName(result?.vendorName || '');

    if (value === 'Add to Denied Apps') {
      setShowAddDeniedAppConfirmationModal(true);
    } else if (value === 'Remove from Denied Apps') {
      setShowRemoveDeniedAppConfirmationModal(true);
    } else if (value === 'Generate PDF Report') {
      // enqueueSnackbar('PDF report requested', { variant: 'success' });
      const generatePDFReport = async () => {
        try {
          setShowActivityIndicator(true);
          const reportResponse = await fetchService(`/application/${os}/${applicationId}/${applicationVersion}/report`, accessToken);
          const blob = new Blob([reportResponse], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${applicationId}_${applicationVersion}_report.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          // enqueueSnackbar('PDF report provided', { variant: 'success' });
        } catch (error) {
          console.error('Error generating PDF report', error);
          enqueueSnackbar('Error generating PDF report', { variant: 'error' });
        } finally {
          setShowActivityIndicator(false);
        }
      };
      generatePDFReport();
    }
    handleClose();
  };

  return (
    <>
      <StyledPopover
        id={applicationId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClick={e => e.stopPropagation()}
      >
        <StyledList>
          {options.map((option) => (
            <ListItemButton
              onClick={() => {
                handlePopoverClick(option);
              }}
              key={option}
            >
              <ListItemText primary={option} />
            </ListItemButton>
          ))}
        </StyledList>
      </StyledPopover>
      <AddDeniedAppConfirmationModal
        showConfirmationModal={showAddDeniedAppConfirmationModal}
        setShowConfirmationModal={setShowAddDeniedAppConfirmationModal}
        selectedApp={{ applicationId, os, name, vendorName }}
        fetchDeniedApps={fetchDeniedApps}
      />
      <RemoveDeniedAppConfirmationModal
        showConfirmationModal={showRemoveDeniedAppConfirmationModal}
        setShowConfirmationModal={setShowRemoveDeniedAppConfirmationModal}
        selectedApp={{ applicationId, os, name, vendorName }}
        fetchDeniedApps={fetchDeniedApps}
      />

      <ActivityIndicator active={showActivityIndicator} message="Generating PDF Report" subtitle='This may take up to 1 minute' />
    </>
  );
}

const StyledList = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
`;

const rulesPopoverStyles = css`
  & div.popover-text {
    max-width: 300px;
    font-size: 0.875rem;
    padding: 12px;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const StyledPopover = styled(Popover)`
  ${rulesPopoverStyles}
`;
