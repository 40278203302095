import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { appBarHeight } from '../components/shared/AppBar';

export const KwContainer = styled(Container)`
  padding: 36px 28px;
  margin: 0;
  max-width: unset;
  background-color: white;
  height: calc(100vh - ${appBarHeight});
  overflow-y: auto;
`;
