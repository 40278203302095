import { jwtPayload } from './index';
import { AuthPermissions } from './AuthPermissions';

export function verifyAuthPermission(authPermission: string): boolean {
  const item = window.localStorage.getItem('accessToken') || '';
  const accessToken = item ? JSON.parse(item) : '';
  return accessToken.length ? jwtPayload(accessToken).permissions.includes(authPermission) : false;
}

// utility function to check if the user has the required risk indicator permissions
export function checkPermissions(permissions: AuthPermissions[]): boolean {
  const check = permissions.some(permission => verifyAuthPermission(permission));
  return check || verifyAuthPermission(AuthPermissions.SYSTEM_ADMIN);
}
