import { DeviceOwner } from '../components/DeviceOwners/DeviceOwnersPopover';

/**
 * Returns the name of a DeviceOwner, or their email address if no name is available.
 *
 * @param {DeviceOwner} currentManagedAppsOwner - The DeviceOwner object to get the name from.
 * @returns {JSX.Element} The name of the DeviceOwner, or their email address if no name is available.
 */
export const getNameOrEmail = (currentManagedAppsOwner: DeviceOwner) => {
  const { firstName, lastName, email } = currentManagedAppsOwner;
  const name = `${firstName} ${lastName}`;

  const displayName = name.trim().length > 0 ? name : email;

  return <>{displayName}</>;
};
