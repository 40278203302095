import { Divider, Modal, Typography, styled, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';

export const IssueDescriptionModal = ({ title, isOpen, onClose, description }: IProps) => {
  return (
    <Modal open={isOpen} aria-labelledby="issue-modal-title" aria-describedby="issue-modal-description" onClose={onClose}>
      <StyledBox>
        <Typography variant="h3Medium" id="issue-modal-title">
          {title}
          <StyledCloseIcon onClick={onClose} />
        </Typography>

        <StyledDivider />

        <div id="issue-modal-description">
          <Typography variant="bodyRegular">{description}</Typography>
        </div>

        <StyledButtonsContainer>
          <KwButton variant="filled" onClick={onClose}>
            Got it
          </KwButton>
        </StyledButtonsContainer>
      </StyledBox>
    </Modal>
  );
};

interface IProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  description: string;
}

const StyledBox = styled(Box)(() => ({
  padding: 16,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  backgroundColor: '#fafafa',
  outline: 'none',
}));

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
});

const StyledButtonsContainer = styled('div')({
  marginTop: 8,
  display: 'flex',
  justifyContent: 'flex-end',
});
