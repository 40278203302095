import { Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import type { RuleEntity } from '@kw/service-definitions-rule';
import type { ListResponse } from '@kw/rules-service/dist/src/types/ListResponse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { KwFilterWrapper } from '../../kw-ui-components/KwFilterWrapper';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { useRuleSortParams } from './useRuleSortParams';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useFetchService } from '../../utils/fetchService';
import { getComparator } from '../../utils/getComparator';
import { PageLoader } from '../../PageLoader';
import { LaunchDarklyFlags } from '../../launch-darkly';
import { BackgroundProgressBar } from '../BackgroundProgressBar/BackgroundProgressBar';

type RuleWithShortDescription<T> = Partial<T> & { descriptionShort: string };

export function Rules() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');
  const flags: LaunchDarklyFlags['flags'] = useFlags();

  const { page, pageSize, setPage, handleChangePage, handleChangeRowsPerPage } = useKwPagination(10);

  let path = `/rule/rules/list`;
  if (flags?.evalService) {
    path = path.replace('/rule/', '/eval/');
  }

  const { data: rulesData, error: rulesError } = useFetchService<ListResponse<RuleEntity>>(path, accessToken);
  const isLoading = !rulesData && !rulesError;

  const filteredRules = useMemo(() => {
    const rules = (rulesData?.results || []).map((rule: RuleWithShortDescription<RuleEntity>) => {
      const newRule = rule;
      if (rule.description.length > 75) {
        newRule.descriptionShort = `${rule.description.slice(0, 75)}...`;
      } else {
        newRule.descriptionShort = rule.description;
      }
      return newRule;
    });

    return searchQuery.length
      ? rules.filter(
          (rule: RuleWithShortDescription<RuleEntity>) =>
            rule.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            rule.description?.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : rules;
  }, [rulesData, searchQuery]);

  const total = filteredRules.length;
  const totalPageCount = Math.ceil(total / pageSize);

  const {
    ruleNameOrder,
    toggleRuleNameOrder,
    createdDateOrder,
    toggleCreatedDateOrder,
    updatedDateOrder,
    toggleUpdatedDateOrder,
    orderBy,
    setOrderBy,
  } = useRuleSortParams();

  const tableHeaders = [
    {
      title: 'Rule Name',
      onClick: toggleRuleNameOrder,
      sortValue: ruleNameOrder,
    },
    {
      title: 'Creation Date',
      onClick: toggleCreatedDateOrder,
      sortValue: createdDateOrder,
    },
    { title: 'Created By', onClick: () => {}, sortValue: null },
    {
      title: 'Last Updated',
      onClick: toggleUpdatedDateOrder,
      sortValue: updatedDateOrder,
    },
  ];

  // column header sorting UI filters
  const order = tableHeaders.map(({ sortValue }) => sortValue).find(o => o);

  useEffect(() => {
    if (ruleNameOrder) {
      setOrderBy('name');
    }

    if (createdDateOrder) {
      setOrderBy('createdAt');
    }

    if (updatedDateOrder) {
      setOrderBy('updatedAt');
    }

    if (!order) {
      setOrderBy(null);
    }
  }, [ruleNameOrder, createdDateOrder, updatedDateOrder, order, orderBy, setOrderBy]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, setPage]);

  if (rulesError) {
    console.error(rulesError);
    return <div>failed to load</div>;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <KwContainer>
        <BackgroundProgressBar />
        <Typography>
          Rules help organizations define what specific risks and types of risks are acceptable. You can also create Policies by combining
          them with actions.
        </Typography>
        <KwFilterWrapper>
          <StyledTableWrapper>
            <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder="Search rules" />
            <KwTablePaginationWrapper>
              <KwRowsPerPage
                rowsPerPageOptions={[10, 20, 50, 100]}
                pageSize={pageSize}
                page={page}
                totalNumberOfEntries={total}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />

              {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
            </KwTablePaginationWrapper>

            <KwTable aria-label="Rules-Table">
              <KwTableHead>
                <KwTableRow>
                  {tableHeaders.map(({ title, onClick, sortValue }) => (
                    <KwColumnSortHeader
                      key={title}
                      title={title}
                      onClick={() => {
                        onClick();
                        setPage(0);
                      }}
                      sortValue={sortValue}
                    />
                  ))}
                </KwTableRow>
              </KwTableHead>
              <KwTableBody>
                {[...filteredRules]
                  .sort(getComparator(order, orderBy))
                  .slice(page * pageSize, (page + 1) * pageSize)
                  .map((rule: RuleWithShortDescription<RuleEntity>) => (
                    <KwTableRow key={rule.id}>
                      <KwTableCell>
                        <Typography>{rule.name}</Typography>
                        <Tooltip title={rule.description} arrow>
                          <Typography variant="smallRegular">{rule.descriptionShort}</Typography>
                        </Tooltip>
                      </KwTableCell>
                      <KwTableCell>{dayjs(rule.createdAt).format('MMM DD, YYYY')}</KwTableCell>
                      <KwTableCell>Quokka Labs</KwTableCell>
                      <KwTableCell>{dayjs().diff(dayjs(rule.updatedAt), 'days')} day(s) ago by Quokka Labs</KwTableCell>
                    </KwTableRow>
                  ))}
              </KwTableBody>
            </KwTable>
            {total > 10 ? (
              <KwTablePaginationWrapper>
                <KwRowsPerPage
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pageSize={pageSize}
                  page={page}
                  totalNumberOfEntries={total}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
              </KwTablePaginationWrapper>
            ) : null}
          </StyledTableWrapper>
        </KwFilterWrapper>
      </KwContainer>
    </>
  );
}

/** styles */
const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledTableWrapper = styled('div')`
  width: 100%;
  margin: 26px 0 0 0;
`;
